import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Radio = ({
  name,
  id,
  label,
  value,
  checked = false,
  onChange = f => f,
  inline = false
}) => {
  const classes = classNames({
    "form-radio": !inline,
    "d-inline-block": inline
  });
  return (
    <div className={classes}>
      <label htmlFor={id}>
        <input
          id={id}
          name={name}
          type="radio"
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <span
          className="icon icon--radio-button-off icon--small"
          aria-hidden="true"
        />
        {label}
      </label>
    </div>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

export default Radio;
