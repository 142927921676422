import * as C from "../actions/constants";

export const loading = (state = false, action = { type: null }) => {
  switch (action.type) {
    case C.LOADING:
      return action.loading;
    default:
      return state;
  }
};

export const saving = (state = false, action = { type: null }) => {
  switch (action.type) {
    case C.SAVING:
      return action.saving;
    default:
      return state;
  }
};
