export const LOADING = "LOADING";
export const SAVING = "SAVING";
export const SET_CONTEXT = "SET_CONTEXT";
export const GET_OPTIONS_SUCCESS = "GET_OPTIONS_SUCCESS";
export const GET_OPTIONS_FAILURE = "GET_OPTIONS_FAILURE";
export const GET_RESOURCES_SUCCESS = "GET_RESOURCES_SUCCESS";
export const GET_RESOURCES_FAILURE = "GET_RESOURCES_FAILURE";
export const GET_NIU_SUCCESS = "GET_NIU_SUCCESS";
export const GET_NIU_FAILURE = "GET_NIU_FAILURE";
export const SAVE_NIU_SUCCESS = "SAVE_NIU_SUCCESS";
export const SAVE_NIU_FAILURE = "SAVE_NIU_FAILURE";
export const PUBLISH_NIU_SUCCESS = "PUBLISH_NIU_SUCCESS";
export const PUBLISH_NIU_FAILURE = "PUBLISH_NIU_FAILURE";
export const UPDATE_FORM = "UPDATE_FORM";
export const UPDATE_NIU_TABLE_DATA = "UPDATE_NIU_TABLE_DATA";
export const ADD_OU = "ADD_OU";
export const REMOVE_OU = "REMOVE_OU";
export const UPDATE_OU = "UPDATE_OU";
export const UPDATE_OUS = "UPDATE_OUS";
export const RESET_ERRORS = "RESET_ERRORS";
export const GET_S3_REQUEST = "GET_S3_REQUEST";
export const GET_S3_SUCCESS = "GET_S3_SUCCESS";
export const GET_S3_FAILURE = "GET_S3_FAILURE";
export const SELECT_S3_FILE = "SELECT_S3_FILE";
export const RESET_S3_FOLDER = "RESET_S3_FOLDER";
export const GET_NIU_TABLE_DATA_SUCCESS = "GET_NIU_TABLE_DATA_SUCCESS";
export const GET_NIU_TABLE_DATA_FAILURE = "GET_NIU_TABLE_DATA_FAILURE";
export const UPDATE_FAV = "UPDATE_FAV";
export const FAV_FOLDER = "FAV_FOLDER";
export const GET_All_S3_SUCCESS = "GET_S3_SUCCESS";
export const GET_All_S3_FAILURE = "GET_S3_FAILURE";
export const FILTER_IMAGE = "GET_FILTER_IMAGE";

