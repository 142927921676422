import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { getIcon } from "../utils/ui";

const Format = ({ code, url }) => {
  return (
    <li>
      <a
        href={url}
        className="btnlink btnlink--format"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className={"icon " + getIcon(code)} aria-hidden="true" />
        <Translate
          id={"format." + code}
          options={{
            onMissingTranslation: () => code
          }}
        />
      </a>
    </li>
  );
};

Format.propTypes = {
  code: PropTypes.string.isRequired,
  url: PropTypes.string
};

export default Format;
