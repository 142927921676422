import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import InfoTooltip from "./InfoTooltip";
import { getIcon } from "../utils/ui";
import Radio from "../components/Radio";

const Format = ({ code }) => {
  return (
    <div className="btnlink btnlink--format">
      <div className={"icon " + getIcon(code)} aria-hidden="true" />
      <Translate
        id={"format." + code}
        options={{
          onMissingTranslation: () => code
        }}
      />
    </div>
  );
};

const FormatField = props => {
  const {
    name,
    label,
    help,
    value,
    formats,
    required,
    tooltip,
    onChange
  } = props;

  return (
    <fieldset aria-labelledby={"legend_" + name}>
      <legend className="h5" id={"legend_" + name}>
        <Translate id={label} /> {required ? "*" : null}{" "}
        {tooltip ? (
          <InfoTooltip
            tooltip={<Translate id={tooltip} />}
            id={"tooltip_" + label}
          />
        ) : null}
      </legend>
      <p>
        <Translate id={help} />
      </p>
      <div className="form-group">
        <Radio
          name={name}
          label={<Format code="NONE" />}
          id={name + "_none"}
          value=""
          checked={value === "" || !value}
          onChange={onChange}
        />
        {formats.map((format, index) => (
          <Radio
            key={name + "_" + index}
            name={name}
            label={<Format code={format.code} />}
            id={name + "_" + index}
            value={format.code}
            checked={format.code === value}
            onChange={onChange}
          />
        ))}
      </div>
    </fieldset>
  );
};

FormatField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  help: PropTypes.string,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  formats: PropTypes.array.isRequired
};

export default FormatField;
