import React from "react";
import PropTypes from "prop-types";
import Box from "./Box";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
const SortableItem = SortableElement(({ ...props }) => {
  return <Box {...props} />;
});

const SortableBoxes = SortableContainer(
  ({ items = [], isMultilang = false, onMove = f => f, onMoveCapture = f => f,multiformat = f => f ,boxAltAtr= f => f, onclickEditImage = f => f,editImage='', handleCancelEditImage = f => f, onSaveEditImage = f => f, chargeGuillotine = f => f , newImageSaved = []}) => {
    return (
      <ul id="boxes-grid" className="row boxes-list">
        {items.map((item, index) => {          
          return (
            <SortableItem
              key={"ou_" + item.idx}
              index={index}
              idx={index}
              max={items.length - 1}
              title={item.title}
              relevance={item.rellevancia ? item.rellevancia.toString() : "3"}
              image={item.imatge}
              hours={item.hores}
              mins={item.minuts}
              suport={item.formats}
              preferent={item.format}
              id={item.id}
              recursId={item.recursId}
              onMove={onMove}
              onMoveCapture={onMoveCapture}
              boxAltAtr={boxAltAtr}
              isMultilang={isMultilang}
              multiformat={multiformat}
              onclickEditImage={onclickEditImage}
              onSaveEditImage={onSaveEditImage}
              handleCancelEditImage={handleCancelEditImage}
              editImage={editImage}
              chargeGuillotine={chargeGuillotine}
              newImageSaved={newImageSaved}
              imatgeStyle={item.imatgeStyle}
              dataIniciVigencia={item?.formats?.[0]?.dataIniciVigencia}
              
            />
          );
        })}
      </ul>
    );
  }
);

SortableBoxes.propTypes = {
  items: PropTypes.array,
  isMultilang: PropTypes.bool,
  onMove: PropTypes.func,
  onMoveCapture: PropTypes.func,
  multiformat: PropTypes.func,
  onclickEditImage: PropTypes.func,
  editImage: PropTypes.any,
  onSaveEditImage: PropTypes.func,
  handleCancelEditImage: PropTypes.func,
  newImageSaved: PropTypes.any,
  chargeGuillotine: PropTypes.func,
};

export default SortableBoxes;
