import { animateScroll as scroll } from "react-scroll";
export const focusOnFirstError = errors => {
  const errorEl = document.querySelector(
    Object.keys(errors)
      .map(fieldName => `[name="${fieldName}"]`)
      .join(",")
  );
  if (errorEl && errorEl.focus) {
    let offset = errorEl.getAttribute("data-offset");
    offset = offset ? parseInt(offset, 10) : 50;
    setTimeout(function () {
      scroll.scrollTo(errorEl.offsetTop - offset, {
        duration: 500,
        smooth: true
      });
      errorEl.focus();
      const tmp = errorEl.value;
      errorEl.value = '';
      errorEl.value = tmp;
    }, 500);
  }
};

export const focusOnField = fieldName => {
  const item = document.querySelector(`[name="${fieldName}"]`);
  if (item && item.focus) {
    let offset = item.getAttribute("data-offset");
    offset = offset ? parseInt(offset, 10) : 50;
    setTimeout(function () {
      scroll.scrollTo(item.offsetTop - offset, {
        duration: 500,
        smooth: true
      });
      item.focus();
      const tmp = item.value;
      item.value = '';
      item.value = tmp;
    }, 500);
  }else{
    scroll.scrollToTop({
      duration: 500,
      smooth: true
    });    
  }
};

export const getIcon = code => {
  let icon = "icon--external-link";
  switch (code) {
    case "WEB":
    case "LINK":
      icon = "icon--material-web-full";
      break;
    case "HTML5":
      icon = "icon--material-html5-full";
      break;
    case "PDF":
      icon = "icon--material-pdf-full";
      break;
    case "EPUB":
      icon = "icon--material-epub-full";
      break;
    case "AUDIO":
      icon = "icon--material-audio-full";
      break;
    case "VIDEO":
    case "DVD":
      icon = "icon--material-video-full";
      break;
    case "POCKET":
      icon = "icon--material-mobipocket-full";
      break;
    case "BIBLIO":
      icon = "icon--book";
      break;
    case "NONE":
      icon = "icon--hidden";
      break;
    default:
  }
  return icon;
};

export const fileName = path => {
  if (!path) return null;
  return path
    .split("\\")
    .pop()
    .split("/")
    .pop();
};

export const isImage = file => {
  if (!file) return false;
  const formats = ["jpg", "jpeg", "png", "svg", "gif", "webp", "bmp"];
  const ext = file
    .split(".")
    .pop()
    .toLowerCase();
  return formats.includes(ext);
};

export const folderName = path => {
  return path.match(/([^/]*)\/*$/)[1];
};

export const previousFolder = path => {
  const parts = path.split("/");
  if (parts.length <= 0) return path;
  const rest = parts[parts.length - 1] === "" ? 3 : 2;
  let s = "";
  for (let i = 0; i <= parts.length - rest; i++) {
    s += parts[i] + "/";
  }
  return s;
};
