import queryString from "query-string";

const getHeaders = lang => {
  return {
    Accept: "application/json",
    "Accept-Language": lang || "ca"
  };
};

const getStatus = response => {
  if (!response.ok) {
    return response.json().then(error => {
      if (error.message) {
        return Promise.reject(new Error(error.message));
      } else {
        return Promise.reject(new Error(response.statusText));
      }
    });
  }
  return response;
};

const getJson = response => {
  return response.json();
};

const getData = response => {
  if (response.error) {
    return new Error(response.error.message);
  } else {
    return response;
  }
};

const apiCall = request => {
  return fetch(request)
    .then(getStatus)
    .then(getJson)
    .then(getData)
    .catch(error => {
      throw error;
    });
};

export const fetchOptions = context => {
  const { lang, url_backend } = context;
  const headers = getHeaders(lang);
  const request = new Request(
    `${url_backend}${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_OPTIONS}`,
    {
      method: "GET",
      credentials: "include",
      headers: headers
    }
  );
  return apiCall(request);
};

export const fetchResources = context => {
  //llama a multilingue 1

  const {
    lang,
    langs,
    defaultLang,
    codeAsig,
    anyAcademic,
    url_backend,
    sisCourseId
  } = context;
  /*const resRoute =
    langs.length > 1
      ? process.env.REACT_APP_API_RESOURCES_MULTI
      : process.env.REACT_APP_API_RESOURCES_MONO;
  const headers = getHeaders(lang);
  const request = new Request(
    `${url_backend}${process.env.REACT_APP_API_VERSION}/${resRoute}/${codeAsig}/${anyAcademic}/${defaultLang}/${lang}/${langs}`,
    {
      method: "GET",
      credentials: "include",
      headers: headers
    }
  );*/
  const resRoute =
    langs.length > 1
      ? `${url_backend}${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_RESOURCES_MULTI}/${codeAsig}/${anyAcademic}/${defaultLang}/${lang}/${langs}/${sisCourseId}`
      :  `${url_backend}${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_RESOURCES_MONO}/${codeAsig}/${anyAcademic}/${defaultLang}/${sisCourseId}`;
  const headers = getHeaders(lang);
  const request = new Request(
    resRoute,
    {
      method: "GET",
      credentials: "include",
      headers: headers
    }
  );
  return apiCall(request);
};

export const fetchNiu = context => {
  const { lang, niuId, url_backend } = context;
  const headers = getHeaders(lang);
  const request = new Request(
    `${url_backend}${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_NIUS}/${niuId}`,
    {
      method: "GET",
      credentials: "include",
      headers: headers
    }
  );
  return apiCall(request);
};

export const fetchNiuMultilang = context => {
  const { lang, url_backend, codeAsig, anyAcademic } = context;
  const headers = getHeaders(lang);
  const request = new Request(
    `${url_backend}${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_NIUS}/${codeAsig}/${anyAcademic}`,
    {
      method: "GET",
      credentials: "include",
      headers: headers
    }
  );
  return apiCall(request);
};

export const deleteNiu = context => {
  const { lang, niuId, url_backend } = context;
  const headers = getHeaders(lang);
  const request = new Request(
    `${url_backend}${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_NIUS}/${niuId}`,
    {
      method: "DELETE",
      credentials: "include",
      headers: headers
    }
  );
  return apiCall(request);
};

// export const updateNiuVisibility = context => {
//   const { lang, niuId, url_backend } = context;
//   const headers = getHeaders(lang);
//   const request = new Request(
//     `${url_backend}${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_NIUS}/${niuId}/${isVisible}`,
//     {
//       method: "PUT",
//       credentials: "include",
//       headers: headers
//     }
//   );
//   return apiCall(request);
// };

export const postNiu = (niu, context) => {
  const { lang, url_backend } = context;
  const deleteOus = niu.deleteOus.join();
  delete niu.deleteOus;
  const url = `${url_backend}${process.env.REACT_APP_API_VERSION}/${
    process.env.REACT_APP_API_NIUS
  }/?${queryString.stringify({ deleteOus: deleteOus })}`;

  const headers = { ...getHeaders(lang), "Content-Type": "application/json" };
  const request = new Request(url, {
    method: "POST",
    credentials: "include",
    headers: headers,
    body: JSON.stringify(niu)
  });
  return apiCall(request);
};

export const putNiu = (niu, context) => {
  const { lang, url_backend } = context;
  const deleteOus = niu.deleteOus.join();
  delete niu.deleteOus;
  const url = `${url_backend}${process.env.REACT_APP_API_VERSION}/${
    process.env.REACT_APP_API_NIUS
  }/${niu.id}/?${queryString.stringify({ deleteOus: deleteOus })}`;
  const headers = { ...getHeaders(lang), "Content-Type": "application/json" };
  const request = new Request(url, {
    method: process.env.REACT_APP_API_CRUD === "true" ? "PUT" : "POST",
    credentials: "include",
    headers: headers,
    body: JSON.stringify(niu)
  });
  return apiCall(request);
};
