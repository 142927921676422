import * as C from "../actions/constants";

export const resources = (state = [], action = { type: null }) => {
  switch (action.type) {
    case C.GET_RESOURCES_SUCCESS:
      return action.payload;
    case C.GET_RESOURCES_FAILURE:
      return state;
    default:
      return state;
  }
};

export const niu = (state = {}, action = { type: null }) => {
  switch (action.type) {
    case C.GET_NIU_SUCCESS:
    case C.SAVE_NIU_SUCCESS:
      return action.payload;
    case C.GET_NIU_FAILURE:
    case C.SAVE_NIU_FAILURE:
      return state;
    default:
      return state;
  }
};

export const errors = (state = [], action = { type: null }) => {
  switch (action.type) {
    case C.GET_OPTIONS_FAILURE:
    case C.GET_RESOURCES_FAILURE:
    case C.GET_NIU_FAILURE:
    case C.SAVE_NIU_FAILURE:
      return [...state, action.payload];
    case C.RESET_ERRORS:
      return [];
    default:
      return state;
  }
};

export const aws = (state = {}, action = { type: null }) => {
  switch (action.type) {
    case C.GET_OPTIONS_SUCCESS:
      return { ...state, ...action.payload };
    case C.GET_S3_REQUEST:
      return { ...state, error: null, loading: true, file: null };
    case C.GET_S3_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case C.GET_S3_SUCCESS:
      return { ...state, ...action.payload, error: null, loading: false };
    case C.RESET_S3_FOLDER:
      return {
        ...state, 
        folder: action.payload + "/", 
        file: null,
        data: [],
        error: null,
        loading: true
      };
    case C.SELECT_S3_FILE:
      return { ...state, file: action.payload };
    default:
      return state;
  }
};
