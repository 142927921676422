import React from "react";
import { Translate } from "react-localize-redux";
import InfoTooltip from "./InfoTooltip";

const TimeField = props => {
  const { names, label, values, required, tooltip, onChange } = props;

  return (
    <fieldset aria-labelledby={"legend_" + label}>
      <div role="presentation" className="h5">
        <legend id={"legend_" + label}>
          <Translate id={label} /> {required ? "*" : null}{" "}
        </legend>
        {tooltip ? (
          <InfoTooltip
            tooltip={<Translate id={tooltip} />}
            id={"tooltip_" + label}
          />
        ) : null}
      </div>
      <div className="form-group form-group--inline">
        <label htmlFor={names.hours}>
          <Translate id="form.hours" />
        </label>
        <input
          id={names.hours}
          name={names.hours}
          type="number"
          className="form-item"
          min="0"
          max="23"
          value={values.hours}
          onChange={onChange}
          aria-required={required}
          onInput={e => {
            e.target.value = (Math.max(0, parseInt(e.target.value, 10)) || 0)
              .toString()
              .slice(0, 2);
          }}
        />
      </div>
      <div className="form-group form-group--inline">
        <label htmlFor={names.mins}>
          <Translate id="form.minutes" />
        </label>
        <input
          id={names.mins}
          name={names.mins}
          type="number"
          className="form-item"
          min="0"
          max="59"
          value={values.mins}
          onChange={onChange}
          aria-required={required}
          onInput={e => {
            e.target.value = (Math.max(0, parseInt(e.target.value, 10)) || 0)
              .toString()
              .slice(0, 2);
          }}
        />
      </div>
    </fieldset>
  );
};

export default TimeField;
