import React, { Fragment } from "react";
import { Translate } from "react-localize-redux";
import { folderName, previousFolder } from "../utils/ui";

const PickerBreadcrumbs = ({ baseFolder, folder, onClick = f => f ,atrasFavFolder = f => f ,favfolder ,filterActive}) => {
  return (
    <nav className="modal-breadcrumb ruler ruler--primary ruler--bottom">
      <ol className="breadcrumb">
        {folder === baseFolder.s3_imatges_folder + "/" ? (
          <Fragment>
            <li className="breadcrumb-item">
              <Translate id="modals.start" />
            </li>
          </Fragment>
        ) : (
          <Fragment>
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn--unstyled"
                onClick={e => !favfolder && !filterActive? onClick(e, previousFolder(folder)): atrasFavFolder(e)}
              >
                <Translate id="modals.back" />
              </button>
            </li>
            <li className="breadcrumb-item active">{folderName(folder)}</li>
            {
              favfolder?(
                <Translate id="modals.fav" />
              ):null
            }
               {
              filterActive?(
                <Translate id="modals.filtro" />
              ):null
            } 
          </Fragment>
        )}
      </ol>
    </nav>
  );
};

export default PickerBreadcrumbs;
