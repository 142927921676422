import React, { Component, Fragment} from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import Header from "../components/Header";
import Skippy from "../components/Skippy";
import Loading from "../components/Loading";
import { withLocalize } from "react-localize-redux";
import $ from 'jquery';
import Alert from 'react-bootstrap/Alert'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
class CopiarNius extends Component {
    constructor(props) {
      super(props);

      this.state = {
        datosSemestres: [],
        datosAsignatura: [
          { value: "", label: "" },
        ],
        selectAsignatura:"",
        selectSemestre:"",
        selectLang:"",
        idiomaOrigen:"",
        semestreDestino:"",
        idiomaDestino:"",
        copiaSelectSemestre:"",
        selectCodiSemestre:"-",
        recursosNoCopiados:"",
        isLoading: false,
        showCorrect: false,
        showError: false,
        idBorrar:null,
        idniu:null,
        
      };
      this.plugSemestreSelect = this.plugSemestreSelect.bind(this)
      this.windowBack = this.windowBack.bind(this)
      this.asignatureSelect = this.asignatureSelect.bind(this)
      this.crearCopia = this.crearCopia.bind(this)
      this.chargeAsignatureData = this.chargeAsignatureData.bind(this)
      this.mostrarTextNoCopia = this.mostrarTextNoCopia.bind(this)      
  }

  
  async componentDidMount() {
    this.setState({ isLoading: true})
    await this.chargeAsignatureData();
    $('#buttonCopia').attr('disabled', 'disabled');
    $("#bannerLang").hide();
    $("#divTableNius").hide();
    $("#infoOrigenDestino").hide();
    $("#NiuNoCopia").hide();
  }

  getHeaders () {
    const {  lang } = this.props.context;
    return {
      Accept: "application/json",
      "Accept-Language": lang || "ca"
    };
  };

  plugSemestreSelect(){ 
    const {datosSemestres,selectCodiSemestre} = this.state;
    $('#selSemestre').empty();
    $('#selSemestre').prepend("<option value='-'>----------------</option>");
    [...datosSemestres].reverse().forEach((datosSemestre) => {
      $('#selSemestre').prepend("<option value='" + datosSemestre.anyAcademic + "' >" + datosSemestre.periodeAcademic + "</option>");
    });
    
    
     $('#selSemestre').val(selectCodiSemestre);
  }

  async chargeAsignatureData(){
    const {codeAsig , anyAcademic } = this.props.context;
    const {url_backend} = this.props.context;  
      const headers = this.getHeaders();
      const request = new Request(
        `${url_backend}/edna/copiaNius/assignatures/${anyAcademic}/${codeAsig}`,
        {
          method: "GET",
          credentials: "include",
          headers: headers
        }
      );
      let response= await fetch(request);
      let data =await response.json();
      let asignatura=[];
      await data.map((datos) => 
      asignatura.push({value: datos, label: datos})
      )
      
      await this.setState({ datosAsignatura: asignatura, isLoading: false  });

  }

   async asignatureSelect(){
    const {codeAsig,url_backend,lang,anyAcademic} = this.props.context;
    await setTimeout(5000);
    let codiAsigOrigen= await $('#selAsig').val();
    if(codiAsigOrigen){
        const headers = this.getHeaders();
        const request = new Request(
          `${url_backend}/edna/copiaNius/semestres/${anyAcademic}/${codeAsig}/${codiAsigOrigen}/${lang}`,
          {
            method: "GET",
            credentials: "include",
            headers: headers
          }
        );
        let response= await fetch(request);
        let data =await response.json();
        await this.setState({ datosSemestres: data});
        // await $('#selAsig').val(codiAsigOrigen);
        await this.plugSemestreSelect()
        $("#NiuNoCopia").hide();
        $("#bannerLang").hide();
        $('#buttonCopia').attr('disabled', 'disabled');
        // $('#buttonCopia').attr('disabled', 'disabled');
        // $('#selSemestre option["-"]').prop("selected",true).trigger("change");
        $('#selSemestre').val("-");
        $('#selSemestre').removeAttr('disabled');
        $("#destinoDiv").hide();
        $("#origenDiv").hide();
        $("#infoOrigenDestino").hide();
        $("#divTableNius").hide();
  }
   
  }
  async semestreSelect(){
   const {url_backend,langs,defaultLang,anyAcademic,lang,codeAsig} = this.props.context;
   this.setState({ isLoading: true})
    let semestreOrigen=$('#selSemestre').val();
    let asignaturaOrigen=$('#selAsig').val();
     const headers = this.getHeaders();

    //formatear semestre origen
    if(semestreOrigen!=="-"){
      const urlsemestreorigen = new Request(
        `${url_backend}/edna/periodeAcademic/${semestreOrigen}/${lang}`,
        {
          method: "GET",
          credentials: "include",
          headers: headers
        }
      );
      let datastreorigen= await fetch(urlsemestreorigen);
       let semestreOrigenFormat =await datastreorigen.text();
  
      //formatear semestre destino
      const urlsemestredestino = new Request(
        `${url_backend}/edna/periodeAcademic/${anyAcademic}/${lang}`,
        {
          method: "GET",
          credentials: "include",
          headers: headers
        }
      );
      let datastredestino= await fetch(urlsemestredestino);
      
      let semestreDestinoFormat =await datastredestino.text();
      $('#anyAcademic').append(semestreDestinoFormat);
      //

      let idiomesDesti=langs.toString();
      const requests = new Request(
        `${url_backend}/edna/copiaNius/idiomes/${semestreOrigen}/${asignaturaOrigen}/${defaultLang}/${idiomesDesti}`,
        {
          method: "GET",
          credentials: "include",
          headers: headers
        }
      );
      let response= await fetch(requests);
      let idiomaPerDefecte =await response.text();
        
        /*Get per agafar Alerta De Nius No Copiats*/          
        const noCopia = new Request(
          `${url_backend}/edna/peticions/recursosNoCopia?codeAsig=${asignaturaOrigen}&codeAssigDesti=${codeAsig}&idiomaOrigen=${lang}&idiomesDesti=${langs}&semestreDesti=${anyAcademic}&semestreOrigen=${semestreOrigen}`,
          {
            method: "GET",
            credentials: "include",
            headers: headers
          }
        );
        let txtNoCopia= await fetch(noCopia);
        let recursosNoCopiado =await txtNoCopia.json();
        if(recursosNoCopiado!==""){
          $('#recursosNoCopiados').empty();
          $("#NiuNoCopia").show();
        }else{
          $("#NiuNoCopia").hide();
        }

      //pasar datos al front sobre el lang
      // await this.setState({ selectCodiSemestre:semestreOrigen,selectSemestre: semestreOrigenFormat,copiaSelectSemestre:semestreOrigen ,selectAsignatura:asignaturaOrigen,idiomaOrigen:idiomaPerDefecte.slice(2, -2),semestreDestino: semestreDestinoFormat,idiomaDestino:idiomesDesti,recursosNoCopiados:recursosNoCopiado});
      await this.setState({ selectCodiSemestre:semestreOrigen,selectSemestre: semestreOrigenFormat,copiaSelectSemestre:semestreOrigen ,selectAsignatura:asignaturaOrigen,idiomaOrigen:idiomaPerDefecte.slice(2, -2),semestreDestino: semestreDestinoFormat,idiomaDestino:idiomesDesti,recursosNoCopiados:recursosNoCopiado ,isLoading: false});
      this.mostrarBanner();
      this.rellenarTabla();
      await this.setState({ selectCodiSemestre:semestreOrigen,selectSemestre: semestreOrigenFormat,copiaSelectSemestre:semestreOrigen ,selectAsignatura:asignaturaOrigen,idiomaOrigen:idiomaPerDefecte.slice(2, -2),semestreDestino: semestreDestinoFormat,idiomaDestino:idiomesDesti,recursosNoCopiados:recursosNoCopiado ,isLoading: false});
      this.plugSemestreSelect();
      $('#selSemestre').removeAttr('disabled');
      // $('#selSemestre').val(semestreOrigen);
      $('#buttonCopia').removeAttr('disabled');
      $("#destinoDiv").show();
      $("#origenDiv").show();
      $("#infoOrigenDestino").show();
      $("#divTableNius").show();
    }else{
      // await this.setState({ selectSemestre:semestreOrigen});
      $('#selSemestre').val(semestreOrigen);
      await this.setState({ selectCodiSemestre:"-",selectSemestre:semestreOrigen,isLoading: false});
      this.mostrarTextNoCopia();
      $("#NiuNoCopia").hide();
      $("#bannerLang").hide();
      $('#buttonCopia').attr('disabled', 'disabled');
      $("#destinoDiv").hide();
      $("#origenDiv").hide();
      $("#infoOrigenDestino").hide();
      $("#divTableNius").hide();
    }
  }

  mostrarTextNoCopia(){
    const {recursosNoCopiados} = this.state;
    if(recursosNoCopiados.length!==0){
      $('#recursosNoCopiados').empty();
      [...recursosNoCopiados].reverse().forEach((recursoNoCopiado) => {
        $('#recursosNoCopiados').prepend(recursoNoCopiado + "<br>");
      });
      
      $("#NiuNoCopia").show();
    }else{
      $("#NiuNoCopia").hide();
    }
  }

  async mostrarBanner(){
    const {idiomaOrigen,idiomaDestino} = this.state;
    let arrayidiomaDestino=idiomaDestino.split(',');
    $("#bannerLang").hide();
    let coincide=0;
    for (let i = 0; i < arrayidiomaDestino.length; i++) {
      // const element = array [i];
      if(arrayidiomaDestino[i]===idiomaOrigen){
        coincide =1;
      }
    }
    if(coincide===0){
      $("#bannerLang").show();
    }
  }
  async rellenarTabla(){
    const {selectAsignatura,idiomaOrigen,selectCodiSemestre} = this.state;
    const {url_backend }= this.props.context;
    const headers = this.getHeaders();
    //formatear semestre origen
    const request = new Request(
      `${url_backend}/edna/copiaNius/nius/${selectCodiSemestre}/${selectAsignatura}/${idiomaOrigen}`,
      {
        method: "GET",
        credentials: "include",
        headers: headers
      }
    );
    let response= await fetch(request);
    let data =await response.json();
    $("#dataTable").empty();
    data.forEach((datos) => {
      $("#dataTable").append("<tr><td class='col-xs-8'>" + datos.titol + "</td><td class='col-xs-4'>" + datos.idioma.toString().toUpperCase() + "</td></tr>");
    });
    
  }

  windowBack(){
    var urlCopiar;
    const { url_backend,lang,defaultLang,langs,userId,codeAsig,anyAcademic,titleAsig,s,sisCourseId} = this.props.context;
    if(sisCourseId){
      urlCopiar="/?lang="+lang+"&defaultLang="+defaultLang+"&langs="+langs+"&userId="+userId+"&codeAsig="+codeAsig+"&anyAcademic="+anyAcademic+"&titleAsig="+titleAsig+"&s="+s+"&url_backend="+url_backend+"&sisCourseId="+sisCourseId+"#/gestionarNius";
    }else{
      urlCopiar="/?lang="+lang+"&defaultLang="+defaultLang+"&langs="+langs+"&userId="+userId+"&codeAsig="+codeAsig+"&anyAcademic="+anyAcademic+"&titleAsig="+titleAsig+"&s="+s+"&url_backend="+url_backend+"#/gestionarNius";
    }
    window.location.href = urlCopiar;
  }

  async crearCopia(){
    const { url_backend,langs,codeAsig,anyAcademic,lang} = this.props.context;
    //selectSemestre cambiar el formato
    const {copiaSelectSemestre,selectAsignatura,idiomaOrigen} = this.state;
    
    this.setState({ isLoading: true})

    /*crear copia*/
    const headers = this.getHeaders();
    const request = new Request(
      `${url_backend}/edna/peticions/noSAMLcopiaSemestres?codeAsig=${selectAsignatura}&codeAssigDesti=${codeAsig}&semestreOrigen=${copiaSelectSemestre}&semestreDesti=${anyAcademic}&idiomaOrigen=${idiomaOrigen}&idiomesDesti=${encodeURIComponent(langs)}`,
      {
        method: "GET",
        credentials: "include",
        headers: headers
      }
    );
      let response= await fetch(request);
    /*copia realizada*/
    this.setState({ isLoading: false})
      if(response.status===200){
        this.setState({ showCorrect: true})
        }
      if(response.status!==200){
      this.setState({ showError: true})
      }
      $("#destinoDiv").show();
      $("#origenDiv").show();
      $("#infoOrigenDestino").show();
      $('#buttonCopia').hide();
      $('#selectores').hide();
      switch (lang) {
        case 'es':
          $('#literalBtnCancel').html('Volver a la Tabla de NIUS');
          break;
        case 'ca':
          $('#literalBtnCancel').html('Tornar a la Taula de NIUS');
          break;
        case 'en':
          $('#literalBtnCancel').html('Return to NIUS Table');
          break;
        default:
          $('#literalBtnCancel').html('Volver a la Tabla de NIUS');
      }
    
      this.rellenarTabla();
      $("#divTableNius").show();
      $("#bannerLang").hide();

  }

  render() {
    const { isLoading ,selectAsignatura,selectSemestre,datosAsignatura,showCorrect,showError,idiomaOrigen,semestreDestino,idiomaDestino} = this.state;
    const { codeAsig } = this.props.context;
    if (isLoading){
      return <Loading />;
    }
     return (
    <Fragment>
       <Skippy />
       <div>
            <header id="header" role="banner" className="fixed-heading">
            <div className="container container--expand heighHeader">
                    <Header />
                    
                </div>
            </header>
            <div className="container container--expand m-bottom-2y containerCopia" >
                <table />
              <div className="steps-flex" id="selectores">
              <div className="col-xs-6 inline-grid m-right-2x">
                    <span className="p-bottom-2y"><Translate id="copiaNius.literalAsignatura"/></span>
                      <Autocomplete
                        disableClearable
                        // value={selectAsignatura}
                        className="copyNiuSelect"
                        id="selAsig"
                        options={datosAsignatura}
                        defaultValue={selectAsignatura}
                        sx={{ width: 300 , height: 20 }}
                        renderInput={(params) => <TextField {...params} label=" " />}
                        onChange={() => this.asignatureSelect()}
                      />
               </div>
               {/* {this.plugSemestreSelect()} */}
               <div className="col-xs-6 inline-grid m-right-2x caja">
                    <span className="p-bottom-2y"><Translate id="copiaNius.literalSemestre"/></span>
                    <select value={selectSemestre} className="copyNiuSelect col-xs-6" id="selSemestre" onChange={() => this.semestreSelect()} disabled="disabled" >
                      <option value="">---------------</option>
                    </select>     
               </div>
            </div>
            {/*  */}
            <div id="Alertinfo" className="containeralert">
            <Alert className="alert--error" variant="danger" show={showError}>
              <Alert.Heading id="alertError" className="alertMensaje"><Translate id="copiaNius.copiaError"/></Alert.Heading>
            </Alert>
           
            <Alert className="alert" show={showCorrect}>
              <Alert.Heading id="alertCorrect" className="alertMensaje"><Translate id="copiaNius.copiaCorrect"/></Alert.Heading>
            </Alert>
            </div>

            <div id="bannerLang" className="bannerLang">
              <Alert variant="-info-resource">
              <Translate id="copiaNius.literalIdioma"/>
              </Alert>
            </div>
            {/* info copiar */}
            <div className="m-2y col-xs-12 place-center info-copiar">
              <div id="infoOrigenDestino">
                <h2><Translate id="copiaNius.tituloDatos"/></h2>
                <div className="inline-grid col-xs-6 align-left hidden" id="origenDiv" >
                  <h3><Translate id="copiaNius.origen"/></h3>
                  <div className="seeInfo">
                    <span><Translate id="copiaNius.semestre"/> {selectSemestre}</span>
                    <span><Translate id="copiaNius.asignatura"/> {selectAsignatura} </span>
                    <span><Translate id="copiaNius.idioma"/> {idiomaOrigen}</span>
                  </div>
                </div>
                <div className="inline-grid col-xs-6 align-left hidden" id="destinoDiv">
                  <h3><Translate id="copiaNius.destino"/></h3>
                  <div className="seeInfo">
                    <span><Translate id="copiaNius.semestre"/> {semestreDestino}</span>
                    <span><Translate id="copiaNius.asignatura"/> {codeAsig}</span>
                    <span><Translate id="copiaNius.idioma"/> {idiomaDestino} </span>
                  </div>
                </div>
              </div>
            </div>
     
            <div id="NiuNoCopia" className="NiuNoCopia">
            <Alert variant="-warning" style={{ width: "-webkit-fill-available"} }>
              <Translate id="copiaNius.alertNoCopia"/>
              <p><span id="recursosNoCopiados">{this.mostrarTextNoCopia()}</span></p>
            </Alert>
            </div>

            <div className="container container--expand copiaTableNius" id="divTableNius">
              <h2><Translate id="copiaNius.literalTablaNiu"/></h2>
              <table className="table tableCopia col-xs-6">
                <tr>
                  <th className="col-xs-8"><Translate id="copiaNius.tablaNombre"/></th>
                  <th className="col-xs-4"><Translate id="copiaNius.tablaIdioma"/></th>
                </tr>
                <span id="dataTable" className="dataTable">

                </span>
              </table>
             </div>
             {/* Botones */}
              <div className="divBotones">
              <ul className="list--unstyled list--inline">
           <li>
               <button
                 type="button"
                 className="btn btn--primary btn--large btn--save"
                 onClick={() => this.windowBack()}

               >
               <span className="btn-label" id="literalBtnCancel">
               <Translate id="copiaNius.btnCancelarCopia"/>
               </span>
             </button>
             </li>
             <li> 
               <button
               id="buttonCopia"
               type="button"
               className="btn btn--primary btn--large"
               onClick={() => this.crearCopia()}
              >
               <span className="btn-label">
               <Translate id="copiaNius.btnCopiaNiu"/>                     
               </span>
             </button>
           </li>
         </ul>
              </div>
                
            </div>
        </div>                    
    </Fragment>    
     );
    }
  }
  const mapStateToProps = state => {
    return {
      
      context: state.context,
      lang: state.context.lang,
      loading: state.loading,
      state: state.form.state
    };
  };
  export default withLocalize(
    connect(
      mapStateToProps,
      null
    )(CopiarNius)
  );
