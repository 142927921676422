import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { updateForm } from "../actions/form";
import { animateScroll as scroll } from "react-scroll";

const AsidePreview = ({ langs, lang, langPreview, updateForm }) => {
  return (
    <aside className="pane-nav">
      <ul className="nav-resources">
        {langs.map(l => {
          const liClass = classNames("li--preview", {
            "is-active": l === langPreview
          });
          return (
            <li className={liClass} key={l}>
              <button
                type="button"
                className="btn--unstyled btn--nav btn--nav__preview"
                onClick={() => {
                  updateForm({ langPreview: l });
                }}
                aria-current={l === langPreview}
              >
                <Translate>
                  {({ translate }) =>
                    translate("form.niuLang", {
                      lang: translate("langs." + l).toLowerCase()
                    })
                  }
                </Translate>
              </button>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

AsidePreview.propTypes = {
  lang: PropTypes.string.isRequired,
  langs: PropTypes.array.isRequired,
  langPreview: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    langs: state.context.langs,
    lang: state.context.langs.includes(state.context.lang)
      ? state.context.lang
      : state.context.defaultLang,
    langPreview: state.form.langPreview
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateForm: data => {
      dispatch(updateForm(data));
      scroll.scrollToTop({
        duration: 500,
        smooth: true
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AsidePreview);
