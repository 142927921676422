import { combineReducers } from "redux";
import { context } from "./context";
import { loading, saving } from "./ui";
import { resources, niu, errors, aws } from "./api";
import { ous, form , fav } from "./form";

export default combineReducers({
  context,
  loading,
  saving,
  errors,
  resources,
  niu,
  ous,
  form,
  aws,
  fav
});
