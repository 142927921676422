import React, { Component, Fragment} from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import Header from "../components/Header";
import Skippy from "../components/Skippy";
import Loading from "../components/Loading";
import { withLocalize } from "react-localize-redux";
import ConfirmModal from "../components/ConfirmModal";
import * as constantsRALTI from "../constants/constantsRALTI";
import * as constantsEnvironment from "../constants/constantsEnvironment";

class TableNius extends Component {
    constructor(props) {
      super(props);

      this.state = {
        niusList: [],
        isLoading: false,
        show: false,
        idBorrar:null,
        idniu:null,
      };
      this.papeleraNiuAparece = this.papeleraNiuAparece.bind(this)
      this.papeleraNiuOculta = this.papeleraNiuOculta.bind(this)
      this.borrarNiu = this.borrarNiu.bind(this)
      this.changeState = this.changeState.bind(this)
      this.editaNiu = this.editaNiu.bind(this)
      this.nombreNiu = this.nombreNiu.bind(this)
      this.datosNiu = this.datosNiu.bind(this)
      this.trNiu = this.trNiu.bind(this)
      this.urlRALTI = this.urlRALTI.bind(this)
      this.creaNiu = this.creaNiu.bind(this)
      this.copiarNiu = this.copiarNiu.bind(this)
      this.altatr = this.altatr.bind(this)
  }

  async componentDidMount(){
    this.setState({ isLoading: true });
    let datos =await this.datosNiu();
    await this.setState({ niusList: datos,isLoading: false});
  }


  async datosNiu() {
    const { url_backend, anyAcademic, codeAsig } = this.props.context;
    this.setState({ isLoading: true });
    const headers = this.getHeaders();

    const request = new Request(
      `${url_backend}${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_NIUS}/${codeAsig}/${anyAcademic}`,
      {
        method: "GET",
        credentials: "include",
        headers: headers
      }
    );
    let response= await fetch(request);
    let datos;
    if(response.status===200){
      datos= await response.json();
      }
    if(response.status!==200){
    datos=null;
    }
   return datos;

  }

  getHeaders () {
    const {  lang } = this.props.context;
    return {
      Accept: "application/json",
      "Accept-Language": lang || "ca"
    };
  };
  papeleraNiuAparece(id) {
    this.setState({ show: true , idBorrar:id });
  }
  papeleraNiuOculta() {
    this.setState({ show: false })
  }

  async borrarNiu(){
   
    const {url_backend } = this.props.context;
    this.setState({ isLoading: true });
    const headers = this.getHeaders();
    const request = new Request(
      `${url_backend}${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_NIUS}/${this.state.idBorrar}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: headers
      }
    );
      await fetch(request);
      let datos =await this.datosNiu();
      await this.setState({ niusList: datos,isLoading: false,show: false});
  }

  async changeState(id,state){ 
    const {url_backend} = this.props.context;
    this.setState({ isLoading: true });
    const headers = this.getHeaders();
    const request = new Request(
      `${url_backend}${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_NIUS}/${id}/${state=!state}`,
      {
        method: "PUT",
        credentials: "include",
        headers: headers
      }
    );

    await fetch(request);
    let datos =await this.datosNiu();

    await this.setState({ niusList: datos,isLoading: false});
  }

  editaNiu(id){
    var urlEdita;
    const { url_backend,lang,defaultLang,langs,userId,codeAsig,anyAcademic,titleAsig,s,sisCourseId} = this.props.context;
      if(sisCourseId){
       urlEdita="/?lang="+lang+"&defaultLang="+defaultLang+"&langs="+langs+"&userId="+userId+"&codeAsig="+codeAsig+"&anyAcademic="+anyAcademic+"&titleAsig="+titleAsig+"&s="+s+"&url_backend="+url_backend+"&sisCourseId="+sisCourseId+"&niuId="+id+"&origen=canvas";
     }else{
      urlEdita="/?lang="+lang+"&defaultLang="+defaultLang+"&langs="+langs+"&userId="+userId+"&codeAsig="+codeAsig+"&anyAcademic="+anyAcademic+"&titleAsig="+titleAsig+"&s="+s+"&url_backend="+url_backend+"&niuId="+id+"&origen=canvas";     
    }
      window.location.href = urlEdita;
  }
  creaNiu(){
    var urlCrear;
     const { url_backend,lang,defaultLang,langs,userId,codeAsig,anyAcademic,titleAsig,s,sisCourseId} = this.props.context;
     if(sisCourseId){
       urlCrear="/?lang="+lang+"&defaultLang="+defaultLang+"&langs="+langs+"&userId="+userId+"&codeAsig="+codeAsig+"&anyAcademic="+anyAcademic+"&titleAsig="+titleAsig+"&s="+s+"&url_backend="+url_backend+"&sisCourseId="+sisCourseId+"&origen=canvas";
     }else{
      urlCrear="/?lang="+lang+"&defaultLang="+defaultLang+"&langs="+langs+"&userId="+userId+"&codeAsig="+codeAsig+"&anyAcademic="+anyAcademic+"&titleAsig="+titleAsig+"&s="+s+"&url_backend="+url_backend+"&origen=canvas";     
    }
      window.location.href = urlCrear;
  }

  copiarNiu(){
    var urlCopiar;
    const { url_backend,lang,defaultLang,langs,userId,codeAsig,anyAcademic,titleAsig,s,sisCourseId} = this.props.context;
    if(sisCourseId){
      urlCopiar="/?lang="+lang+"&defaultLang="+defaultLang+"&langs="+langs+"&userId="+userId+"&codeAsig="+codeAsig+"&anyAcademic="+anyAcademic+"&titleAsig="+titleAsig+"&s="+s+"&url_backend="+url_backend+"&sisCourseId="+sisCourseId;
    }else{
     urlCopiar="/?lang="+lang+"&defaultLang="+defaultLang+"&langs="+langs+"&userId="+userId+"&codeAsig="+codeAsig+"&anyAcademic="+anyAcademic+"&titleAsig="+titleAsig+"&s="+s+"&url_backend="+url_backend;     
   }
    window.location.href = urlCopiar+"#/copiarNius";
  }

  trNiu(niuActual){
    let tr="";
    if(niuActual.state==="P"){
      tr="noModify"
    }
    if(niuActual.visible===false && tr===""){
      tr="oculto"
    }
    if(niuActual.visible===true && tr===""){
      tr=""
    }
    return (tr);
  }

  urlRALTI(niuMultilang){
    const { codeAsig ,anyAcademic} = this.props.context;
    let entornActual = window.location.hostname;
    let url_ralti = "";
    if (constantsEnvironment.ENVIRONMENT_LOCAL.includes(entornActual)){
      url_ralti = constantsRALTI.RALTI_PREVIEW_TEST;
    }
    else if (constantsEnvironment.ENVIRONMENT_TEST.includes(entornActual)){
      url_ralti = constantsRALTI.RALTI_PREVIEW_TEST;
    }
    else if (constantsEnvironment.ENVIRONMENT_PRE.includes(entornActual)){
      url_ralti = constantsRALTI.RALTI_PREVIEW_PRE;
    }
    else if (constantsEnvironment.ENVIRONMENT_PRO.includes(entornActual)) {
      url_ralti = constantsRALTI.RALTI_PREVIEW_PRO;
    }
    
    return url_ralti+niuMultilang.idNiu+"/"+anyAcademic+"/"+codeAsig+"/"+niuMultilang.langId;
  }

  nombreNiu(niuActual){
    const { defaultLang ,lang} = this.props.context;
    var niu = Object.values(niuActual);
    let name="";

    niu.map((niuActual) => 
    <>
      {niuActual.langId===defaultLang ? (
      name=(niuActual.name)
      ): null}

      {niuActual.langId===lang && name==="" ? (
      name=(niuActual.name)
      ): null}

      {name==="" && niuActual.langId==="es"? (
      name=(niuActual.name)
      ): null}

      {name==="" && niuActual.langId==="ca"? (
      name=(niuActual.name)
      ): null}

      {name==="" && niuActual.langId==="en"? (
      name=(niuActual.name)
      ): null}
    </>
    )
     
     if(name==="" && niu.length===1){
      name=(niu[0].name);
    }

    return name;
  }


  altatr(traduccionid){
    const {lang} = this.props.context;
    let alt="";
  
    if(traduccionid==="trash"){
      if(lang==="es"){
        alt="Borrar NIU "
      }
      if(lang==="ca"){
        alt="Esborrar NIU "
      }
      if(lang==="en"){
        alt="Delete NIU "
      }
    }

    if(traduccionid==="redCa"){
      if(lang==="es"){
        alt="Redireccionamiento del recurso en Catalan "
      }
      if(lang==="ca"){
        alt="Redireccionament recurs en Català "
      }
      if(lang==="en"){
        alt="Resource redirection in Catalan "
      }
    }

    if(traduccionid==="redEs"){
      if(lang==="es"){
        alt="Redireccionamiento del recurso en Español "
      }
      if(lang==="ca"){
        alt="Redireccionament recurs en Espanyol "
      }
      if(lang==="en"){
        alt="Resource redirection in Spanish "
      }
    }

    if(traduccionid==="redEn"){
      if(lang==="es"){
        alt="Redireccionamiento del recurso en Inglés "
      }
      if(lang==="ca"){
        alt="Redireccionament recurs en Anglés "
      }
      if(lang==="en"){
        alt="Resource redirection in English "
      }
    }
    if(traduccionid==="estadoV"){
      if(lang==="es"){
        alt="Ocultar NIU "
      }
      if(lang==="ca"){
        alt="Ocultar NIU "
      }
      if(lang==="en"){
        alt="Make visible NIU "
      }
    }

    if(traduccionid==="estadoO"){
      if(lang==="es"){
        alt="visibilizar NIU "
      }
      if(lang==="ca"){
        alt="Fer visible NIU "
      }
      if(lang==="en"){
        alt="Hide NIU "
      }
    }

    if(traduccionid==="edita"){
      if(lang==="es"){
        alt="Editar NIU "
      }
      if(lang==="ca"){
        alt="Editar NIU "
      }
      if(lang==="en"){
        alt="Modify NIU "
      }
    }

    if(traduccionid==="tablaNIUS"){
      if(lang==="es"){
        alt="Tabla NIUS con columnas Nombre del NIU , Idioma, Estado , Editar "
      }
      if(lang==="ca"){
        alt="Tabla NIUS amb columnes Nom del NIU , Idioma, Estat , Edició"
      }
      if(lang==="en"){
        alt="NIUS Table with columns Name of the NIU , Idiom, State , Edit"
      }
    }
    

    return alt;
  }

  render() {
    const { niusList, isLoading } = this.state;
    if (isLoading){
      return <Loading />;
    }    
     return (
       <Fragment>
       <Skippy />
       <div>
         <header id="header" role="banner" className="fixed-heading">
           <div className="container container--expand heighHeader">
             <Header />
             {/* <Title /> */}
           </div>
         </header>
         
         <div className="container container--expand divTableNius">
          
         <div>
         <ul className="list--unstyled list--inline botones-superiores">
           <li>
               <button
                 type="button"
                 className="btn btn--primary btn--large btn--save"
                 onClick={() => this.creaNiu()}
               >
               <span className="btn-label">
                   <Translate id="form.createNiuButton" />
               </span>
             </button>
             </li>
             <li> 
               <button
               type="button"
               className="btn btn--primary btn--large "
               onClick={() => this.copiarNiu()}
              >
               <span className="btn-label">
                   <Translate id="form.copyNiuButton" />                     
               </span>
             </button>
           </li>
         </ul>
       </div>
         <table className="table" aria-label={this.altatr("tablaNIUS")} role="table">
                       <tr>
                           <th className="col-xs-6"><Translate id="form.recursos"/></th>
                           <th className="col-xs-2"><Translate id="form.lang" /></th>
                           <th className="col-xs-2"><Translate id="form.state" /></th>
                           <th className="col-xs-2"><Translate id="form.edit" /></th>
                       </tr>
         {niusList!==null ? (
                      <>
                       {  niusList.map((niuActual) => 
                             <tr key={niuActual.id} className={this.trNiu(niuActual)}  >
                             <td className="col-xs-6">
                              {this.nombreNiu(niuActual.niuMultilangList)}
                             </td>
                             {/* IDIOMAS */}
                             <td  className="col-xs-2">
                               {niuActual.niuMultilangList.map((niuMultilang) => 
                                 <>
                                  {niuMultilang.visible==="T" ? (
                                    <>
                                    {niuMultilang.langId==="ca" ? (
                                      <a className="lang" href= {this.urlRALTI(niuMultilang)} target="_blank" rel="noopener noreferrer" aria-label={this.altatr("redCa")+this.nombreNiu(niuActual.niuMultilangList)}>{niuMultilang.langId.toUpperCase()}</a>
                                        ): null}
                                       {niuMultilang.langId==="es" ? (
                                      <a className="lang" href= {this.urlRALTI(niuMultilang)} target="_blank" rel="noopener noreferrer" aria-label={this.altatr("redEs")+this.nombreNiu(niuActual.niuMultilangList)}>{niuMultilang.langId.toUpperCase()} </a>
                                        ): null}
                                       {niuMultilang.langId==="en" ? (
                                      <a className="lang" href= {this.urlRALTI(niuMultilang)} target="_blank" rel="noopener noreferrer" aria-label={this.altatr("redEn")+this.nombreNiu(niuActual.niuMultilangList)}>{niuMultilang.langId.toUpperCase()} </a>
                                        ): null}
                                        </>
                                 ): null}
                                 
                                 </>
                                 )}
                             </td>
                             {niuActual.state==="F" ? (
                                <>
                                <td  className="col-xs-2">
                                <div class="divTableCell col-10 cell-niu cell-niu-state" role="gridcell" aria-labelledby="col-state-niu">
                               <div class="form-check--state">
                                 <label for={"niu-check-eye-"+niuActual.id} onClick={() => this.changeState(niuActual.id,niuActual.visible)}> 
                                  <input type="checkbox" id={"niu-check-eye-"+niuActual.id} aria-hidden="true" value={niuActual.visible}/>
                                  {niuActual.visible ? (
                                 <>
                                 <span class="icon icon--show estado" aria-hidden="true" />
                                   <span class="label estado" aria-label={this.altatr("estadoV")+this.nombreNiu(niuActual.niuMultilangList)}> <Translate id="form.estadoV"/>
                                     <span class="visually-hidden">
                                     <Translate id="form.txtVisible"/>
                                     </span>
                                   </span>
                                 </>
                                 ): 
                                 <>
                                <span class="icon icon--hidden" aria-hidden="true" />
                                   <span class="label estado" aria-label={this.altatr("estadoO")+this.nombreNiu(niuActual.niuMultilangList)}> <Translate id="form.estadoO"/>
                                     <span class="visually-hidden">
                                     <Translate id="form.txtOcult"/>
                                     </span>
                                   </span>
                                 </>
                                 } 
                                 </label>
                               </div>
                             </div>
                                </td>
                                <td  className="col-xs-2">
                                  <div class="divTableCell col-10 cell-niu cell-niu-state" role="gridcell" aria-labelledby="col-state-niu">
                                   <div class="form-check--state">
                                   
                                     <span class="label" >
                                     <button  className="buttonTable"onClick={() => this.editaNiu(niuActual.id)} aria-label={this.altatr("edita")+this.nombreNiu(niuActual.niuMultilangList)}><Translate id="form.stateF"/></button>
                                     </span>
                                     
                                     <button className="rightIcon buttonTable" aria-label={this.altatr("trash")+this.nombreNiu(niuActual.niuMultilangList)} onClick={() => this.papeleraNiuAparece(niuActual.id)}><span class="icon icon--trash icon-tableniu-edit" aria-hidden="true"/></button>
                               </div>
                             </div>
                             </td>
                             </>
                             ): null}
                             {/* Estado M */}
                               {niuActual.state==="M" ? (
                                <>
                                <td  className="col-xs-2">
                                <div class="divTableCell col-10 cell-niu cell-niu-state" role="gridcell" aria-labelledby="col-state-niu">
                               <div class="form-check--state" >
                                 <label for={"niu-check-eye-"+niuActual.id} onClick={() => this.changeState(niuActual.id,niuActual.visible)}> 
                                 <input type="checkbox" id={"niu-check-eye-"+niuActual.id} aria-hidden="true" value={niuActual.visible}/>
                                  {niuActual.visible ? (
                                 <>
                                 <span class="icon icon--show estado" aria-hidden="true" />
                                   <span class="label estado" aria-label={this.altatr("estadoV")+this.nombreNiu(niuActual.niuMultilangList)}> <Translate id="form.estadoV"/>
                                     <span class="visually-hidden">
                                     <Translate id="form.txtVisible"/>
                                     </span>
                                   </span>
                                 </>
                                 ): 
                                 <>
                                <span class="icon icon--hidden" aria-hidden="true" />
                                   <span class="label estado" aria-label={this.altatr("estadoO")+this.nombreNiu(niuActual.niuMultilangList)}> <Translate id="form.estadoO"/>
                                     <span class="visually-hidden">
                                     <Translate id="form.txtOcult"/>
                                     </span>
                                   </span>
                                 </>
                                 } 
                                 </label>
                               </div>
                             </div>
                                </td>
                                <td  className="col-xs-2">
                                  <div class="divTableCell col-10 cell-niu cell-niu-state" role="gridcell" aria-labelledby="col-state-niu">
                                   <div class="form-check--state">
                                     <span class="label">
                                       <button  className="buttonTable" onClick={() => this.editaNiu(niuActual.id)}><Translate id="form.stateM"/></button>
                                     </span>
                                     <button className="rightIcon buttonTable" aria-label={this.altatr("trash")+this.nombreNiu(niuActual.niuMultilangList)}  ><span class="icon icon--trash icon-tableniu-edit" aria-hidden="true" onClick={() => this.papeleraNiuAparece(niuActual.id)}/></button>
                               </div>
                             </div>
                             </td>
                             </>
                             ): null}
                            {/* Estado B */}
                                  {niuActual.state==="B" ? (
                                <>
                                <td  className="col-xs-2">
                                <div class="divTableCell col-10 cell-niu cell-niu-state" role="gridcell" aria-labelledby="col-state-niu">
                               <div class="form-check--state">
                                 <label for={"niu-check-eye-"+niuActual.id} onClick={() => this.changeState(niuActual.id,niuActual.visible)}> 
                                 <input type="checkbox" id={"niu-check-eye-"+niuActual.id} aria-hidden="true" value={niuActual.visible}/>
                                  {niuActual.visible ? (
                                 <>
                                 <span class="icon icon--show estado" aria-hidden="true" />
                                   <span class="label estado" aria-label={this.altatr("estadoV")+this.nombreNiu(niuActual.niuMultilangList)}> <Translate id="form.estadoV"/>
                                     <span class="visually-hidden">
                                     <Translate id="form.txtVisible"/>
                                     </span>
                                   </span>
                                 </>
                                 ): 
                                 <>
                                <span class="icon icon--hidden" aria-hidden="true" />
                                   <span class="label estado" aria-label={this.altatr("estadoO")+this.nombreNiu(niuActual.niuMultilangList)}> <Translate id="form.estadoO"/>
                                     <span class="visually-hidden">
                                     <Translate id="form.txtOcult"/>
                                     </span>
                                   </span>
                                 </>
                                 }                 
                                 </label>
                               </div>
                             </div>
                                </td>
                                <td  className="col-xs-2">
                                  <div class="divTableCell col-10 cell-niu cell-niu-state" role="gridcell" aria-labelledby="col-state-niu">
                                   <div class="form-check--state">
                                     <span class="label">
                                     <button className="buttonTable" onClick={() => this.editaNiu(niuActual.id)}><Translate id="form.stateB"/></button>
                                     </span>
                                   <button className="rightIcon buttonTable" aria-label={this.altatr("trash")+this.nombreNiu(niuActual.niuMultilangList)} ><span class="icon icon--trash icon-tableniu-edit" aria-hidden="true"  onClick={() => this.papeleraNiuAparece(niuActual.id)} /></button>
                               </div>
                             </div>
                             </td>
                             </>
                             ): null}
                            
                             </tr>
                             )}
                          </>
                         ):null}
                         
                         </table>
        
                      <br></br>
                      {niusList===null ? (
                      <>
                      <p className="tablavacia">
                        <Translate id="form.TablaVacia" /> 
                      </p>
                      </>
                      ):null}
         <div>
         <ul className="list--unstyled list--inline">
           <li>
               <button
                 type="button"
                 className="btn btn--primary btn--large btn--save"
                 onClick={() => this.creaNiu()}
               >
               <span className="btn-label">
                   <Translate id="form.createNiuButton" />
               </span>
             </button>
             </li>
             <li> 
               <button
               type="button"
               className="btn btn--primary btn--large "
               onClick={() => this.copiarNiu()}
              >
               <span className="btn-label">
                   <Translate id="form.copyNiuButton" />                     
               </span>
             </button>
           </li>
         </ul>
       </div>
       </div>
       </div>
       <ConfirmModal
              show={this.state.show}
              onHide={this.papeleraNiuOculta}
              onConfirm={this.borrarNiu}
            />                         
     </Fragment>    
     );
    }
  }
  const mapStateToProps = state => {
    return {
      
      context: state.context,
      lang: state.context.lang,
      loading: state.loading,
      state: state.form.state
    };
  };
  export default withLocalize(
    connect(
      mapStateToProps,
      null
    )(TableNius)
  );
