import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import Format from "./Format";

const FormatsList = ({ formats = [], s = '' }) => (
  <ul className="list--unstyled list--inline list--formats">
    {formats.length === 0 ? (
      <li>
        <div className="btnlink btnlink--format">
          <Translate id="errors.noFormats" />
        </div>
      </li>
    ) : (
      formats.map((format, index) => (
        <Format key={index} code={format.code} url={format.url.replace('$SESSIONID$', s)} />
      ))
    )}
  </ul>
);

FormatsList.propTypes = {
  formats: PropTypes.array.isRequired,
  s: PropTypes.string
};

export default FormatsList;
