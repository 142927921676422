import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const InfoTooltip = props => {
  const { id, tooltip, className } = props;
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id={id} style={{ margin: ".2rem" }} className={className}>
          {tooltip}
        </Tooltip>
      }
      placement="right"
    >
      <button
        type="button"
        className="btn--unstyled btn--infotip"
        aria-label="Info"
      >
        <span className="icon icon--info-full" aria-hidden="true" />
      </button>
    </OverlayTrigger>
  );
};

export default InfoTooltip;
