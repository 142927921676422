import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import logo from "../images/logo-uoc-default.png";

const Header = ({ titleAsig = "" }) => (
  <section>
    <div className="header brand-header-light">
      <div className="header-container clearfix">
        <div className="row">
          <div className="col-xs-4 col-lg-3">
            <span className="top-logo">
              <img src={logo} alt="Logo UOC" />
            </span>
            <p className="top-slogan ruler ruler--secondary ruler--double">
              Universitat Oberta <br aria-hidden="true" /> de Catalunya
            </p>
          </div>
          <div className="col-xs-8 col-lg-9">
            <div className="top-title-wrapper ruler ruler--secondary ruler--double">
              <h1 className="top-title">
                <Translate id="header.title" />
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const mapStateToProps = state => {
  return {
    titleAsig: state.context.titleAsig
  };
};

Header.propTypes = {
  titleAsig: PropTypes.string
};

export default connect(
  mapStateToProps,
  null
)(Header);