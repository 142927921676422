import React, { Component } from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { Alert } from "react-bootstrap";

class AlertAppear extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      show: false
    };
  }

  componentDidMount() {
    const { show } = this.props;
    if (show) {
      this.timer = setTimeout(() => {
        this.setState({
          show: show
        });
      }, 600);
    }
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer);
  }

  render() {
    const { variant, message, onClose } = this.props;
    return (
      <Translate>
        {({ translate }) => (
          <Alert
            className="alert-appear"
            dismissible
            variant={variant}
            show={this.state.show}
            closeLabel={translate("help.close")}
            onClose={() => {
              this.setState({ show: false });
              onClose();
            }}
          >
            {message}
          </Alert>
        )}
      </Translate>
    );
  }
}

AlertAppear.defaultProps = {
  show: true,
  variant: "-info",
  message: "",
  onClose: f => f
};

AlertAppear.propTypes = {
  show: PropTypes.bool,
  variant: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func
};

export default AlertAppear;
