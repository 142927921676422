import queryString from "query-string";
import { resetValidation } from "../utils/validation";

const getContext = () => {
  let context = {
    // idioma de navegación del usuario
    lang: "ca",
    // idioma de docencia principal del aula
    defaultLang: "ca",
    // idiomas en que se imparte la asignatura
    langs: ["ca", "es", "en"],
    s: "[SESSION]",
    codeAsig: "[CODEASIG]",
    anyAcademic: "[ANYACADEMIC]",
    titleAsig: "[ Título de la asignatura ]",
    userId: "[USERID]",
    niuId: null,
    origen: null,
    url_backend: "[URL_BACKEND]"
  };


  const parsed = queryString.parse(window.location.search);

  if (parsed.lang) context.lang = parsed.lang;
  if (parsed.defaultLang) context.defaultLang = parsed.defaultLang;
  if (parsed.s) context.s = parsed.s;
  if (parsed.semester) context.semester = parsed.semester;
  if (parsed.codeAsig) context.codeAsig = parsed.codeAsig;
  if (parsed.anyAcademic) context.anyAcademic = parsed.anyAcademic;
  if (parsed.titleAsig) context.titleAsig = parsed.titleAsig;
  if (parsed.niuId) context.niuId = parsed.niuId;
  if (parsed.userId) context.userId = parsed.userId;
  if (parsed.langs) context.langs = parsed.langs.split(",");
  if (parsed.origen) context.origen = parsed.origen;
  if (parsed.sisCourseId) context.sisCourseId = parsed.sisCourseId;
  if (parsed.url_backend) context.url_backend = parsed.url_backend;


  context.langs.sort(function(a, b) {
    if (a === context.defaultLang) return -1;
    if (b === context.defaultLang) return 1;
    else return 0;
  });

  return context;
};

const getNiuLangFields = (langs = []) => {
  const fields = [];
  for (const lang of langs) {
    fields.push({ langId: lang, name: "" });
  }
  return fields;
};

const getLangFields = (langs = [], items) => {
  const fields = {};
  for (const lang of langs) {
    for (const item of items) {
      fields[item + "_" + lang] = "";
    }
  }
  return fields;
};

const getLangStateFields = (langs = []) => {
  const fields = {};
  const items = ["st_sel_collapse", "st_ed_collapse"];
  for (const lang of langs) {
    for (const item of items) {
      fields[item + "_" + lang] = false;
      if (item === "st_sel_collapse") {
        for (const l of langs) {
          fields[item + "_" + lang + "_" + l] = false;
        }
        fields[item + "_" + lang + "_others"] = false;
      }
    }
  }

  return fields;
};

export const getInitialState = () => {
  const context = getContext();
  const initialState = {
    loading: true,
    saving: false,
    context: context,
    errors: [],
    resources: [],
    fav:{
      favImages: [],
      favfolder:false
    },
    aws: {
      s3_imatges_endpoint: "https://s3.eu-west-1.amazonaws.com",
      s3_imatges_region: "eu-west-1",
      s3_imatges_bucket: "niu-test.materials",
      s3_imatges_folder: "imatgesOus",
      folder: "imatgesOus/",
      data: [],
      error: null,
      loading: true,

    },
    niu: {
      id: null,
      url: null,
      state: "B",
      step: 0,
      createUser: context.userId,
      lastUserUpdate: context.userId,
      codeAsig: context.codeAsign,
      niuMultilangList: getNiuLangFields(context.langs),
      ous: []
    },
    ous: [],
    form: {
      step: 0,
      state: "B",
      isNew: true,
      isUpdated: false,
      isMultilang: context.langs.length > 1,
      ou: null,
      validation: resetValidation(),
      helpSelect: true,
      helpEdit: true,
      helpPreview: true,
      langPreview:
        context.langs.length > 0 ? context.langs[0] : context.defaultLang,
      modalPicker: false,
      modalRelevance: false,
      modalRelItemA: null,
      modalRelItemB: null,
      sortLive: null,
      favfolder:false,
      userImageFav:[],
      ...getLangFields(context.langs, ["title"]),
      ...getLangStateFields(context.langs)
    }
  };
  return initialState;
};

export const formatResources = resources => {
  resources = resources || [];
  let index = 0;
  for (let res of resources) {
    res.index = index;
    res.id = res.id.toString();
    index++;
  }
  return resources;
};

export const formatNiu = (data, langs) => {
  data = data || {};
  let state = {
    step: data.step ? data.step - 1 : 0,
    state: data.state || "B",
    createUser: data.createUser,
    isNew: false,
    isUpdated: false,
    id: data.id,
    helpSelect: data.state !== "F",
    helpEdit: data.state !== "F",
    helpPreview: data.state !== "F"
  };

  let nLangsNiu = 0;
  let nLangsAula = langs.length;
  let defaultTitle = "";

  if (Array.isArray(data.niuMultilangList)) {
    nLangsNiu = data.niuMultilangList.length;
    for (const item of data.niuMultilangList) {
      if (nLangsNiu < nLangsAula) defaultTitle = item.name || "";
      state["title_" + item.langId] = item.name || "";
    }
  }

  if (nLangsNiu < nLangsAula) {
    for (const lang of langs) {
      if (!state["title_" + lang]) state["title_" + lang] = defaultTitle;
    }
  }

  return state;
};

export const formatNiuDataTable = (data) => {
  data = data || {};
  return data;
};


/**
 * Format bbdd ous to form
 * @param {array} data bbdd ous array
 * @param {array} langs available langs
 */
export const formatOus = (data, langs, resources) => {
  let ous = [];
  let finded;
  data = data || [];
  langs = langs || [];
  resources = resources || [];
  for (let ou of data) {
    if(ou.recursId){
      finded = resources.find(r => r.id.toString() === ou.recursId.toString() && r.lang === (ou.recursLang !== null ? ou.recursLang : r.lang));
      if(finded !== undefined){
        ou.formats = finded.formats ? finded.formats : ou.formats ? ou.formats : [];
        ous.push(formatOu(ou, ou.langId, langs, finded));
      }
    }
  }
  return ous;
};

/**
 * Format bbdd ou to form ou
 * @param {object} ou
 * @param {string} lang niu lang
 * @param {integer} langIndex niu lang index
 * @param {object} resource resource
 */
export const formatOu = (ou, lang, langs, resource) => {
  ou = ou || {};
  const langId = ou.langId || lang;
  const langIndex = langs.indexOf(langId) + langs.indexOf(resource.lang);
  const recursId = ou.recursId
    ? ou.recursId.toString()
    : resource
    ? resource.id
    : null;
  const formated = {
    id: ou.id,
    idx: recursId + "_" + langId + "_" + (ou.recursLang || resource.lang),
    index: resource.index || 0,
    recursId: recursId,
    recursLang: ou.recursLang || lang,
    langId: langId,
    langIndex: langIndex >= 0 ? langIndex : 3,
    rellevancia: ou.rellevancia ? ou.rellevancia.toString() : "3",
    hores: ou.hores || 0,
    minuts: ou.minuts || 0,
    posicio: ou.posicio || 0,
    format: ou.format,
    formats: ou.formats,
    imatge: ou.imatge,
    url: ou.url || null,
    nou: ou.nou || false,
    title: ou.titol || "",
    description: ou.descripcio || "",
    bibliography: ou.bibliografia || "",
    imatgeStyle: ou.imatgeStyle || ""
  };
  return formated;
};

/**
 * Convert Resource to Ou
 * @param {object} resource
 * @param {string} lang niu lang
 * @param {string} langIndex niu lang index
 */
export const convertOu = (resource, lang, langs) => {
  resource = resource || {};
  const langIndex = langs.indexOf(lang) + langs.indexOf(resource.lang);
  const recursId = resource.id ? resource.id.toString() : null;
  let ou = {
    id: null,
    idx: recursId + "_" + lang + "_" + resource.lang,
    index: resource.index,
    recursId: recursId,
    recursLang: resource.lang,
    langId: lang,
    langIndex: langIndex >= 0 ? langIndex : 5,
    rellevancia: "3",
    hores: 0,
    minuts: 0,
    posicio: -1,
    format: null,
    formats: resource.formats,
    imatge: null,
    url: resource.url || null,
    nou: true,
    title: resource.title || "",
    description: resource.description || "",
    bibliography: resource.bibliography || resource.title,
    imatgeStyle: resource.imatgeStyle || "",
  };
  return ou;
};

/**
 * Format bbdd ous to form
 * @param {array} data bbdd ous array
 * @param {array} ous ous cliente array
 */
export const assingOusIds = (data, ous) => {
  let updated = [];
  data = data || [];
  ous = ous || [];
  let finded;
  for (let ou of ous) {
    finded = data.find(o => ou.idx === o.recursId + "_" + o.langId+ "_" + o.recursLang);
    if (finded !== undefined) {
      ou.id = finded.id;
    }
    updated.push(ou);
  }
  return updated;
};

export const setCurrentOu = (current, ous) => {
  ous = ous || [];
  if (ous.length <= 0) return null;
  for (const ou of ous) {
    if (ou.nou) return ou.idx;
  }
  if (!current || ous.find(o => o.idx === current.idx) === undefined) {
    return ous[0].idx;
  }
  return current;
};

export const prepareNiuForSave = getState => {
  const { context, niu, form, ous } = getState();
  const { langs } = context;
  niu.codeAsig = context.codeAsig;
  niu.anyAcademic = context.anyAcademic;
  niu.lastUserUpdate = context.userId;
  niu.deleteOus = [];
  niu.step = form.step + 1;
  niu.state = form.state;
  let index;
  for (const lang of langs) {
    index = niu.niuMultilangList.findIndex(o => o.langId === lang);
    if (index === -1) {
      niu.niuMultilangList.push({ langId: lang, name: form["title_" + lang] });
    } else {
      niu.niuMultilangList[index].name = form["title_" + lang];
    }
  }
  let deleted;
  for (const ou of niu.ous) {
    deleted = ous.find(o => o.id === ou.id) === undefined;
    if (deleted) {
      niu.deleteOus.push(ou.id);
    }
  }
  let formated;
  niu.ous = [];
  for (const ou of ous) {
    formated = {
      id: ou.id,
      recursId: ou.recursId,
      langId: ou.langId,
      rellevancia: ou.rellevancia,
      hores: ou.hores || 0,
      minuts: ou.minuts || 0,
      posicio: ou.posicio || 0,
      format: ou.format,
      formats: ou.formats,
      url: ou.url,
      imatge: ou.imatge,
      nou: false,
      titol: ou.title,
      descripcio: ou.description,
      bibliografia: ou.bibliography !=="<p><br></p>" ? ou.bibliography : null,

      recursLang: ou.recursLang || 'null',
      imatgeStyle: ou.imatgeStyle || null
    };
    niu.ous.push(formated);
  }
  return niu;
};
