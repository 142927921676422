import React from "react";
import PropTypes from "prop-types";

const MultiCheckbox = ({ name, id, value, label, checked , onClick ,idiomaRecurso}) => (
  <div className="form-check">
    <label htmlFor={id}>
         <input
          id={id}
          name={name}
          value={value}
          onFocus={e => onClick()}
          type="checkbox"
          defaultChecked={checked}
        >
      </input>
      <span
        className="icon icon--checkbox-on icon--small"
        aria-hidden="true"
      />
      {label}
    </label>
  </div>
);

MultiCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  idiomaRecurso: PropTypes.string,
};



export default MultiCheckbox;
