import * as C from "../actions/constants";

export const form = (state = {}, action = { type: null }) => {
  switch (action.type) {
    case C.UPDATE_FORM:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const ou = (state = null, action = { type: null }) => {
  switch (action.type) {
    case C.UPDATE_OU:
      return state.idx !== action.payload.idx
        ? state
        : { ...state, ...action.payload };
    default:
      return state;
  }
};

export const fav = (state = null, action = { type: null }) => {
  switch (action.type) {
    case C.UPDATE_FAV:
      return state.idx !== action.payload.idx
        ? state
        : { ...state, ...action.payload };
    case C.FAV_FOLDER:
      return state.idx !== action.payload.idx
        ? state
        : { ...state, ...action.payload };
    case C.FILTER_IMAGE:
      return state.idx !== action.payload.idx
        ? state
        : { ...state, ...action.payload };
    default:
      return state;
  }
};

export const ous = (state = [], action = { type: null }) => {
  switch (action.type) {
    case C.UPDATE_OUS:
      return [...action.payload].sort((a, b) => {
        return a.langIndex !== b.langIndex ? a.langIndex - b.langIndex : a.index - b.index;
      });
    case C.UPDATE_OU:
      return state.map(item => ou(item, action));
    case C.ADD_OU:
      return [...state, action.payload].sort((a, b) => {
        return a.langIndex !== b.langIndex ? a.langIndex - b.langIndex : a.index - b.index;
      });
    case C.REMOVE_OU:
      return state.filter(r => {
        return r.idx !== action.payload;
      });
    default:
      return state;
  }
};
