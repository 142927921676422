import React from "react";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { Modal, Alert } from "react-bootstrap";
import { getFolder, resetFolder, selectFile , getAllData} from "../actions/api";
import Spinner from "./Spinner";
import PickerGrid from "./PickerGrid";
import PickerBreadcrumbs from "./PickerBreadcrumbs";
import { updateFavFolderClick} from "../actions/form";
const PickerModal = ({
  show,
  title = "form.selectImageModal",
  confirm = "modals.accept",
  cancel = "modals.cancel",
  fav = "modals.fav",
  search = "modals.buscadorBut",
  cancelFilter = "modals.cancelarBuscadorBut",
  onConfirm,
  onCancel,
  getFavData,
  putImageFav,
  deleteImageFav,
  aws,
  getFolder,
  selectFile,
  resetFolder,
  userImageFav,
  favfolder,
  onClickFavfolder,
  getS3BucketData,
  getAllData,
  filtrarImagenesButton,
  filterActive,
  filterimege,
  btnCancelFilter,
  atrasButtonFabOBuscador,
  onPressEnter,
  context
}) => {

  const onEnter = e => {
    const modal = document.getElementsByClassName("modal-picker");
    if (modal.length > 0) modal[0].removeAttribute("aria-hidden");
  
  getAllData(aws.folder, aws);
 
    getFavData();
    getFolder(aws.folder, aws);
  };

  const onExited = e => {
    resetFolder(aws.s3_imatges_folder);
  };

  const onClickFolder = (e, folder) => {
      getFolder(folder, aws);
  };

  // const onClickAtrasFavButton = (e)=> {
  // const { updateFavFolderClick } = this.props;
  // selectFile('');
  //  updateFavFolderClick({ favfolder: false });
  // }

  const onClickEnableImageFab = (e, url) => {
    putImageFav(url);
  };

  const onClickDisabelImageFab = (e, url) => {
    if(favfolder===true && url=== aws.file){
      selectFile(undefined);
    }
    deleteImageFav(url);
  };

  const heartAltAtr = (traduccionid) => {
    const lang= context.lang;
    let alt="";
    if(traduccionid==="onheartAltAtr"){
      if(lang==="es"){
        alt="Añadir la imagen como Favoritos"
      }
      if(lang==="ca"){
        alt="Afegir a Preferits"
      }
      if(lang==="en"){
        alt="Add as Favorites"
      }
    }
    if(traduccionid==="ofheartAltAtr"){
      if(lang==="es"){
        alt="Quitar de Favoritos"
      }
      if(lang==="ca"){
        alt="Treure de Preferits"
      }
      if(lang==="en"){
        alt="Remove as Favorite"
      }
    }
    if(traduccionid==="selectImage"){
      if(lang==="es"){
        alt="Selecciona la imagen"
      }
      if(lang==="ca"){
        alt="Selecciona l'imatge"
      }
      if(lang==="en"){
        alt="Select the image"
      }
    }
    return alt;
  };

  const onClickFile = (e, urlImg) => {
    if(urlImg=== aws.file){
      selectFile(undefined);
    }else{
      selectFile(urlImg);
    }
  };

  const atrasButtonFabOBuscadors = (e) => {
    selectFile('');
    atrasButtonFabOBuscador();
  };

  const checkUrl = (url) => {
    console.log(url);
    if(url.includes('%')){
      console.log('NO Filtro');
    }
    else{
      console.log('SI Filtro');
      const urls=url.split('imatgesOus');
      console.log(urls);
      const barra="/";
      urls[1] =urls[1].replace(/ /g, '%20');
      urls[1] =urls[1].replace(barra, '%2F');
      urls[1] =urls[1].replace(barra, '%2F');
      urls[1] =urls[1].replace('ï', '%C3%AF');
      url=urls[0]+'imatgesOus'+urls[1];
      console.log(url);
      }
      
    
    return url;
  };
  const checkFav = (imagesDataFav, url) => {
    if (imagesDataFav.length > 0) {
      if(imagesDataFav[0].url.length!==2){
        for (let i = 0; i < imagesDataFav.length; i++) {
          imagesDataFav[i].url = imagesDataFav[i].url.split('imatgesOus');
        }
      }
      if(url.length!==2){
        url=encodeURIComponent(url);
        url = url.split('imatgesOus');
      }
      if(favfolder===false && filterActive!==true){
        url[1]=decodeURIComponent(url[1]);
      }
      let checked = imagesDataFav.find(imagesDataFav => decodeURIComponent(imagesDataFav.url[1])=== decodeURIComponent(decodeURIComponent(url[1])));
      
      if (checked) {
        return true;
      }else{
        return false;
      }
    }else{
      return false;
      
    }
    
  };

  const { file, folder, data, loading, error, s3_imatges_folder } = aws;
  return (
    <Modal
      className="modal-picker"
      show={show}
      size="lg"
      backdrop={true}
      aria-labelledby="modal-picker-title"
      centered
      onHide={onCancel}
      onEntered={onEnter}
      onExited={onExited}
    >
      <Modal.Header>
        <h4 className="modal-title h4" id="modal-picker-title">
          <Translate id={title} />
        </h4>
      </Modal.Header>
      <div className="buscadorImagenes">
        <div className="col-xs-4 inline-grid m-right-2x caja inline-flex">
          <input type="text" id="txtFiltrar" name="name" maxLength="40" size="20" onKeyUp={onPressEnter} />  
          
         
            <button
              type="button"
              className="btn btn--large btn--secondary buscarImgButton"
              onClick={filtrarImagenesButton}
            >
              <Translate id={search} />
            </button>
         
          
          
        </div>
        
        <button
          type="button"
          className="btn btn--secondary btn--large"
          id="favButton"
          onClick={onClickFavfolder}
          
        >
          <Translate id={fav} />
        </button>
      </div>
      <PickerBreadcrumbs baseFolder={ favfolder? 'fav':{s3_imatges_folder}} folder={ favfolder || filterActive ? '':folder} onClick={onClickFolder} favfolder={favfolder} atrasFavFolder={atrasButtonFabOBuscadors} filterActive={filterActive} />
      <Modal.Body>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert variant="-warning">
            <p>
              <strong>{error.name}</strong>: {error.message}
            </p>
          </Alert>
        ) : (
          <PickerGrid
            file={file}
            data={favfolder===true ? userImageFav.data:(filterActive===true ? filterimege:data)} 
            // favfolder={favfolder}
            onClickEnableImageFav={onClickEnableImageFab}
            onClickDisabelImageFab={onClickDisabelImageFab}
            onClickFolder={onClickFolder}
            onClickFile={onClickFile}
            imagesDataFav={userImageFav.data}
            checkFav={checkFav}
            checkUrl={checkUrl}
            heartAltAtr={heartAltAtr}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn--primary btn--large"
          onClick={onConfirm}
          id="confirmButton"
          disabled={!file}
        >
          <Translate id={confirm} />
        </button>
        <button
          type="button"
          className="btn btn--secondary btn--large"
          id="cancelButton"
          onClick={onCancel}
        >
          <Translate id={cancel} />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    aws: state.aws,
    context: state.context
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFolder: (folder, options) => dispatch(getFolder(folder, options)),
    selectFile: url => dispatch(selectFile(url)),
    resetFolder: folder => dispatch(resetFolder(folder)),
    updateFavFolderClick: data => dispatch(updateFavFolderClick(data)),
    getS3BucketData: data => dispatch(updateFavFolderClick(data)),
    getAllData: (folder, options) => dispatch(getAllData(folder, options)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickerModal);
