import * as C from "./constants";

export const isLoading = loading => {
  return {
    type: C.LOADING,
    loading
  };
};

export const isSaving = saving => {
  return {
    type: C.SAVING,
    saving
  };
};

export const resetErrors = () => {
  return {
    type: C.RESET_ERRORS
  };
};
