import React from "react";
import { Translate } from "react-localize-redux";
import { Modal } from "react-bootstrap";
import Radio from "../components/Radio";

const RelevanceModal = ({ show, onHide, itemA, itemB, onChange }) => {
  if (!itemA || !itemB) return null;
  const onEnter = e => {
    const modal = document.getElementsByClassName("modal-relevance");
    if (modal.length > 0) modal[0].removeAttribute("aria-hidden");
  };
  return (
    <Modal
      className="modal-relevance"
      show={show}
      size="md"
      aria-labelledby="modal-relevance-title"
      centered
      backdrop="static"
      onEntered={onEnter}
      onHide={onHide}
    >
      <Modal.Header>
        <h4 className="modal-title h4" id="modal-relevance-title">
          <Translate id="modals.relevanceTitle" />
        </h4>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Translate id="modals.relevancePrompt" />
        </p>
        <div className="row">
          <div className="col-sm-6">
            <h5 className="text-small text-normal text-compare">
              {itemA.name}
            </h5>
            <div className="form-group form-inline-radio">
              <Radio
                name="relevanceA"
                label=<Translate id="form.relevanceAcurt" />
                id="relevanceA1"
                value="1"
                inline={true}
                checked={itemA.value === "1"}
                onChange={onChange}
              />
              <Radio
                name="relevanceA"
                label=<Translate id="form.relevanceBcurt" />
                id="relevanceA2"
                value="2"
                inline={true}
                checked={itemA.value === "2"}
                onChange={onChange}
              />
              <Radio
                name="relevanceA"
                label=<Translate id="form.relevanceCcurt" />
                id="relevanceA3"
                value="3"
                inline={true}
                checked={itemA.value === "3"}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <h5 className="text-small text-normal text-compare">
              {itemB.name}
            </h5>
            <div className="form-group form-inline-radio">
              <Radio
                name="relevanceB"
                label=<Translate id="form.relevanceAcurt" />
                id="relevanceB1"
                value="1"
                inline={true}
                checked={itemB.value === "1"}
                onChange={onChange}
              />
              <Radio
                name="relevanceB"
                label=<Translate id="form.relevanceBcurt" />
                id="relevanceB2"
                value="2"
                inline={true}
                checked={itemB.value === "2"}
                onChange={onChange}
              />
              <Radio
                name="relevanceB"
                label=<Translate id="form.relevanceCcurt" />
                id="relevanceB3"
                value="3"
                inline={true}
                checked={itemB.value === "3"}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn--primary btn--large"
          onClick={onHide}
        >
          <Translate id="menu.continue" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RelevanceModal;
