import React from "react";
import PropTypes from "prop-types";
import AsideMenuItem from "./AsideMenuItem";
import classNames from "classnames";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { Collapse } from "react-bootstrap";
import { updateForm, updateFormAndOus } from "../actions/form";
import { validateOu } from "../utils/validation";
import { focusOnField, focusOnFirstError } from "../utils/ui";
import $, {  } from 'jquery';
const AsideMenu = ({
  ous,
  langs,
  lang,
  form,
  updateForm,
  updateFormAndOus
}) => {
  const { ou, validation, isMultilang } = form;
  const ouData = ou ? ous.find(o => o.idx === ou) : null;
  return (
    <aside className="pane-nav">
      <div className="collapse__aside">
        {langs.map(lang => {
          const ousLang = ous.filter(o => o.langId === lang);

          if (!isMultilang)
            return (
              <ul className="nav-resources" key={"uncollapse_" + lang}>
                {ousLang.length === 0 ? (
                  <li>
                    <p className="m-2y text-muted">
                      <Translate id="errors.noResources" />
                    </p>
                  </li>
                ) : (
                  ousLang.map(item => (
                    <AsideMenuItem
                      key={item.idx}
                      id={"ou_" + item.idx}
                      name={item["title"]}
                      isNew={item.nou}
                      isActive={item.idx === ou}
                      isMultilang={false}
                      lang={item.recursLang}
                      onClick={() => {
                        $(".nav-resources").unbind('click');
                        updateFormAndOus(
                          ou,
                          item.idx,
                          validation,
                          ouData,
                          langs
                        );
                      }}
                    />
                  ))
                )}
              </ul>
            );

          const collapse = "st_ed_collapse_" + lang;
          const open = form[collapse];
          const icon = classNames("icon icon--small icon--after float-right", {
            "icon--arrow-down": !open,
            "icon--arrow-up": open
          });

          return (
            <div
              className="collapse__wrapper ruler ruler--primary ruler--bottom"
              key={"collapse_" + lang}
            >
              <a
                href={"#" + collapse}
                role="button"
                className="btn--collapse btn--collapse__aside btn--unstyled btn--block"
                onClick={e => {
                  e.preventDefault();
                  updateForm({ [collapse]: !open });
                }}
                aria-controls={collapse}
                aria-expanded={open}
              >
                <span className="visually-hidden">
                  <Translate id={open ? "help.contract" : "help.expand"} />{" "}
                </span>
                <Translate>
                  {({ translate }) =>
                    translate("form.niuLang", {
                      lang: translate("langs." + lang).toLowerCase()
                    })
                  }
                </Translate>
                <span className={icon} aria-hidden="true"></span>
              </a>
              <Collapse in={open}>
                <div id={collapse} aria-hidden={!open}>
                  <ul className="nav-resources">
                    {ousLang.length === 0 ? (
                      <li>
                        <p className="m-2y text-muted">
                          <Translate id="errors.noResources" />
                        </p>
                      </li>
                    ) : (
                      ousLang.map(item => (
                        <AsideMenuItem
                          key={item.idx}
                          id={"ou_" + item.idx}
                          name={item["title"]}
                          isNew={item.nou}
                          isActive={item.idx === ou}
                          isMultilang={true}
                          lang={item.recursLang}
                          onClick={() => {
                            updateFormAndOus(
                              ou,
                              item.idx,
                              validation,
                              ouData,
                              langs
                            );
                          }}
                        />
                      ))
                    )}
                  </ul>
                </div>
              </Collapse>
            </div>
          );
        })}
      </div>
    </aside>
  );
};

AsideMenu.propTypes = {
  lang: PropTypes.string.isRequired,
  ous: PropTypes.array.isRequired,
  ou: PropTypes.object
};

const mapStateToProps = state => {
  return {
    langs: state.context.langs,
    lang: state.context.langs.includes(state.context.lang)
      ? state.context.lang
      : state.context.defaultLang,
    ous: state.ous,
    form: state.form
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateFormAndOus: (prev, next, validation, prevData) => {
      if (validation.isValid) {
        let data = validateOu(prevData);
        if (data.validation.isValid) {
          dispatch(
            updateFormAndOus({
              form: { ou: next },
              ous: [
                { idx: prev, nou: false },
                { idx: next, nou: false }
              ]
            })
          );
          focusOnField('title');
        } else {
          dispatch(updateForm(data));
          focusOnFirstError(data.validation.errors);
        }
      } else {
        focusOnFirstError(validation.errors);
      }
    },
    updateForm: data => dispatch(updateForm(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AsideMenu);
