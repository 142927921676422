import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const configureStore = (initialState = {}) => {
  const middlewares = [thunk];

  if (process.env.NODE_ENV === "development") {
    // Logging Middleware
    const logger = createLogger({
      level: "info",
      collapsed: true
    });
    middlewares.push(logger);
  }

  const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  });

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  return store;
};

export default configureStore;
