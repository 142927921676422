import React from "react";
import { Translate } from "react-localize-redux";
import { Modal } from "react-bootstrap";

const ConfirmModal = ({ show, onHide, title, body, confirm, cancel , onConfirm }) => {
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="modal-confirm-title"
      centered
      onHide={onHide}
    >
      <Modal.Header>
        <h4 className="modal-title h4" id="modal-confirm-title">
        <Translate id="form.modalTitle" />   
        </h4>
      </Modal.Header>
      <Modal.Body>
      <Translate id="form.modalBody" />   
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn--primary btn--large"
          onClick={onConfirm}
        >
          <Translate id="form.modalConfirm" />   
        </button>
        <button
          type="button"
          className="btn btn--secondary btn--large"
          onClick={onHide}
        >
          <Translate id="form.modalCancel" />   
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
