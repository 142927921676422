import React from "react";
import PropTypes from "prop-types";
import MultiCheckbox from "./MultiCheckbox";
import FormatsList from "./FormatsList";
import { Translate } from "react-localize-redux";
import { Alert } from "react-bootstrap";

const MultiCheckboxResource = ({
  value,
  name,
  label,
  checked = false,
  isNew = false,
  onClick,
  formats,
  idiomaRecurso,
  s = '',
  lang
}) => (
    <div className="form-formats form-group">
      <MultiCheckbox
        id={"resource_" + lang + '_' + value + '_' + idiomaRecurso}
        value={value}
        name={name}
        label={label}
        checked={checked}
        onClick={e => onClick(value,idiomaRecurso,lang,checked)}
        idiomaRecurso={idiomaRecurso}
      />
      <FormatsList formats={formats} s={s} />
      {
        isNew ? (
          <Alert variant="-info-resource">
            <Translate id="help.newResource" />
          </Alert>
        ) : null
      }
    </div>
  );
  MultiCheckboxResource.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  isNew: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  formats: PropTypes.array.isRequired,
  s: PropTypes.string,
  lang: PropTypes.string,
  idiomaRecurso: PropTypes.string
};

export default MultiCheckboxResource;
