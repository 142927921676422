import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import classNames from "classnames";
import Field from "../components/Field";
import CheckboxResource from "../components/CheckboxResource";
import MultiCheckboxResource from "../components/MultiCheckboxResource";
import AppErrors from "../components/AppErrors";
import AlertAppear from "../components/AlertAppear";
import ScrollToTop from "../components/ScrollToTop";
import { Collapse } from "react-bootstrap";
import { updateForm, addOu, removeOu ,removeOuMulti , addOuMulti } from "../actions/form";
import $, {  } from 'jquery';
import Loading from "../components/Loading";

class StepSelect extends Component {
  constructor(props) {
    super(props);
      this.state = {
        datosRecursos: [
        ], 
        inputdatosRecursos: [
        ], 
        isLoading: false,    
      };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleResourcesChange = this.handleResourcesChange.bind(this);
    this.MultilingueResourceCheckbox = this.MultilingueResourceCheckbox.bind(this);
    this.handleCollapseChange = this.handleCollapseChange.bind(this);
    this.chargeSelectFilter = this.chargeSelectFilter.bind(this);
    this.nrecursos = this.nrecursos.bind(this);
    
  }

  windowBack(){
    var urlCopiar;
    const { url_backend,lang,defaultLang,langs,userId,codeAsig,anyAcademic,titleAsig,s,sisCourseId} = this.props.context;
    if(sisCourseId){
      urlCopiar="/?lang="+lang+"&defaultLang="+defaultLang+"&langs="+langs+"&userId="+userId+"&codeAsig="+codeAsig+"&anyAcademic="+anyAcademic+"&titleAsig="+titleAsig+"&s="+s+"&url_backend="+url_backend+"&sisCourseId="+sisCourseId+"#/gestionarNius";
    }else{
      urlCopiar="/?lang="+lang+"&defaultLang="+defaultLang+"&langs="+langs+"&userId="+userId+"&codeAsig="+codeAsig+"&anyAcademic="+anyAcademic+"&titleAsig="+titleAsig+"&s="+s+"&url_backend="+url_backend+"#/gestionarNius";
    }
    window.location.href = urlCopiar;
  }

  getHeaders () {
    const {  lang } = this.props.context;
    return {
      Accept: "application/json",
      "Accept-Language": lang || "ca"
    };
  };

  async chargeSelectFilter(){
    const {lang} = this.props.context;
    const { datosRecursos,inputdatosRecursos } = this.state;

    setTimeout(function() {
      $('.copyNiuSelect').empty();
      if(lang==="es"){
    $('.copyNiuSelect').prepend("<option value='undefined'>TODOS</option>");
    $('.copyNiuSelect').prepend("<option value='C'>PROPIOS UOC</option>")
    $('.copyNiuSelect').prepend("<option value='E'>EXTERNOS</option>")
    }else if(lang==="ca"){
    $('.copyNiuSelect').prepend("<option value='undefined'>TOTS</option>");
    $('.copyNiuSelect').prepend("<option value='C'>PROPIS UOC</option>")
    $('.copyNiuSelect').prepend("<option value='E'>EXTERNS</option>")
    }else if(lang==="en"){
    $('.copyNiuSelect').prepend("<option value='undefined'>ALL</option>");
    $('.copyNiuSelect').prepend("<option value='C'>OWN UOC</option>")
    $('.copyNiuSelect').prepend("<option value='E'>EXTERNAL</option>")
    }
    
    //rellenenar select filtro
    if(datosRecursos.length!==0){
      datosRecursos.forEach((recursolang) =>{
        if(recursolang.lang.length>2){
          $("#selMostrar"+recursolang.lang+" option:contains("+recursolang.type+")").attr('selected', true);
        }else{
          //filtro monolingue
          $("#selMonoMostrar"+recursolang.lang+" option:contains("+recursolang.type+")").attr('selected', true);
        }
      })
    }
    if(inputdatosRecursos.length!==0){
      inputdatosRecursos.forEach((inputRecurso,x) =>{
          if(inputRecurso.lang.length>2){
             $("#txtFiltrar"+inputRecurso.lang).val(inputRecurso.value);
            //sel others 
          }else{
            //filtro monolingue
             $("#txtMonoMostrar"+inputRecurso.lang).val(inputRecurso.value);
          }
      })
      
    }
  }, 1);
    
  }
async inputMultiSelect(k,langRec){
  const { inputdatosRecursos } = this.state;
   
  let valueInputFiltro=$('#txtFiltrar'+k+langRec).val();
  let label="inputRecursos"+k+langRec;
  //borrar del state de datosRecursos los datos en caso de que anteriormente ya se haya usado el filtro para filtrar y así despues poder actualizar la lista
  if(inputdatosRecursos.length!==0){
    inputdatosRecursos.forEach((recursolang,index) =>
     {if(recursolang.label===label){
       delete this.state.inputdatosRecursos[index] ; 
     }}
    )
  }
  inputdatosRecursos.push({label: label, value: valueInputFiltro,lang:k+langRec})
  await this.setState({ inputdatosRecursos: inputdatosRecursos });
}

  async filterMultiSelect(k,langRec){
    const {url_backend,langs,defaultLang,anyAcademic,codeAsig,lang,sisCourseId} = this.props.context;
    const { datosRecursos } = this.state;
    let valueSelectFiltro=$('#selMostrar'+k+langRec+" option:selected").val();
    await this.setState({ isLoading: true });
    const headers = await this.getHeaders();
    let idiomaRecurso;
    if(k==="others"){
    idiomaRecurso=defaultLang;
    }
    const request = new Request(
      `${url_backend}/edna/api/v1/resources/multilinguePorRecurso/?anyAcademic=${anyAcademic}&codeAsig=${codeAsig}&defaultlang=${defaultLang}&idiomaRecurso=${idiomaRecurso}&lang=${lang}&langs=${langs}&tipoRecurso=${valueSelectFiltro}&sisCourseId=${sisCourseId}`,
      {
        method: "GET",
        credentials: "include",
        headers: headers
      }
    );

    let response= await fetch(request);
    let data =await response.json();

    let label="recursos"+k+langRec;
    let datosfilred=[];
    //borrar del state de datosRecursos los datos en caso de que anteriormente ya se haya usado el filtro para filtrar y así despues poder actualizar la lista
    if(datosRecursos.length!== 0){
      datosfilred=datosRecursos.filter(dr => dr.label !== label)
    }
    datosfilred.push({label: label, value: data ,length: data.length,type:valueSelectFiltro,lang:k+langRec,})
    await this.setState({ datosRecursos: datosfilred ,isLoading: false});
    this.chargeSelectFilter();
    $('#selMostrar'+k+langRec+" option:contains("+valueSelectFiltro+")").attr('selected', true);
  }
  async filterMonoSelect(lang){
    const {url_backend,anyAcademic,codeAsig,sisCourseId} = this.props.context;
    const { datosRecursos } = this.state;
    let valueSelectFiltro=$('#selMonoMostrar'+lang).val();
   await this.setState({ isLoading: true });
   const headers = await this.getHeaders();
    const request = new Request(
      `${url_backend}/edna/api/v1/resources/monolinguePorRecurso/?anyAcademic=${anyAcademic}&codeAsig=${codeAsig}&idioma=${lang}&tipoRecurso=${valueSelectFiltro}&sisCourseId=${sisCourseId}`,
      {
        method: "GET",
        credentials: "include",
        headers: headers
      }
    );

    let response= await fetch(request);
    let data =await response.json();
    let label="recursos"+lang;
    this.state.datosRecursos.shift()
    datosRecursos.push({label: label, value: data ,length: data.length,type:valueSelectFiltro,lang:lang});
    await this.setState({ datosRecursos: datosRecursos, isLoading: false });
    this.chargeSelectFilter();
    $('#selMostrar'+lang+" option:contains("+valueSelectFiltro+")").attr('selected', true);
  }
  
  async inputMonoSelect(lang){
    const { inputdatosRecursos } = this.state;
    let valueInputFiltro=$('#txtMonoMostrar'+lang).val();
    let label="inputRecursos"+lang;
    //borrar del state de datosRecursos los datos en caso de que anteriormente ya se haya usado el filtro para filtrar y así despues poder actualizar la lista
    this.state.inputdatosRecursos.shift()
    inputdatosRecursos.push({label: label, value: valueInputFiltro ,lang: lang})
    await this.setState({ inputdatosRecursos: inputdatosRecursos });
  }

  nrecursos(k,lang,rl){
    const { datosRecursos } = this.state;
    let number="";
      if(datosRecursos.length > 0){
        datosRecursos.forEach((recursolang,x) =>{
          if(recursolang.label==="recursos"+k+lang){
            number=recursolang.length;
          }
          if(recursolang.label==="recursos"+lang){
            number=recursolang.length;
          }
        })
      }
      if(number===""){
        number=rl.length;
      }
      return number;
    }

  handleFieldChange(e) {
    const { updateForm } = this.props;
    updateForm({
      isUpdated: true,
      [e.target.name]: e.target.value
    });
  }

  handleResourcesChange(e, lang) {
    const { resources, addOu, removeOu } = this.props;
    const { defaultLang } = this.props.context;
    if (e.target.checked) {
      const finded = resources.find(r => r.id === e.target.value.toString());
      if (finded !== undefined) {
        addOu(finded, defaultLang);
      }
    } else {
      removeOu(e.target.value + "_" + defaultLang + "_"+ lang);
    }
  }

  MultilingueResourceCheckbox (value,idiomaRecurso,lang,checked) {
    const { resources, addOuMulti , removeOuMulti } = this.props;
    
    if (!checked) {
      const finded = resources.find(r => r.id === value.toString() && r.lang === idiomaRecurso);
      if (finded !== undefined) {
        addOuMulti(finded, lang);
      }
    } else { 
      removeOuMulti(value.toString() + "_" + lang + "_" + idiomaRecurso);
    }
  }

  handleCollapseChange(p, v) {
    const { updateForm } = this.props;
    updateForm({ [p]: v });
  }

  render() {
    const { langs, s} = this.props.context;
    const {
      resourcesLangs,
      resourcesLangsK,
      ous,
      updateForm,
      form
    } = this.props;
    const { state, validation, helpSelect, isMultilang } = this.props.form;
    const errors = validation.errors;
    const { datosRecursos,inputdatosRecursos,isLoading } = this.state;
    const titleProps = {
      label: "form.niuTitle",
      component: "input",
      type: "text",
      required: true,
      maxLength: "200",
      onChange: this.handleFieldChange,
      errors: errors
    };
    setTimeout(() => {this.chargeSelectFilter() }, 0)  
    let recursosMono= this.props.resources;
    if (isLoading){
      return <Loading />;
    }  
    return (
      
      <Fragment>
        <ScrollToTop />
        <AppErrors />
        <AlertAppear
          show={helpSelect}
          onClose={() =>
            updateForm({
              helpSelect: false
            })
          }
          message={<Translate id="help.select" />}
        />
        {isMultilang ? (
          <div className="collapse__select">
            {langs.map(lang => {

              const reslang = "resources_" + lang;
              const collapse = "st_sel_collapse_" + lang;
              const open = form[collapse];
              const icon = classNames(
                "icon icon--normal icon--after float-right",
                {
                  "icon--arrow-down": !open,
                  "icon--arrow-up": open
                }
              );
              const resourcesClass = classNames({
                fieldgroup: true,
                "fieldgroup--multilang": isMultilang,
                "is-form-error": errors[reslang] ? true : false
              });
              return (
                <div
                  className="collapse__wrapper ruler ruler--primary ruler--bottom"
                  key={"collapse_" + lang}
                >
                  <a
                    href={"#" + collapse}
                    role="button"
                    className="btn--collapse btn--collapse__niu btn--unstyled btn--block"
                    onClick={e => {
                      e.preventDefault();
                      this.handleCollapseChange(collapse, !open);
                    }}
                    aria-controls={collapse}
                    aria-expanded={open}
                  >
                    <span className="visually-hidden">
                      <Translate id={open ? "help.contract" : "help.expand"} />{" "}
                    </span>
                    <Translate>
                      {({ translate }) =>
                        translate("form.niuLang", {
                          lang: translate("langs." + lang).toLowerCase()
                        })
                      }
                    </Translate>
                    <span className={icon} aria-hidden="true"></span>
                  </a>
                  <Collapse in={open}>
                    <div id={collapse} aria-hidden={!open}>
                      <div className="fieldset">
                        <div className="fieldgroup form-group">
                          <Field
                            {...titleProps}
                            name={"title_" + lang}
                            value={form["title_" + lang]}
                          />
                        </div>
                        <div className={resourcesClass}>
                          <fieldset aria-required="true">
                            <legend className="visually-hidden">
                              <Translate>
                                {({ translate }) =>
                                  translate("form.selResLang", {
                                    lang: translate(
                                      "langs." + lang
                                    ).toLowerCase()
                                  })
                                }
                              </Translate>{" "}
                              *
                            </legend>
                            <div className="legend-block">
                              <div
                                aria-hidden="true"
                                role="presentation"
                                className="legend"
                              >
                                <Translate>
                                  {({ translate }) =>
                                    translate("form.selResLang", {
                                      lang: translate(
                                        "langs." + lang
                                      ).toLowerCase()
                                    })
                                  }
                                </Translate>{" "}
                                *
                              </div>

                              <div
                                name={reslang}
                                className="invalid-feedback"
                                tabIndex="-1"
                                role="alert"
                                aria-hidden={errors[reslang] ? false : true}
                                data-offset="45"
                              >
                                {errors[reslang] ? (
                                  <Fragment>
                                    * <Translate id={errors[reslang]} />
                                  </Fragment>
                                ) : null}
                              </div>
                            </div>
                            
                            {resourcesLangsK.map(k => {
                              
                              let rl = resourcesLangs[k];
                              const key = lang + "_" + k;
                              
                              const collapseR = "st_sel_collapse_" + key;
                              const openR = form[collapseR];
                              const iconR = classNames(
                                "icon icon--normal icon--after float-right",
                                {
                                  "icon--arrow-down": !openR,
                                  "icon--arrow-up": openR
                                }
                              );

                              return (
                                <div
                                  className="collapse__wrapper ruler ruler--primary ruler--thin"
                                  key={"collapse_" + key}
                                >
                                  <a
                                    href={"#" + collapseR}
                                    type="button"
                                    className="btn--collapse btn--collapse__res btn--unstyled btn--block"
                                    onClick={e => {
                                      e.preventDefault();
                                      this.handleCollapseChange(
                                        collapseR,
                                        !openR
                                      );
                                    }}
                                    aria-controls={collapseR}
                                    aria-expanded={openR}
                                  >
                                    <span className="visually-hidden">
                                      <Translate
                                        id={
                                          openR
                                            ? "help.contract"
                                            : "help.expand"
                                        }
                                      />{" "}
                                    </span>
                                    <Translate>
                                      {({ translate }) =>
                                        translate("form.resLang", {
                                          lang: translate(
                                            "langs." + k
                                          ).toLowerCase()
                                        })
                                      }
                                    </Translate>
                                    <span
                                      className={iconR}
                                      aria-hidden="true"
                                    ></span>
                                  </a>
                                  <Collapse in={openR}>
                                    <div id={collapseR} aria-hidden={!openR}>
                                      <div className="filterElement">
                                        <div className="filaFiltro">
                                          <div className="col-xs-4 inline-grid m-right-2x caja inline-flex">
                                            <span className="p-bottom-2y spanFiltro"><Translate id="form.FiltroMostrar"/></span>
                                            <select className="copyNiuSelect col-xs-6" id={"selMostrar"+k+lang} onChange={() => this.filterMultiSelect(k,lang)} >
                                              <option value="undefined">---------------</option>
                                              
                                            </select>     
                                          </div>
                                          <div className="col-xs-4 inline-grid m-right-2x caja inline-flex">
                                            <span className="p-bottom-2y spanFiltro"><Translate id="form.FiltroFiltrartxt"/></span>
                                            <input type="text" id={"txtFiltrar"+k+lang}  name="name" maxLength="40" size="20"  onChange={() => this.inputMultiSelect(k,lang)}/>  
                                          </div>
                                        </div>
                                      </div>
                                      <div className="collapse--inner" id={"collapse--inner"+k+lang}>
                                        {
                                          
                                        datosRecursos.length !== 0 ?(
                                          datosRecursos.forEach((recursolang,x) =>
                                          {
                                          if(recursolang.label==="recursos"+k+lang){
                                          rl=recursolang.value;
                                          }}
                                         )
                                          ): null
                                        }
                                        {
                                        inputdatosRecursos.length!== 0 ? (
                                          inputdatosRecursos.forEach((inputlang,x) =>
                                          {
                                            
                                          if(inputlang.label==="inputRecursos"+k+lang){
                                            let inputFilter = inputlang.value;
                                              
                                            let separado= inputFilter.split('');
                                            let reg='';
                                           separado.forEach(letra => {
                                              if(letra==='a'||letra==='à'||letra==='á'||letra==='e'||letra==='é'||letra==='è'||letra==='i'||letra==='í'||letra==='ì'||letra==='o'||letra==='ó'||letra==='ò'||letra==='u'||letra==='ú'||letra==='ù'){
                                                switch (letra) {
                                                  case 'a':
                                                    reg=reg+'[aàá]';
                                                    break;
                                                  case 'à':
                                                    reg=reg+'[aàá]';
                                                    break;
                                                  case 'á':
                                                    reg=reg+'[aàá]';
                                                    break;
                                                  case 'e':
                                                    reg=reg+'[eèé]';
                                                    break;
                                                  case 'é':
                                                    reg=reg+'[eèé]';
                                                    break;
                                                  case 'è':
                                                    reg=reg+'[eèé]';
                                                    break;
                                                  case 'i':
                                                    reg=reg+'[iíì]';
                                                    break;
                                                  case 'í':
                                                    reg=reg+'[iíì]';
                                                    break;
                                                  case 'ì':
                                                    reg=reg+'[iíì]';
                                                    break;
                                                  case 'o':
                                                    reg=reg+'[oóò]';
                                                    break;
                                                  case 'ó':
                                                    reg=reg+'[oóò]';
                                                    break;
                                                  case 'ò':
                                                    reg=reg+'[oóò]';
                                                    break;
                                                  case 'u':
                                                    reg=reg+'[uúù]';
                                                    break;
                                                  case 'ú':
                                                    reg=reg+'[uúù]';
                                                    break;
                                                  case 'ù':
                                                    reg=reg+'[uúù]';
                                                    break;
                                                    default:
                                                      return null;
                                                }
                                              }else{
                                                reg=reg+letra;
                                              }
                                            })
                                                var regex = new RegExp(reg, "gi");
                                                if(inputlang.value.length > 2){
                                                  if(rl){
                                                  rl = rl.filter(rs => regex.test(rs.title)===true);
                                                } 
                                              }}
                                            } 
                                        ))
                                          :null
                                        }
                                        {rl.length === 0 ? (
                                          <div
                                            className="text-muted p-bottom-3y"
                                            key={"error_res_" + k}
                                          >
                                            <Translate id="errors.noResourcesLang" />
                                          </div>
                                        ) : (
                                          rl?(rl.filter(resource => k === resource.lang || k === 'others').map(resource => {
                                            const idx =
                                              resource.id + "_" + lang + "_" + resource.lang;
                                            let related = ous.find(
                                              o => o.idx === idx && (o.recursLang !== null ? o.recursLang : resource.lang)  === resource.lang 
                                            );
                                            
                                            //if(k===resource.lang || (k==='others' )){
                                            console.log(resource)
                                            return (
                                              // recursos multilingues
                                              <MultiCheckboxResource
                                                key={
                                                  "res_" +
                                                  lang +
                                                  "_" +
                                                  k +
                                                  "_" +
                                                  resource.id
                                                }
                                                value={resource.id}
                                                name={
                                                  "resources_" + lang + "[]"
                                                }
                                                label={resource.title}
                                                formats={resource.formats}
                                                checked={related !== undefined}
                                                isNew={
                                                  related !== undefined &&
                                                  related.nou &&
                                                  state !== "B"
                                                }
                                                onClick={this.MultilingueResourceCheckbox}
                                                s={s}
                                                lang={lang}
                                                idiomaRecurso={resource.lang}
                                                type={resource.type}
                                                
                                              />
                                            );
                                            }
                                        //  }
                                        )):null
                                          
                                        )}
                                      </div>
                                    </div>
                                  </Collapse>
                                </div>
                              );
                            })}
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              );
            })}
          </div>
        ) : (
          <Fragment>
            {langs.map(lang => {
              const reslang = "resources_" + lang;
              const resourcesClass = classNames({
                fieldgroup: true,
                "fieldgroup--multilang": isMultilang,
                "is-form-error": errors[reslang] ? true : false
              });
              return (
                <div className="fieldset" key={"fieldset_" + lang}>
                  <div className="fieldgroup form-group">
                    <Field
                      {...titleProps}
                      name={"title_" + lang}
                      value={form["title_" + lang]}
                    />
                  </div>

                  <div className={resourcesClass}>
                    <fieldset aria-required="true">
                      <legend className="visually-hidden">
                        <Translate id="form.resTitle" /> *
                      </legend>
                      <div className="legend-block">
                        <div
                          className="h5"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <Translate id="form.resTitle" /> *
                        </div>
                        <div
                          name={reslang}
                          className="invalid-feedback"
                          tabIndex="-1"
                          role="alert"
                          aria-hidden={errors[reslang] ? false : true}
                          data-offset="30"
                        >
                          {errors[reslang] ? (
                            <Fragment>
                              * <Translate id={errors[reslang]} />
                            </Fragment>
                          ) : null}
                        </div>
                      </div>
                      <div className="filterElement">
                        <div className="filaFiltro">
                          <div className="col-xs-4 inline-grid m-right-2x caja inline-flex">
                            <span className="p-bottom-2y spanFiltro"><Translate id="form.FiltroMostrar"/></span>
                            <select  className="copyNiuSelect col-xs-6" id={"selMonoMostrar"+lang} onChange={() => this.filterMonoSelect(lang)}>
                              <option value="undefined">---------------</option>
                            </select>                              
                            
                          </div>
                          <div className="col-xs-4 inline-grid m-right-2x caja inline-flex">
                            <span className="p-bottom-2y spanFiltro"><Translate id="form.FiltroFiltrartxt"/></span>
                            <input type="text"  name="name"  maxLength="40" size="20" id={"txtMonoMostrar"+lang} onChange={() => this.inputMonoSelect(lang)}/>  
                          </div>
                     
                        </div>
                        
              
                    </div>
                   
                    {/* recursos monolingues */}
                    {
                      datosRecursos.length > 0 ?(
                        datosRecursos.forEach((recursolang,x) =>
                        {
                        if(recursolang.label==="recursos"+lang){
                          recursosMono=recursolang.value;
                        }}
                        )
                        ): null   
                    }
                      {  
                      inputdatosRecursos.length > 0 ? ( 
                        inputdatosRecursos.forEach((inputlang,x) =>
                          {                              
                            if(inputlang.label==="inputRecursos"+lang){
                              let inputFilter = inputlang.value;

                              let separado= inputFilter.split('');
                              let reg='';
                             separado.forEach(letra => {
                                if(letra==='a'||letra==='à'||letra==='á'||letra==='e'||letra==='é'||letra==='è'||letra==='i'||letra==='í'||letra==='ì'||letra==='o'||letra==='ó'||letra==='ò'||letra==='u'||letra==='ú'||letra==='ù'){
                                  switch (letra) {
                                    case 'a':
                                      reg=reg+'[aàá]';
                                      break;
                                    case 'à':
                                      reg=reg+'[aàá]';
                                      break;
                                    case 'á':
                                      reg=reg+'[aàá]';
                                      break;
                                    case 'e':
                                      reg=reg+'[eèé]';
                                      break;
                                    case 'é':
                                      reg=reg+'[eèé]';
                                      break;
                                    case 'è':
                                      reg=reg+'[eèé]';
                                      break;
                                    case 'i':
                                      reg=reg+'[iíì]';
                                      break;
                                    case 'í':
                                      reg=reg+'[iíì]';
                                      break;
                                    case 'ì':
                                      reg=reg+'[iíì]';
                                      break;
                                    case 'o':
                                      reg=reg+'[oóò]';
                                      break;
                                    case 'ó':
                                      reg=reg+'[oóò]';
                                      break;
                                    case 'ò':
                                      reg=reg+'[oóò]';
                                      break;
                                    case 'u':
                                      reg=reg+'[uúù]';
                                      break;
                                    case 'ú':
                                      reg=reg+'[uúù]';
                                      break;
                                    case 'ù':
                                      reg=reg+'[uúù]';
                                      break;
                                    default:
                                      return null;
                                
                                  }
                                }else{
                                  reg=reg+letra;
                                }
                              })
                              var regex = new RegExp(reg, "gi");
                              if(recursosMono){
                                if(inputlang.value.length > 2){
                                  recursosMono = recursosMono.filter(rs => regex.test(rs.title)===true);
                                }
                                    
                              }
                          
                            }
                          }
                          ))
                          :null
                      }
                      {recursosMono.length === 0 ? (
                        <p className="m-2y text-muted">
                          <Translate id="errors.noResources" />
                        </p>
                      ) : (
                        recursosMono.map(resource => {
                          
                          const idx = resource.id + "_" + lang + "_" + resource.lang;
                          let related = ous.find(o => o.idx === idx);
                          
                            return (
                              <>
                              <CheckboxResource
                                key={"res_" + idx}
                                value={resource.id}
                                name={"resources_" + lang + "[]"}
                                label={resource.title}
                                formats={resource.formats}
                                checked={related !== undefined}
                                isNew={
                                  related !== undefined &&
                                  related.nou &&
                                  state !== "B"
                                }
                                onChange={this.handleResourcesChange}
                                s={s}
                                lang={lang}
                                resouceLang={resource.lang}
                              /></>
                            );
                          
                        })
                      )}
                    </fieldset>
                  </div>
                </div>
              );
            })}
            
          </Fragment>
        )}
        
      </Fragment>
      
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let res_lang = {};

  for (const lang of state.context.langs) {
    res_lang[lang] = [];
  }

  for (const resource of state.resources) {
    const lng = !res_lang[resource.lang] ? "others" : resource.lang;
    if (!res_lang[lng]) res_lang[lng] = [];
    res_lang[lng].push(resource);
  }

  return {
    context: state.context,
    resources: state.resources,
    resourcesLangs: res_lang,
    resourcesLangsK: Object.keys(res_lang),
    form: state.form,
    ous: state.ous
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateForm: data => dispatch(updateForm(data)),
    addOu: (resource, lang) => dispatch(addOu(resource, lang)),
    removeOu: idx => dispatch(removeOu(idx)),
    addOuMulti: (resource, lang) => dispatch(addOuMulti(resource, lang)),
    removeOuMulti: idx => dispatch(removeOuMulti(idx))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepSelect);
