import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({ name, id, value, label, checked = false, onChange }) => (
  <div className="form-check">
    <label htmlFor={id}>
      <input
        id={id}
        name={name}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span
        className="icon icon--checkbox-off icon--small"
        aria-hidden="true"
      />
      {label}
    </label>
  </div>
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default Checkbox;
