import React, { Component } from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import AlertAppear from "../components/AlertAppear";
import classNames from "classnames";
import StepSelect from "./StepSelect";
import StepEdit from "./StepEdit";
import StepPreview from "./StepPreview";
import { updateForm } from "../actions/form";
import { saveNiu } from "../actions/api";
import { validateNiu, validateOu, resetValidation } from "../utils/validation";
import { focusOnFirstError } from "../utils/ui";
import Spinner from "../components/Spinner";
class StepsForm extends Component {
  constructor(props) {
    super(props);
    this.sectionSelect = React.createRef();
    this.sectionEdit = React.createRef();
    this.sectionPreview = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePrevStep = this.handlePrevStep.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleChangeStep = this.handleChangeStep.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  windowBack(){
    var urlCopiar;
    const { url_backend,lang,defaultLang,langs,userId,codeAsig,anyAcademic,titleAsig,s,sisCourseId} = this.props.context;
    if(sisCourseId){
      urlCopiar="/?lang="+lang+"&defaultLang="+defaultLang+"&langs="+langs+"&userId="+userId+"&codeAsig="+codeAsig+"&anyAcademic="+anyAcademic+"&titleAsig="+titleAsig+"&s="+s+"&url_backend="+url_backend+"&sisCourseId="+sisCourseId+"#/gestionarNius";
    }else{
      urlCopiar="/?lang="+lang+"&defaultLang="+defaultLang+"&langs="+langs+"&userId="+userId+"&codeAsig="+codeAsig+"&anyAcademic="+anyAcademic+"&titleAsig="+titleAsig+"&s="+s+"&url_backend="+url_backend+"#/gestionarNius";
    }
    window.location.href = urlCopiar;
  }
  handleNextStep(e) {
    const { step, validation } = this.props.form;
    if (!validation.isValid && step === 1) {
      focusOnFirstError(validation.errors);
    } else {
      this.handleChangeStep(step, step < 3 ? step + 1 : 3, e);
    }
  }

  handlePrevStep(e) {
    const { step, validation } = this.props.form;
    if (!validation.isValid && step === 1) {
      focusOnFirstError(validation.errors);
    } else {
      this.handleChangeStep(step, step > 0 ? step - 1 : 0, e);
    }
  }

  handleChangeStep(prev, next, e) {
    const { form, ous, langs, updateForm, saveNiu } = this.props;
    const { ou, isNew, isUpdated } = this.props.form;
    let data = { validation: resetValidation() };
    switch (prev) {
      case 0:
        data = validateNiu(form, ous, langs);
        break;
      case 1:
        const ouData = ou ? ous.find(o => o.idx === ou) : null;
        data = validateOu(ouData);
        break;
      default:
    }
    if (data.validation.isValid) {
      data.sortLive = null;
      switch (prev) {
        case 0:
          data.helpSelect = false;
          break;
        case 1:
          data.helpEdit = false;
          break;
        case 2:
        case 3:
          data.helpPreview = false;
          break;
        default:
      }
      if (next === 3 || isNew || isUpdated) {
        saveNiu({ ...data, step: next });
      } else {
        updateForm({ ...data, step: next });
      }
    } else {
      updateForm(data);
      focusOnFirstError(data.validation.errors);
    }
  }

  render() {
    const { saving } = this.props;
    const { step, state, isUpdated, isMultilang } = this.props.form;
    const { origen} = this.props.context;
    let $section = (
      <React.Fragment>
        
        <section
          id="section-select"
          ref={this.sectionSelect}
          aria-hidden={step !== 0}
          className={
            step !== 0 ? "section-select fade" : "section-select fade show"
          }
        >
          {step === 0 ? <StepSelect /> : null}
        </section>
        <section
          id="section-edit"
          ref={this.sectionEdit}
          aria-hidden={step !== 1}
          className={step !== 1 ? "secton-edit fade" : "section-edit fade show"}
        >
          {step === 1 ? <StepEdit /> : null}
        </section>
        <section
          id="section-preview"
          ref={this.sectionPreview}
          aria-hidden={step !== 2}
          className={
            step !== 2 ? "section-preview fade" : "section-preview fade show"
          }
        >
          {step === 2 ? <StepPreview /> : null}
        </section>
      </React.Fragment>
    );

    if (state === "F" && step === 3 && origen ==="canvas" ) {
      $section = (
        <>
        <p className="lead">
          <Translate id="help.finalizeOk" />
        </p>
        <div>
         <ul className="list--unstyled list--inline">
           <li>
           <button
                 type="button"
                 className="btn btn--primary btn--large btn--save"
                 id="btnTableNius"
                 onClick={() => this.windowBack()}

               >
               <span className="btn-label" id="literalBtnCancel">
               <Translate id="copiaNius.btnTornar"/>
               </span>
             </button>
           </li>
         </ul>
       </div>
        </>
      );
    }else if (state === "F" && step === 3) {
      $section = (
        <>
        <p className="lead">
          <Translate id="help.finalizeOk" />
        </p>
        <div>
        <ul className="list--unstyled list--inline">
          <li>
          <button
                type="button"
                className="btn btn--primary btn--large btn--save"
                id="btnTableNius"
                onClick={() => this.windowBack()}

              >
              <span className="btn-label" id="literalBtnCancel">
              <Translate id="copiaNius.btnTornar"/>
              </span>
            </button>
          </li>
        </ul>
      </div>
      </>
      );
    }
    if (state === "N") {
      return (
        <AlertAppear
          show={true}
          variant="-warning"
          message={<Translate id="errors.noEdiable" />}
        />
      );
    }

    let classes = classNames("form--inverse", {
      "form--multilang": isMultilang
    });

    return (
      <form onSubmit={this.handleSubmit} className={classes}>
        <div className="fixed-steps" id="menu">
          <div className="container container--expand">
            <div className="steps-flex ruler ruler--primary ruler--bottom clearfix">
              <h2 className="step-title">
                {step === 0 ? (
                  <Translate id="steps.select" />
                ) : step === 1 ? (
                  <Translate id="steps.edit" />
                ) : step === 2 ? (
                  <Translate id="steps.preview" />
                ) : (
                  <Translate id="steps.published" />
                )}
              </h2>
              <nav className="step-actions">
                {saving ? (
                  <Spinner
                    type="small"
                    label={<Translate id="menu.saving" />}
                  />
                ) : null}
                <ul className="list--unstyled list--inline">
                  <li>
                    <button
                      type="button"
                      className="btn btn--primary btn--large btn--save"
                      disabled={step === 0 || saving}
                      onClick={this.handlePrevStep}
                    >
                      <span
                        className="icon icon--arrow-svg icon--small icon--flipped icon--before"
                        aria-hidden="true"
                      />
                      <span className="btn-label">
                        <Translate id="menu.previous" />
                      </span>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="btn btn--primary btn--large btn--continue"
                      onClick={this.handleNextStep}
                      disabled={
                        saving ||
                        step === 3 ||
                        (step === 2 && state === "F" && !isUpdated)
                      }
                    >
                      <span className="btn-label">
                        {step < 2 ? (
                          <Translate id="menu.next" />
                        ) : (
                          <Translate id="menu.finalize" />
                        )}
                      </span>
                      <span
                        className="icon icon--arrow-svg icon--small icon--after"
                        aria-hidden="true"
                      />
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        {$section}   
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    saving: state.saving,
    langs: state.context.langs,
    form: state.form,
    context: state.context,
    ous: state.ous
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateForm: data => dispatch(updateForm(data)),
    saveNiu: data => dispatch(saveNiu(data))
  };
};

StepsForm = connect(mapStateToProps, mapDispatchToProps)(StepsForm);

export default StepsForm;
