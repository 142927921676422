import PropTypes from "prop-types";
import React from "react";
import { Translate } from "react-localize-redux";

const LiveBlock = ({ id, data }) => (
  <div
    className="visually-hidden"
    role="region"
    aria-live="polite"
    aria-atomic="true"
    aria-labelledby={id}
  >
    {data ? <Translate id={data.id} data={data.data} /> : ""}
  </div>
);

LiveBlock.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object
};

export default LiveBlock;
