import { config, Endpoint, S3 } from "aws-sdk";
import { isImage } from "./ui";

export const getS3AllData = (folder, options) => {
  config.region = options.s3_imatges_region;
  const ep = new Endpoint(options.s3_imatges_endpoint);
  const s3 = new S3({ apiVersion: "2006-03-01", endpoint: ep });
  const params = {
    Bucket: options.s3_imatges_bucket,
    // Delimiter: "/",
    Prefix: folder
  };
  return new Promise((resolve, reject) => {
    s3.makeUnauthenticatedRequest("listObjectsV2", params, function(err, data) {
      if (err) {
        reject(err);
      } else {
        let results = [];
        let url = "";

        data.CommonPrefixes.forEach(function(content) {
          results.push({ url: content.Prefix, folder: true });
        });

        data.Contents.forEach(function(content) {
          // https: //s3.(region).amazonaws.com/(bucketName)/{objectName}
          if (content.Key !== folder && isImage(content.Key)) {
            url = `${options.s3_imatges_endpoint}/${options.s3_imatges_bucket}/${encodeURIComponent(content.Key)}`;
            results.push({ url: url, folder: false });
          }
        });

        resolve(results);
      }
    });
  });
};
