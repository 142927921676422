import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import AsidePreview from "../components/AsidePreview";
import AlertAppear from "../components/AlertAppear";
import AppErrors from "../components/AppErrors";
import SortableBoxes from "../components/SortableBoxes";
import ScrollToTop from "../components/ScrollToTop";
import LiveBlock from "../components/LiveBlock";
import arrayMove from 'array-move';
import classNames from "classnames";
import { updateOus, updateForm } from "../actions/form";
import FormatRecursosModal from "../components/FormatRecursosModal";
import $, {  } from 'jquery';
// eslint-disable-next-line
import { Guillotine } from "../components/guillotine-master/js/jquery.guillotine";


class StepPreview extends Component {
  constructor(props) {
    super(props);
    this.onSortStart = this.onSortStart.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onMoveCapture = this.onMoveCapture.bind(this);
    this.onclickEditImage = this.onclickEditImage.bind(this);
    this.handleCancelEditImage = this.handleCancelEditImage.bind(this);
    this.onSaveEditImage = this.onSaveEditImage.bind(this);
    this.chargeGuillotine = this.chargeGuillotine.bind(this);
    this.txtAltAtr = this.txtAltAtr.bind(this);
    // this.altatr = this.altatr.bind(this);

    this.state = { 
      sorting: false,
      show:false,
      recurs:null,
      titleRecurs:null,
      titleEditRecurs:null,
      newImageSaved:[]
     };
  }

  onMove = ({ oldIndex, dir }) => {
    const { ous, langOus, updateOrder } = this.props;
    const newIndex = oldIndex + dir;
    const items = arrayMove(langOus, oldIndex, newIndex);
    let index = 0;
    for (let item of items) {
      item.posicio = index;
      index++;
    }
    updateOrder(ous, items[newIndex]["title"], newIndex - oldIndex);
  };

  onMoveCapture = e => {
    const { updateForm } = this.props;
    updateForm({ sortLive: null });
  };

  onSortStart = ({ oldIndex, newIndex }) => {
    const { updateForm } = this.props;
    this.setState({ sorting: true });
    updateForm({ sortLive: null });
  };

  onclickEditImage= ({title}) => {
    this.setState({ titleEditRecurs: title });
    // var declaration = document.getElementById(".box--model-1:has(#butcontrols) .guillotine-canvas").style;
    // var removedvalue = declaration.removeProperty("width");
    // alert(removedvalue);
  };

  // handleCancelEditImage= () => {
  //   this.setState({ titleEditRecurs: null });
  // };


  handleCancelEditImage = () => {
    this.setState({ titleEditRecurs: null });
  };
  

  getHeaders () {
    const {  lang } = this.props.context;
    return {
      Accept: "application/json",
      "Accept-Language": lang || "ca"
    };
  };

  onSaveEditImage= ({idRecurs}) => {
    const { ous, updateImage } = this.props;
    const { newImageSaved } = this.state;
    var html=$( "#img-guillotine" ).parent();
    var htmlcss=html[0].attributes[1].nodeValue;
    htmlcss=htmlcss.replace(/;/g, ":");
    htmlcss=htmlcss.split(/\s*:\s*/);
    var style=htmlcss[1]+","+htmlcss[3]+","+htmlcss[5]+","+htmlcss[7];
    const {url_backend} = this.props.context;  
    const headers = this.getHeaders();
    const request = new Request(
      `${url_backend}/edna/api/v1/updateStyleImatgeOu/?idOu=${idRecurs}&style='${style}'`,
      {
        method: "PUT",
        credentials: "include",
        headers: headers
      }
    );
    fetch(request);
    var coincide=false;
    for (let i = 0; i < newImageSaved.length; i++) {
      if(newImageSaved[i].idRecurs===idRecurs){
        newImageSaved[i].style=style;
        coincide=true;
      }
    }
    if (!coincide) {
      newImageSaved.push({idRecurs: idRecurs, style: style})
    }
    
    this.setState({ newImageSaved: newImageSaved,titleEditRecurs: null });
    var titleEdited=null
    for (let i = 0; i < ous.length; i++) {
      if(ous[i].id===idRecurs){
        titleEdited =ous[i].title;
        ous[i].imatgeStyle= style;
      }
    }
    updateImage(ous, titleEdited);
  };

  chargeGuillotine= () => {
     if (this.state.titleEditRecurs!==null) {
    var picture = $('#img-guillotine');
    
    // Make sure the image is completely loaded before calling the plugin
    picture.one('load', function(){
      
      // Initialize plugin (with custom event)
      picture.guillotine({eventOnChange: 'guillotinechange'});

      // Display inital data
      var data = picture.guillotine('getData');
      for(var key in data) { $('#'+key).html(data[key]); }

      // Bind button actions
      $('#fit').click(function(){ picture.guillotine('fit'); });
      $('#zoom_in').click(function(){ picture.guillotine('zoomIn'); });
      $('#zoom_out').click(function(){ picture.guillotine('zoomOut'); });

      // Update data on change
      picture.on('guillotinechange', function(ev, data, action) {
        data.scale = parseFloat(data.scale.toFixed(4));
        for(var k in data) { $('#'+k).html(data[k]); }
      });
    });

    // Make sure the 'load' event is triggered at least once (for cached images)
    if (picture.prop('complete')) picture.trigger('load')    
    picture.guillotine('fit');
    return null;
  }

return null;
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { ous, langOus, updateOrder } = this.props;
    const items = arrayMove(langOus, oldIndex, newIndex);
    let index = 0;
    for (let item of items) {
      item.posicio = index;
      index++;
    }
    this.setState({ sorting: false });
    updateOrder(ous, items[newIndex]["title"], newIndex - oldIndex);
  };
  windowBack(){
    var urlCopiar;
    const { url_backend,lang,defaultLang,langs,userId,codeAsig,anyAcademic,titleAsig,s,sisCourseId} = this.props.context;
    if(sisCourseId){
      urlCopiar="/?lang="+lang+"&defaultLang="+defaultLang+"&langs="+langs+"&userId="+userId+"&codeAsig="+codeAsig+"&anyAcademic="+anyAcademic+"&titleAsig="+titleAsig+"&s="+s+"&url_backend="+url_backend+"&sisCourseId="+sisCourseId+"#/gestionarNius";
    }else{
      urlCopiar="/?lang="+lang+"&defaultLang="+defaultLang+"&langs="+langs+"&userId="+userId+"&codeAsig="+codeAsig+"&anyAcademic="+anyAcademic+"&titleAsig="+titleAsig+"&s="+s+"&url_backend="+url_backend+"#/gestionarNius";
    }
    window.location.href = urlCopiar;
  }
  formatoAparece = ({value,title}) => {
     this.setState({ show: true , recurs:value,titleRecurs:title});
  };

  formatoDesaparece= () => {
     this.setState({ show: false});
  };

    txtAltAtr = ({ traduccionid }) => {
    const {lang} = this.props.context;
    let alt="";
    if(traduccionid==="editImagebtn"){
      if(lang==="es"){
        alt="Editar Imagen"
      }
      if(lang==="ca"){
        alt="Editar Imatge"
      }
      if(lang==="en"){
        alt="Edit Image"
      }
    }
    if(traduccionid==="zoomout"){
      if(lang==="es"){
        alt="Disminuir el Zoom"
      }
      if(lang==="ca"){
        alt="Disminuir el Zoom"
      }
      if(lang==="en"){
        alt="Zoom out"
      }
    }
    if(traduccionid==="fitImage"){
      if(lang==="es"){
        alt="Ajustar imagen"
      }
      if(lang==="ca"){
        alt="Ajustar imatge"
      }
      if(lang==="en"){
        alt="Fit image"
      }
    }
    if(traduccionid==="zoomin"){
      if(lang==="es"){
        alt="Aumentar el Zoom"
      }
      if(lang==="ca"){
        alt="Augmentar el Zoom"
      }
      if(lang==="en"){
        alt="Zoom in"
      }
    }
    if(traduccionid==="saveImage"){
      if(lang==="es"){
        alt="Guardar"
      }
      if(lang==="ca"){
        alt="Guardar"
      }
      if(lang==="en"){
        alt="Save"
      }
    }
    if(traduccionid==="cancelImage"){
      if(lang==="es"){
        alt="Cancelar"
      }
      if(lang==="ca"){
        alt="Cancel·lar"
      }
      if(lang==="en"){
        alt="Cancel"
      }
    }
      if(traduccionid==="moveImage"){
        if(lang==="es"){
          alt="Arrastra"
        }
        if(lang==="ca"){
          alt="Arrastra"
        }
        if(lang==="en"){
          alt="Drag"
        }
      }
    
    return alt;
  }

  render() {
    const { langOus, helpPreview, updateForm, sortLive, isMultilang } = this.props;
    const { origen} = this.props.context;
    const boxesClass = classNames("boxes", {
      "no-select": this.state.sorting
    });

    if (!isMultilang)
      return (
        <Fragment>
          <ScrollToTop />
          <AppErrors />
          <AlertAppear
            show={helpPreview}
            onClose={() =>
              updateForm({
                helpPreview: false
              })
            }
            message={<Translate id="help.preview" />}
          />

          <p className="lead">
            <Translate id="help.previewLead" />
          </p>

          <LiveBlock id="sort-resources-block" data={sortLive} />

          <hr />

          <Translate>
            {({ translate }) => (
              <div
                className={boxesClass}
                aria-label={translate("help.sortResources")}
                id="sort-resources-block"
              >
                <SortableBoxes
                  helperClass={"placehold"}
                  axis={"xy"}
                  items={langOus}
                  useDragHandle={true}
                  useWindowAsScrollContainer={true}
                  onSortStart={this.onSortStart}
                  onSortEnd={this.onSortEnd}
                  onMove={this.onMove}
                  onMoveCapture={this.onMoveCapture}
                  isMultilang={isMultilang}
                  multiformat={this.formatoAparece}
                  onclickEditImage={this.onclickEditImage}
                  editImage={this.state.titleEditRecurs}
                  handleCancelEditImage={this.handleCancelEditImage}
                  onSaveEditImage={this.onSaveEditImage }
                  chargeGuillotine={this.chargeGuillotine}
                  newImageSaved={this.state.newImageSaved}
                  boxAltAtr={this.txtAltAtr}

                />
              </div>
            )}
          </Translate>
          <div>
         <ul className="list--unstyled list--inline">
           <li>
           <button
                 type="button"
                 className="btn btn--primary btn--large btn--save"
                 id="btnTableNius"
                 onClick={() => this.windowBack()}

               >
               <span className="btn-label" id="literalBtnCancel">
               <Translate id="copiaNius.btnTornar"/>
               </span>
             </button>
           </li>
         </ul>
       </div>
       
       <FormatRecursosModal
          show={this.state.show}
          onHide={this.formatoDesaparece}
          recurs={this.state.recurs}
          titleRecurs={this.state.titleRecurs}
        />      
        </Fragment>
      )



    return (
      <Fragment>
        <ScrollToTop />

        <div className="panes">
          <div className="fixed-pane">
            <div className="container container--expand heighHeader">
              <AsidePreview />
            </div>
          </div>
          <div className="pane-content">
            <AppErrors />

            <AlertAppear
              show={helpPreview}
              onClose={() =>
                updateForm({
                  helpPreview: false
                })
              }
              message={<Translate id="help.preview" />}
            />

            <p className="lead">
              <Translate id="help.previewLead" />
            </p>

            <LiveBlock id="sort-resources-block" data={sortLive} />

            <hr />

            <Translate>
              {({ translate }) => (
                <div
                  className={boxesClass}
                  aria-label={translate("help.sortResources")}
                  id="sort-resources-block"
                >
                  <SortableBoxes
                    helperClass={"placehold"}
                    axis={"xy"}
                    items={langOus}
                    useDragHandle={true}
                    useWindowAsScrollContainer={true}
                    onSortStart={this.onSortStart}
                    onSortEnd={this.onSortEnd}
                    onMove={this.onMove}
                    onMoveCapture={this.onMoveCapture}
                    isMultilang={isMultilang}
                    multiformat={this.formatoAparece}
                    onclickEditImage={this.onclickEditImage}
                    editImage={this.state.titleEditRecurs}
                    handleCancelEditImage={this.handleCancelEditImage}
                    onSaveEditImage={this.onSaveEditImage }
                    chargeGuillotine={this.chargeGuillotine}
                    newImageSaved={this.state.newImageSaved}
                  />
                </div>
              )}
            </Translate>
            {origen!= null ? (
            <>
              <div>
                <ul className="list--unstyled list--inline">
                  <li>
                  <button
                        type="button"
                        className="btn btn--primary btn--large btn--save"
                        id="btnTableNius"
                        onClick={() => this.windowBack()}

                      >
                      <span className="btn-label" id="literalBtnCancel">
                      <Translate id="copiaNius.btnTornar"/>
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            </>
            ):null}
          </div>
        </div>
        <FormatRecursosModal
          show={this.state.show}
          onHide={this.formatoDesaparece}
          recurs={this.state.recurs}
          titleRecurs={this.state.titleRecurs}
        />    

        
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    context: state.context,
    langPreview: state.form.langPreview,
    helpPreview: state.form.helpPreview,
    sortLive: state.form.sortLive,
    ous: state.ous,
    isMultilang: state.form.isMultilang,
    langOus: state.ous
      .filter(o => o.langId === state.form.langPreview)
      .sort((a, b) => parseInt(a.rellevancia, 10) - parseInt(b.rellevancia, 10))
      .sort((a, b) => a.posicio - b.posicio)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateForm: data => dispatch(updateForm(data)),
    updateOrder: (ous, resource, dir) => {
      let data = null;
      if (dir < 0) {
        data = { id: "help.upResource", data: { resource: resource } };
      } else if (dir > 0) {
        data = { id: "help.downResource", data: { resource: resource } };
      }
      dispatch(updateForm({ isUpdated: true, sortLive: data }));
      dispatch(updateOus(ous));
    },
    updateImage: (ous, resource) => {
      let data = null;
      data = { id: "help.editImage", data: { resource: resource } };
      dispatch(updateForm({ isUpdated: true, sortLive: data }));
      dispatch(updateOus(ous));
    }
  };
};

StepPreview = connect(mapStateToProps, mapDispatchToProps)(StepPreview);

export default StepPreview;
