import React, { Component } from "react";

class BoxImage extends Component {
  constructor(props) {
    super(props);
    this.imageRef = null;
  }

  componentDidMount() {
    if (this.props.image) {
      const loader = new Image();
      loader.src = this.props.image;
      loader.onload = () => {
        if (this.imageRef) {
          this.imageRef.setAttribute(
            "style",
            `background-image: url('${this.props.image}')`
          );
          this.imageRef.classList.add(this.props.loadedClass);
        }
      };
    } else if (this.imageRef) {
      this.imageRef.classList.add(this.props.loadedClass);
    }
  }

  render() {
    return (
      <div
        className={this.props.className}
        ref={img => (this.imageRef = img)}
      />
    );
  }
}

export default BoxImage;
