import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const AsideMenuItem = ({ id, name, isActive, isNew, isMultilang, lang, onClick }) => {
  const liClass = classNames({
    "is-active": isActive,
    "is-new": isNew
  });
  return (
    <li className={liClass}>
      <button
        type="button"
        className="btn--unstyled btn--nav"
        onClick={e => onClick(id)}
        aria-current={isActive}
      >
        <span className="lbl">{name}</span>
        {isMultilang && <span className="lng">{lang}</span>}
      </button>
    </li>
  );
};

AsideMenuItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
  isActive: PropTypes.bool,
  isMultilang: PropTypes.bool,
  lang: PropTypes.string
};

export default AsideMenuItem;
