import React from "react";
import { Translate } from "react-localize-redux";

const Skippy = () => (
  <ul id="skippy" className="visually-hidden">
    <li>
      <a href="#menu">
        <Translate id="header.skipMenu" />
      </a>
    </li>
    <li>
      <a href="#content">
        <Translate id="header.skipContent" />
      </a>
    </li>
  </ul>
);

export default Skippy;
