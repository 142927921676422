import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import { Alert } from "react-bootstrap";
import AsideMenu from "../components/AsideMenu";
import AlertAppear from "../components/AlertAppear";
import AppErrors from "../components/AppErrors";
import ScrollToTop from "../components/ScrollToTop";
import Field from "../components/Field";
import RelevanceField from "../components/RelevanceField";
import RelevanceModal from "../components/RelevanceModal";
import PickerModal from "../components/PickerModal";
import TimeField from "../components/TimeField";
import FormatField from "../components/FormatField";
import { validateOuBlur, resetValidation } from "../utils/validation";
import { focusOnFirstError } from "../utils/ui";
import { updateForm, updateOu, updateFormAndOus, updateImageFavData ,updateFavFolderClick, updateFilteredimages} from "../actions/form";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import $, {  } from 'jquery';
// import updateFavImageData from "../actions/api";
class StepEdit extends Component {
  constructor(props) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleFieldBlur = this.handleFieldBlur.bind(this);
    this.handleRelevanceChange = this.handleRelevanceChange.bind(this);
    this.handleRelModalClose = this.handleRelModalClose.bind(this);
    this.handleRelModalChange = this.handleRelModalChange.bind(this);
    this.handlePickerShow = this.handlePickerShow.bind(this);
    this.handlePickerConfirm = this.handlePickerConfirm.bind(this);
    this.handlePickerClose = this.handlePickerClose.bind(this);
    this.handleQlChange = this.handleQlChange.bind(this);
    this.handleonFocus = this.handleonFocus.bind(this);
    this.getUserFavImage = this.getUserFavImage.bind(this);
    this.putUserFavImage = this.putUserFavImage.bind(this);
    this.deleteUserFavImage = this.deleteUserFavImage.bind(this);
    this.onClickFavButton = this.onClickFavButton.bind(this);
    this.filtrarImagenesButton = this.filtrarImagenesButton.bind(this);
    this.btnCancelFilter = this.btnCancelFilter.bind(this);
    this.atrasButtonFabOBuscador = this.atrasButtonFabOBuscador.bind(this);
    this.onPressEnter = this.onPressEnter.bind(this);
    
    this.state = {
      userImageFav: [],
      favfolder:false,
    }
  }

  async getHeaders(e) {
    const {  lang } = this.props.context;
    return {
      Accept: "application/json",
      "Accept-Language": lang || "ca"
    };
  };

  async getUserFavImage(){
    const {  userId,url_backend } = this.props.context;
    const {updateImageFavData} = this.props;
    const headers = this.getHeaders();
    const request = new Request(
      `${url_backend}/edna/imatgesNius/getAllImatgesFavorites/?userCodi=${userId}`,
      {
        method: "GET",
        credentials: "include",
        headers: headers
      }
    );
    let response= await fetch(request);
    let data =await response.json();
    updateImageFavData({favImages: {data}});
  }

  async onClickFavButton(){
    $('#txtFiltrar').val('');
    $('#favButton').hide();
    this.props.updateFavFolderClick({ favfolder: true ,filterActive: false });
  }

  async putUserFavImage(e){
    const {  userId,url_backend } = this.props.context;
    const headers = this.getHeaders();
    e=Array.isArray(e)? e[0]+'imatgesOus'+e[1] : e;
    const request = new Request(
      `${url_backend}/edna/imatgesNius/addImatgeFavorita/?nameImatge=${e}&userCodi=${userId}`,
      {
        method: "POST",
        credentials: "include",
        headers: headers
      }
    );
    await fetch(request);
    this.getUserFavImage();
  }

  async deleteUserFavImage(e){
    const {  userId,url_backend } = this.props.context;
    const headers = this.getHeaders();
    if(this.props.fav.filterActive===true &&this.props.fav.favfolder===false){
        let separado= e.split('imatgesOus');
        e= separado[0]+'imatgesOus'+encodeURIComponent(separado[1]);
    }
    e=Array.isArray(e)? e[0]+'imatgesOus'+e[1] : e;
    e=encodeURIComponent(e);
    e =e.replace('(', '%2528');
    e =e.replace(')', '%2529');
    const request = new Request(
      `${url_backend}/edna/imatgesNius/deleteImatgeFavorita/?nameImatge=${e}&userCodi=${userId}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: headers
      }
    );
    await fetch(request);
    this.getUserFavImage();
  }

  async filtrarImagenesButton(){
    const {  allimageData ,updateFilteredimages} = this.props;
    var txtFiltrar = $("#txtFiltrar").val();
    if(txtFiltrar.length > 0){
      let allData=allimageData.allImageData;
      let separado= txtFiltrar.split('');
        let reg='';
       separado.forEach(letra => {
          if(letra==='a'||letra==='à'||letra==='á'||letra==='e'||letra==='é'||letra==='è'||letra==='i'||letra==='í'||letra==='ì'||letra==='o'||letra==='ó'||letra==='ò'||letra==='u'||letra==='ú'||letra==='ù'){
            switch (letra) {
              case 'a':
                reg=reg+'[aàá]';
                break;
              case 'à':
                reg=reg+'[aàá]';
                break;
              case 'á':
                reg=reg+'[aàá]';
                break;
              case 'e':
                reg=reg+'[eèé]';
                break;
              case 'é':
                reg=reg+'[eèé]';
                break;
              case 'è':
                reg=reg+'[eèé]';
                break;
              case 'i':
                reg=reg+'[iíì]';
                break;
              case 'í':
                reg=reg+'[iíì]';
                break;
              case 'ì':
                reg=reg+'[iíì]';
                break;
              case 'o':
                reg=reg+'[oóò]';
                break;
              case 'ó':
                reg=reg+'[oóò]';
                break;
              case 'ò':
                reg=reg+'[oóò]';
                break;
              case 'u':
                reg=reg+'[uúù]';
                break;
              case 'ú':
                reg=reg+'[uúù]';
                break;
              case 'ù':
                reg=reg+'[uúù]';
                break;
                default:
                  
          
            }
          }else{
            reg=reg+letra;
          }
        })
            var regex = new RegExp(reg, "gi");
            allData = allData.filter(data => regex.test(data.url.split('/').pop())===true);
          updateFilteredimages({ filterimege: allData, filterActive: true , favfolder: false });
          }
          else{
            updateFilteredimages({filterActive: false , favfolder: false });
          }
          $('#favButton').show();
  }


  componentDidMount(e) {

  }

  handleFieldChange(e) {
    const { ou, updateFormAndOus } = this.props;
         updateFormAndOus({
          form: { isUpdated: true },
          ous: [{ idx: ou.idx, [e.target.name]: e.target.value }]
        }); 
  }

  handleQlChange (){
    const { ou, updateFormAndOus } = this.props;
    if (ou.bibliography!== $(".ql-editor").html()) {
      updateFormAndOus({
        form: { isUpdated: true },
        ous: [{...ou, bibliography: $(".ql-editor").html() }],
      });
    }
  }

  handleonFocus(){
    const { ou, updateFormAndOus } = this.props;
    $(".nav-resources").unbind('click');
    $(".btn--continue").unbind('click');    
    $(".btn--save").unbind('click');    
    $( ".nav-resources" ).click(function() {
      if (ou.phy!==$(".ql-editor").html()) {
        updateFormAndOus({
          form: { isUpdated: true },
          ous: [{ idx: ou.idx, bibliography: $(".ql-editor").html()}]
        }) 
      } 
      $(".nav-resources").unbind('click');     
    });
    $( ".btn--continue" ).click(function() {
      if (ou.phy!==$(".ql-editor").html()) {
        updateFormAndOus({
          form: { isUpdated: true },
          ous: [{ idx: ou.idx, bibliography: $(".ql-editor").html()}]
        })
      }  
      $(".btn--continue").unbind('click');       
    });
    $( ".btn--save" ).click(function() {
      if (ou.phy!==$(".ql-editor").html()) {
        updateFormAndOus({
          form: { isUpdated: true },
          ous: [{ idx: ou.idx, bibliography: $(".ql-editor").html()}]
        })
      }  
      $(".btn--save").unbind('click');     
    });
  }

  handleFieldBlur(e) {
    const { ou, resource, updateFormAndOus, updateForm } = this.props;
    const data = validateOuBlur(ou, {
      name: e.target.name,
      value: e.target.value
    });
    if (!data.validation.isValid) {
      updateFormAndOus({
        form: data,
        ous: [{ idx: ou.idx, [e.target.name]: resource.title }]
      });
      focusOnFirstError(data.validation.errors);
    } else {
      updateForm(data);
    }
  }

  handleUserInputChange(e) {
    const { ou,updateFormAndOus} = this.props;
  var editorValue=($(".ql-editor").html());
  updateFormAndOus({
    form: { isUpdated: true },
    ous: [{ idx: ou.idx, phy: editorValue}]
  });
  }

  handleRelevanceChange(e) {
    const { ou, ous, updateFormAndOus } = this.props;
    let updatedForm = { isUpdated: true };
    let updatedOus = [];

    if (e.target.value === "1") {
      const finded = ous.find(
        item => item.rellevancia === e.target.value && item.langId === ou.langId
      );
      if (finded !== undefined) {
        const itemA = {
          id: ou.idx,
          name: ou.title,
          value: "1"
        };
        const itemB = {
          id: finded.idx,
          name: finded.title,
          value: "2"
        };
        updatedForm = {
          isUpdated: true,
          modalRelevance: true,
          modalRelItemA: itemA,
          modalRelItemB: itemB
        };
      } else {
        updatedOus.push({
          idx: ou.idx,
          [e.target.name]: e.target.value
        });
      }
    } else {
      if (e.target.value === "3") {
        updatedForm.validation = resetValidation();
      }
      updatedOus.push({
        idx: ou.idx,
        [e.target.name]: e.target.value
      });
    }

    updateFormAndOus({ form: updatedForm, ous: updatedOus });
  }

  handleRelModalChange(e) {
    const { updateForm } = this.props;
    const { modalRelItemA, modalRelItemB } = this.props.form;
    const updated = {
      modalRelItemA: { ...modalRelItemA },
      modalRelItemB: { ...modalRelItemB }
    };
    if (e.target.name === "relevanceA") {
      updated.modalRelItemA.value = e.target.value;
      if (e.target.value === "1" && modalRelItemB.value === "1") {
        updated.modalRelItemB.value = "2";
      }
    } else if (e.target.name === "relevanceB") {
      updated.modalRelItemB.value = e.target.value;
      if (e.target.value === "1" && modalRelItemA.value === "1") {
        updated.modalRelItemA.value = "2";
      }
    }
    updateForm(updated);
  }

  handleRelModalClose(e) {
    const { updateFormAndOus } = this.props;
    const { modalRelItemA, modalRelItemB } = this.props.form;
    updateFormAndOus({
      form: {
        modalRelevance: false,
        modalRelItemA: null,
        modalRelItemB: null
      },
      ous: [
        {
          idx: modalRelItemA.id,
          rellevancia: modalRelItemA.value
        },
        {
          idx: modalRelItemB.id,
          rellevancia: modalRelItemB.value
        }
      ]
    });
  }

  handlePickerShow(e) {
    const { updateForm } = this.props;
    $('#favButton').show();
    updateForm({ modalPicker: true });
  }

  handlePickerConfirm(e) {
    const { ou, file, updateFormAndOus , updateFavFolderClick } = this.props;
    const { validation } = this.props.form;
    const errors = validation.errors;
    // Array.isArray(url)?  url[0]+'imatgesOus'+url[1] : url)
    if (errors.imatge) delete errors.imatge;
    updateFormAndOus({
      form: {
        isUpdated: true,
        modalPicker: false,
        validation:
          Object.keys(errors).length === 0 ? resetValidation() : validation
      },
      ous: [{ idx: ou.idx, imatge: decodeURIComponent(Array.isArray(file)? file[0]+'imatgesOus'+file[1] : file), imatgeStyle : "" }]
    });
    $('#favButton').show();
    updateFavFolderClick({ favfolder: false, filterActive:false });
  }

  handlePickerClose(e) {
    const { updateForm , updateFavFolderClick} = this.props;
    $('#favButton').show();
    updateForm({ modalPicker: false });
    updateFavFolderClick({ favfolder: false, filterActive:false });
  }

  async btnCancelFilter(e) {
    const { updateFavFolderClick} = this.props;    
    updateFavFolderClick({ favfolder: false, filterActive:false });
  }

  async atrasButtonFabOBuscador(e) {
    const { updateFavFolderClick} = this.props;
    $('#favButton').show();
    updateFavFolderClick({ favfolder: false, filterActive:false });
  }

  async onPressEnter(e) {
    if (e.key === "Enter") {
      this.filtrarImagenesButton();
    }
    $("#txtFiltrar").val().length <= 2 ?$(".buscarImgButton").addClass('btn--secondary').removeClass('btn--primary'):$(".buscarImgButton").addClass('btn--primary').removeClass('btn--secondary');

  }  

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline']
    ],
  }

  formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]
  
  render() {
    const { ou, updateForm ,file} = this.props;
    const {
      validation,
      modalRelevance,
      modalRelItemA,
      modalRelItemB,
      modalPicker,
      helpEdit
    } = this.props.form;

    const { 
      favfolder,
      favImages,
      filterimege,
      filterActive
    } = this.props.fav;
    console.log(this.props);
    const errors = validation.errors;  
    if (!ou)
      return (
        <Alert dismissible variant="-warning">
          <Translate id="errors.noResources" />
        </Alert>
      );

    const ouTitleProps = {
      name: "title",
      label: "form.ouTitle",
      component: "input",
      className: "form-item form-item--lg",
      required: true,
      onChange: this.handleFieldChange,
      onBlur: this.handleFieldBlur,
      tooltip: "tooltips.ouTitle",
      maxLength: "200",
      value: ou.title,
      errors: errors
    };

    const ouDescProps = {
      name: "description",
      label: "form.description",
      component: "textarea",
      onChange: this.handleFieldChange,
      tooltip: "tooltips.description",
      maxLength: "2000",
      value: ou.description,
      errors: errors
    };

    const ouBiblioProps = {
      name: "bibliography",
      label: "form.biblio",
      component: "textarea",
      onChange: this.handleFieldChange,
      tooltip: "tooltips.biblio",
      value: ou.bibliography,
      maxLength: "2000",
      errors: errors
    };

    const ouRelevanceProps = {
      name: "rellevancia",
      label: "form.relevance",
      required: true,
      onChange: this.handleRelevanceChange,
      onSelectImage: this.handlePickerShow,
      tooltip: "tooltips.relevance",
      value: ou.rellevancia,
      imatge: ou.imatge,
      errors: errors
    };

    const ouTimeProps = {
      label: "form.time",
      required: true,
      onChange: this.handleFieldChange,
      tooltip: "tooltips.time",
      names: { hours: "hores", mins: "minuts" },
      values: { hours: ou.hores, mins: ou.minuts }
    };

    const ouFormatProps = {
      label: "form.formatPref",
      help: "help.format",
      name: "format",
      onChange: this.handleFieldChange,
      formats: ou.formats || [],
      value: ou.format
    };
    return (
      <Fragment>
        <ScrollToTop />
        <div className="panes">
          <div className="fixed-pane">
            <div className="container container--expand heighHeader">
              <AsideMenu />
            </div>
          </div>
          <div className="pane-content">

            <AppErrors />

            <AlertAppear
              show={helpEdit}
              onClose={() =>
                updateForm({
                  helpEdit: false
                })
              }
              message={<Translate id="help.edit" />}
            />

            <h3 className="visually-hidden">{ou.title}</h3>

            <div className="fieldset">
              <div className="fieldgroup form-group">
                <Field {...ouTitleProps} />
              </div>

              <div className="fieldgroup form-group">
                <RelevanceField {...ouRelevanceProps} />
                <RelevanceModal
                  show={modalRelevance}
                  itemA={modalRelItemA}
                  itemB={modalRelItemB}
                  onHide={this.handleRelModalClose}
                  onChange={this.handleRelModalChange}
                />
                <PickerModal
                  deleteImageFav={this.deleteUserFavImage}
                  putImageFav={this.putUserFavImage}
                  getFavData={this.getUserFavImage}
                  show={modalPicker}
                  onConfirm={this.handlePickerConfirm}
                  onCancel={this.handlePickerClose}
                  userImageFav={favImages}
                  favfolder={favfolder}
                  file={file}
                  filterimege={filterimege}
                  filterActive={filterActive}
                  onClickFavfolder={this.onClickFavButton}
                  filtrarImagenesButton={this.filtrarImagenesButton}
                  btnCancelFilter={this.btnCancelFilter}
                  atrasButtonFabOBuscador={this.atrasButtonFabOBuscador}
                  onPressEnter={this.onPressEnter}
                />
              </div>

              <div className="fieldgroup form-group">
                <Field {...ouDescProps} />
              </div>

              <div className="fieldgroup fieldgroup--time">
                <TimeField {...ouTimeProps} />
              </div>

              <div className="fieldgroup form-group">
                <Field {...ouBiblioProps} />
                <ReactQuill theme="snow" value={ou.bibliography? ou.bibliography:ou.bibliography} modules={this.modules} formats={this.formats} onBlur={this.handleQlChange} onKeyUp={this.handleQlChange} onFocus={this.handleonFocus}/>
              </div>
              <div className="fieldgroup fieldgroup--formats">
                <FormatField {...ouFormatProps} />
              </div>
            </div>
          </div> 
          
        </div>
        
      </Fragment>
      
    );
  }
}

const mapStateToProps = state => {
  const { ou } = state.form;
  const o = ou ? state.ous.find(o => o.idx === ou) : null;
  return {
    context: state.context,
    langs: state.context.langs,
    lang: state.context.langs.includes(state.context.lang)
      ? state.context.lang
      : state.context.defaultLang,
    form: state.form,
    ous: state.ous,
    ou: o,
    resource: o ? state.resources.find(r => r.id === o.recursId) : null,
    file: state.aws.file,
    fav: state.fav,
    allimageData: state.aws,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateForm: data => dispatch(updateForm(data)),
    updateOu: data => dispatch(updateOu(data)),
    updateFormAndOus: data => dispatch(updateFormAndOus(data)),
    updateImageFavData: data => dispatch(updateImageFavData(data)),
    updateFavFolderClick: data => dispatch(updateFavFolderClick(data)),
    updateFilteredimages: data => dispatch(updateFilteredimages(data))
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepEdit);
