import React from "react";
import { Translate } from "react-localize-redux";
import InfoTooltip from "./InfoTooltip";
import classNames from "classnames";

const Field = props => {
  const {
    name,
    component,
    type,
    label,
    maxLength,
    value,
    required = false,
    tooltip,
    errors,
    className = "form-item",
    onBlur = f => f,
    onChange = f => f
  } = props;

  const Tag = component;
  const error = errors[name] ? true : false;

  let classes = classNames({
    "form-group": true,
    "is-form-error": error
  });


  return (
    <div className="fieldgroup">
      <div className={classes}>
        <label htmlFor={name} className="h5">
          <Translate id={label} /> {required ? "*" : null}{" "}
        </label>
        {tooltip ? (
          <InfoTooltip
            tooltip={<Translate id={tooltip} />}
            id={"tooltip_" + name}
          />
        ) : null}
        <Tag
          name={name}
          id={name}
          type={type}
          className={className}
          value={value}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={onBlur}
          aria-required={required}
          aria-describedby={error ? name + "_feedback" : null}
        />
        {error ? (
          <div id={name + "_feedback"} className="invalid-feedback" role="alert">
            * <Translate id={errors[name]} />
          </div>
        ) : null}
      </div>
    </div>
  );

};

export default Field;
