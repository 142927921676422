import React from "react";
import { Translate } from "react-localize-redux";
import { Modal } from "react-bootstrap";
import { getIcon } from "../utils/ui";
const FormatRecursosModal = ({ show, onHide,recurs,titleRecurs }) => {
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="modal-confirm-title"
      centered
    >
      <Modal.Header>
        <h4 className="modal-title h4" id="modal-confirm-title">
        {titleRecurs}
        
        </h4>
        <button
          type="button"
          className="btn btn--short background-white"
          onClick={onHide}

        >
          X  
        </button>
      </Modal.Header>
      <Modal.Body>
    <div className="lineFormats">
    <p className="formatosDis"><Translate id="form.formatosDisponibles"/></p>
       { recurs ? recurs.map((recur) => 
      <>
        <abbr title={recur.code}>
          <a
          href={recur.url}
          className="btnlink btnlink--format"
          target="_blank"
          rel="noopener noreferrer"
          >
          <span className={"icon " + getIcon(recur.code)+" sizeIcons"} aria-hidden="true" />
          </a>
          </abbr>
      </>
      ) : null }
      </div>
      </Modal.Body>
    
    </Modal>
  );
};

export default FormatRecursosModal;