import React, { Component} from "react";
import TableNius from "./TableNius";
import EditCreate from "./EditCreate";
import CopiarNius from "./CopiarNius";
import ErrorPermisos from "../components/ErrorPermisos";
import ErrorGeneric from "../components/ErrorGeneric";
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes,HashRouter } from 'react-router-dom'
import { renderToStaticMarkup } from "react-dom/server";
import translationsCa from "../translations/ca.global.json";
import translationsEs from "../translations/es.global.json";
import translationsEn from "../translations/en.global.json";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";

const onMissingTranslation = ({ translationId }) => translationId;

class App extends Component {
  constructor(props) {
    super(props);
    this.props.initialize({
      languages: ["ca", "es", "en"],
      translation:
        this.props.lang === "ca"
          ? translationsCa
          : this.props.lang === "es"
          ? translationsEs
          : translationsEn,
      options: {
        renderToStaticMarkup,
        onMissingTranslation,
        renderInnerHtml: true,
        defaultLanguage: this.props.lang
      }
    });
  }
  render() {
    return (
      <HashRouter>
         <Routes>
           <Route exact path="/" element={<EditarCrearNiu/>}/>
           <Route exact path="/gestionarNius" element={<GestionarNius/>}/>
           <Route exact path="/copiarNius" element={<CopyNius/>}/>
           <Route exact path="/errorPermisos" element={<ErrorPermisos/>}/>
           <Route exact path="/error" element={<ErrorGeneric/>}/>
           <Route path="*" element={<NotFound/>}/>
         </Routes>
     </HashRouter>
    );
  }
}

function EditarCrearNiu() {
  return (
    <EditCreate />
  );
}

function GestionarNius() {
  return (
    <TableNius />
  );
}

function CopyNius() {
  return (
    <CopiarNius />
  );
}


function NotFound() {
  return (
    <ErrorPermisos />
    );
}

const mapStateToProps = state => {
  return {
    lang: state.context.lang,
    loading: state.loading,
    state: state.form.state
  };
};
export default withLocalize(
  connect(
    mapStateToProps,
    null
  )(App)
);