export const resetValidation = () => {
  return { isValid: true, errors: {} };
};

export const validateNiu = (form, ous, langs) => {
  const data = { validation: resetValidation() };
  let finded;
  for (const lang of langs) {
    if (form["title_" + lang] === "") {
      data["st_sel_collapse_" + lang] = true;
      data.validation.isValid = false;
      data.validation.errors["title_" + lang] = "validation.niuTitle";
    }
    finded = ous.find(o => o.langId === lang);
    if (finded === undefined) {
      data.validation.isValid = false;
      data.validation.errors["resources_" + lang] = "validation.resources";
      data["st_sel_collapse_" + lang] = true;
      data["st_sel_collapse_" + lang + "_others"] = true;
      for (const l of langs) {
        data["st_sel_collapse_" + lang + "_" + l] = true;
      }
    }
  }

  return data;
};

export const validateOu = ou => {
  const data = { validation: resetValidation() };
  if (!ou) return data;

  if (ou["title"] === "") {
    data.validation.isValid = false;
    data.validation.errors.title = "validation.ouTitle";
  }

  if ((ou.rellevancia === "1" || ou.rellevancia === "2") && !ou.imatge) {
    data.validation.isValid = false;
    data.validation.errors.imatge = "validation.ouImage";
  }

  return data;
};

export const validateOuBlur = (ou, item) => {
  const data = { validation: resetValidation() };
  if (item.value === "") {
    data.validation.isValid = false;
    data.validation.errors[item.name] = "validation.ouTitle";
  }
  return data;
};
