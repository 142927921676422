import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Translate } from "react-localize-redux";
import { SortableHandle } from "react-sortable-hoc";
import BoxImage from "./BoxImage";
import Shiitake from "shiitake";
import { getIcon } from "../utils/ui";
import moment from 'moment';

const DragHandle = SortableHandle(() => (
  <a
    href="#no-link"
    role="button"
    className="btn--unstyled btn--drag"
    aria-hidden="true"
  >
    <span className="icon icon--move icon--small-default" aria-hidden="true" />
  </a>
));

const Dedication = ({ hours = 0, mins = 0 }) => {
  if (hours === 0 && mins === 0) return false;
  let title = "";

  return (
    <Translate>
      {({ translate }) => {
        if (hours > 0 && mins > 0)
          title = translate("form.xTime", { hours: hours, mins: mins });
        else if (hours > 0) title = translate("form.xHours", { hours: hours });
        else if (mins > 0) title = translate("form.xMins", { mins: mins });
        return (
          <span className="time" title={title}>
            <span className="visually-hidden">
              {translate("form.dedication")}:{" "}
            </span>
            {hours > 0 ? translate("form.xHoursHtml", { hours: hours }) : null}{" "}
            {mins > 0 ? translate("form.xMinsHtml", { mins: mins }) : null}
            <span>|</span>
          </span>
          
        );
      }}
    </Translate>
  );
};


const Formatos = ({ suport,preferent,title,multiformat}) => {
let prinformat=preferent;
let urlpreferent="";
let titlepreferent="";
  if(prinformat == null){
   if(suport.length===1){      
      return (  
      <abbr title={suport[0].code}>
          <a
          href={suport[0].url || '#'}
          className="btnlink btnlink--format"
          target="_blank"
          rel="noopener noreferrer"
          
          >
          <span className={"icon " + getIcon(suport[0].code)} aria-hidden="true" />
          </a>
          </abbr>
      );
   }else{
    let alttxt = suport.map((item, index) => {
      return item.code + " - ";
    }).join('');
    
    
    return (  
      
      <abbr title={alttxt} className="iconMultiple" onClick={() => multiformat({value:suport,title:title})}>
        
          <button
           className="btnlink btnlink--format background-white"
           target="_blank"
           rel="noopener noreferrer"
          />
        
        
      </abbr>
    );
   }
  }else{
    suport.forEach((item, index) => {
      if (item.code === prinformat) {
        urlpreferent = item.url;
        titlepreferent = item.code;
      }
    });
    
    return (  
      //ifpreferent == suport.
    <abbr title={titlepreferent}>
        <a
        href={urlpreferent}
        className="btnlink btnlink--format"
        target="_blank"
        rel="noopener noreferrer"
        >
        <span className={"icon " + getIcon(preferent)} aria-hidden="true" />
        </a>
        </abbr>
        );
  }
};

const MensajeFechaIncial = ({ dataIniciVigencia,fechaActual,recursId}) => {
 
  const validRecursIds = ["90316", "72730", "118247"];

  if (validRecursIds.includes(recursId)) {
    dataIniciVigencia = "2025-1-12";
  }
  console.log("la fecha actual es: "+fechaActual);
  console.log("la fecha inicial del recurso es: "+dataIniciVigencia);
  console.log("el recursId es: "+recursId);
  if(dataIniciVigencia){
    const dataIniciVigenciaFormatted = moment(dataIniciVigencia).format('YYYY-MM-DD');
    var mostrarDataIniciVigencia=dataIniciVigenciaFormatted.split("-");
  var dateA = new Date(dataIniciVigencia);  
  var dateB = new Date(fechaActual);

  if (dateA > dateB) {
     return <p className="mensajeRecursoNoDisponible"><Translate id="form.recursoNoDisponible"/> {mostrarDataIniciVigencia[2]+'-'+mostrarDataIniciVigencia[1]+'-'+mostrarDataIniciVigencia[0]}</p>;
  } else{
     return null;
  }}
return null;

  };

const Box = ({
  title,
  relevance = "3",
  idx,
  id,
  recursId,
  max,
  hours = 0,
  mins = 0,
  image,
  url,
  suport,
  preferent,
  isMultilang,
  imatgeStyle,
  newImageSaved,
  onMove = f => f,
  onMoveCapture = f => f,
  multiformat=f=>f,
  onclickEditImage=f=>f,
  handleCancelEditImage=f=>f,
  onSaveEditImage=f=>f,
  editImage,
  chargeGuillotine=f=>f,
  boxAltAtr=f=>f,
  dataIniciVigencia,
}) => {

  const colClass = classNames({
    "col-sm-12": relevance === "1",
    "col-sm-6": relevance === "2",
    "col-sm-3": relevance === "3"
  });

  const boxClass = classNames("box", "ruler", "ruler--secondary", {
    "box--model-1": relevance === "1",
    "box--model-2": relevance === "2",
    "box--model-3": relevance === "3",
    "box--multilang": isMultilang
  });

  const imgClass = classNames("box-image", {
    "no-image": !image
  });

  if(imatgeStyle){
     imatgeStyle=imatgeStyle.split(',');
  }
    var styleEdit=null;
    if(newImageSaved){
      for (let i = 0; i < newImageSaved.length; i++) {
        const element = newImageSaved[i];
        if(element){
          if (element.idRecurs === id) {
            styleEdit= element.style
          
            styleEdit=styleEdit.split(',')
            imatgeStyle=styleEdit;
          }
        }
      }
    }

    // Obtiene la fecha y hora actuales
let date = new Date();
let fechaActual= date.getFullYear()+ "-" + (date.getMonth() + 1 )+ "-" + date.getDate() ;
// Muestra la fecha en formato mm-dd-yyyy (ejemplo: 02-20-2023)
  return (
    
    <li className={colClass}>
      {/* poner aqui el codigo que hace referencia al cambio aquest recurs estará disponible a partir de xx/xx*/}
      {/* {dataIniciVigencia===null?(

        <MensajeFechaIncial dataIniciVigencia={dataIniciVigencia} fechaActual={fechaActual}></MensajeFechaIncial>
      ):null} */}
      <div className={boxClass} role="region"> 
         {
          relevance!=="3"?(
            title===editImage?(
              <>
              <div className='imgbutton box-image box-image-loaded' title={boxAltAtr({ traduccionid: 'moveImage'})}>
                  <img id='img-guillotine' className="box-image-loaded box-image-guillotine img-guillotine"  src={image} alt={'relevance'+relevance}/>
                  <div style={{display:'none'}}>{setTimeout(() => chargeGuillotine({}), 100)}</div>
                  <div id='butcontrols' className={'butcontrols'+relevance}>
                      <button id='zoom_out'     type='button' title={boxAltAtr({ traduccionid: 'zoomout'})}> - </button>
                      <button id='fit'          type='button' title={boxAltAtr({ traduccionid: 'fitImage'})}> [ ]  </button>
                      <button id='zoom_in'      type='button' title={boxAltAtr({ traduccionid: 'zoomin'})}> + </button>
                      <button id='rotate_right' type='button' title={boxAltAtr({ traduccionid: 'saveImage'})} className="icon icon--save icon--inherit-default largeIconSave" onClick={e =>setTimeout(() => onSaveEditImage({idRecurs: id}), 1)} ></button>
                      <button id='rotate_right' type='button' title={boxAltAtr({ traduccionid: 'cancelImage'})} onClick={e =>setTimeout(() => handleCancelEditImage({}), 100)}>X</button>
                  </div>
              </div>
              </>
            ) : (
              imatgeStyle||newImageSaved?(

                <div className="imgbutton box-image box-image-loaded" >
                  <div className="guillotine-window" style={{width:'100%',height:'auto',paddingTop:'75%'}}>
                    <div className="guillotine-canvas imageStyle"  style={{width:imatgeStyle[0],height:imatgeStyle[1],top:imatgeStyle[2],left:imatgeStyle[3]}}>
                      <img className="box-image-loaded box-image-guillotine img-guillotine" alt="" src={image} style={{perspective:'1000px',backfaceVisibility:'hidden'}} />
                    </div>
                  </div>
                </div>
              ):(
                <BoxImage
                className={imgClass}
                loadedClass="box-image-loaded"
                image={image}
              />
              )
            )
            ):null
         }
        <div className="box-content">
          <h3 className="box-title" title={title} aria-label={title}>
            {relevance === "1" ? (
              <Shiitake lines={3} throttleRate={150} tagName="span">
                {title}
              </Shiitake>
            ) : (
              <Shiitake lines={4} throttleRate={150} tagName="span">
                {title}
              </Shiitake>
              // poner aqui lo de *Disponible a partir del* en caso de que lo quieran en la parte inferior del BOx
              
            )}
          </h3>
          <div className="box-foot">

          <MensajeFechaIncial dataIniciVigencia={dataIniciVigencia} fechaActual={fechaActual} recursId={recursId} />
                
            <Dedication hours={hours} mins={mins}/>
            <Formatos suport={suport} preferent={preferent} url={url} title={title} multiformat={multiformat}/>
            <DragHandle />
            {relevance!=="3" && title!==editImage?(
                <button
                href="#no-link"
                className="btn--unstyled btn--editimage"
                aria-hidden="true"
                title={boxAltAtr({ traduccionid: 'editImagebtn'})}
                // onClickCapture={onclickEditImage}
                onClick={e =>
                  setTimeout(() => onclickEditImage({ title: title}), 100)
                }
                >
                <span className="icon icon--edit-pencil icon--small-default" aria-hidden="true" />
                </button>
              ):null
              }
              <ul className="move-controls visually-hidden">
              {idx !== 0 ? (
                <li>
                  <button
                    type="button"
                    onClickCapture={onMoveCapture}
                    onClick={e =>
                      setTimeout(() => onMove({ oldIndex: idx, dir: -1 }), 100)
                    }
                  >
                    <Translate id="help.up" />
                  </button>
                </li>
              ) : null}
              {idx < max ? (
                <li>
                  <button
                    type="button"
                    onClickCapture={onMoveCapture}
                    onClick={e =>
                      setTimeout(() => onMove({ oldIndex: idx, dir: 1 }), 100)
                    }
                  >
                    <Translate id="help.down" />
                  </button>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </li>
  );
};

Box.propTypes = {
  idx: PropTypes.number,
  id: PropTypes.number,
  max: PropTypes.number,
  title: PropTypes.string,
  relevance: PropTypes.string,
  hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mins: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suport: PropTypes.array,
  preferent: PropTypes.string,
  url: PropTypes.string,
  onMove: PropTypes.func,
  multiformat: PropTypes.func,
  onclickEditImage : PropTypes.func,
  handleCancelEditImage : PropTypes.func,
  onSaveEditImage : PropTypes.func,
  editImage : PropTypes.any,
  newImageSaved : PropTypes.any,
  imatgeStyle : PropTypes.any,
  chargeGuillotine: PropTypes.func
};

export default Box;
