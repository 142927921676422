import React, { Fragment} from "react";
import Skippy from "../components/Skippy";
import HeaderNoTitle from "../components/HeaderNoTitle";
import Alert from 'react-bootstrap/Alert';
import { Translate } from "react-localize-redux";



const ErrorGeneric = () => {
  
  return (
    <Fragment>
      <Skippy />
       <div>
         <header id="header" role="banner" className="fixed-heading">
           <div className="container container--expand">
             <HeaderNoTitle />
             {/* <Title /> */}
           	</div>
         </header>
        </div>
        <div>
			<Alert className="alert--error page--error" variant="danger">
		      <Alert.Heading>Error</Alert.Heading>
		      <hr />
		      <p className="mb-0">
		        <Translate id="errors.generic" />
		      </p>
    		</Alert>
    	</div>
    </Fragment>
  );
};
export default ErrorGeneric;