import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";

const Title = ({ titleNiu = "" }) => (
  <div className="heading ruler ruler--primary">
    <h1 className="heading-title">
      {titleNiu ? (
        titleNiu
      ) : (
        <span className="visually-hidden">
          <Translate id="form.niuTitle" />
        </span>
      )}
    </h1>
  </div>
);

const mapStateToProps = state => {
  return {
    titleNiu:
      state.form[
        "title_" +
          (state.context.langs.includes(state.context.lang)
            ? state.context.lang
            : state.context.defaultLang)
      ]
  };
};

Title.propTypes = {
  titleNiu: PropTypes.string
};

export default connect(
  mapStateToProps,
  null
)(Title);
