import React from "react";
import { Translate } from "react-localize-redux";
import { Alert } from "react-bootstrap";
import { fileName, folderName } from "../utils/ui";
import PickerImage from "./PickerImage";
import fullHeart from "../images/heart_full.png";
import emptyHeart from "../images/heart.png";

const PickerFile = ({ pressed, url, fav ,onClick= f => f, putImageFab= f => f,deleteImageFab= f => f,heartAltAtr= f => f }) => (
  <div>
    <div
    type="button"
    className={
      pressed
        ? "btn--picker btn--picker-img selected"
        : "btn--picker btn--picker-img"
    }
    onClick={onClick}
    aria-pressed={pressed}
    title={heartAltAtr('selectImage')}
  >
    <div className="imagediv">
    <PickerImage
      className="picker-image"
      loadedClass="picker-image-loaded"
      image={Array.isArray(url)? url[0]+'imatgesOus'+url[1] : url}
      alt={fileName(Array.isArray(url)?  url[0]+'imatgesOus'+url[1] : url)}
      fav={fav}
    />
    
    </div>

  </div>
  {fav===true?(
    <button 
    type="button"
    className="image-fav-button"
      onClick={deleteImageFab}
      title={heartAltAtr('ofheartAltAtr')}
      >
      <img src={fullHeart} alt="full Heart" />
      </button>
    )
    :(
      <button 
      type="button"
      className="image-fav-button-put"
      onClick={putImageFab}
      title={heartAltAtr('onheartAltAtr')}
      // title={heartAltAtr('onheartAltAtr')}
      >
      <img src={emptyHeart} alt="empty Heart" />
    </button>
    )}
  </div>
  
  
);

const PickerFolder = ({ url, onClick = f => f }) => (
  <button
    type="button"
    className="btn--picker btn--picker-folder"
    data-folder={url}
    onClick={onClick}
  >
    <span className="icon icon--folder-full" aria-hidden="true" />
    <span className="label">{folderName(url)}</span>
  </button>
);

const PickerGrid = ({
  file,
  data = [],
  onClickFolder = f => f,
  onClickFile = f => f,
  onClickEnableImageFav = f => f,
  onClickDisabelImageFab = f => f,
  imagesDataFav = [],
  checkFav = f => f,
  checkUrl = f => f,
  heartAltAtr = f => f

}
) => (
  <ul className="list--img-picker row">
    {data.length === 0 ? (
      <li className="col-sm-12">
        <Alert variant="-info">
          {/* {imagesDataFav?<Translate id="errors.noFavImages" />:<Translate id="errors.emptyFolder" />} */}
          { imagesDataFav?<Translate id="errors.noFavImages" />:<Translate id="errors.emptyFolder" />}
          {/* this.props.fav.filterActive=true && this.props.fav.filterActive== []?<Translate id="errors.noResourcesLang" />: */}
        </Alert>
      </li>
    ) : (
      data.map((o, index) => (
        <li key={index} className="col-sm-3">
          {o.folder? (
            <PickerFolder url={o.url} onClick={e => onClickFolder(e, o.url)} />
          ) : (
              <PickerFile
                url={o.url}
                pressed={file === o.url}
                onClick={e => onClickFile(e, o.url)}
                putImageFab={e => onClickEnableImageFav(e, o.url)}
                deleteImageFab={e => onClickDisabelImageFab(e, o.url)}
                fav={checkFav(imagesDataFav,o.url)}
                heartAltAtr={e => heartAltAtr(e)}
  // fav={true}
              />
          )
        }
        </li>
      ))
    )}
  </ul>
);

export default PickerGrid;
