import * as C from "./constants";
import {
  fetchOptions,
  fetchResources,
  fetchNiu,
  postNiu,
  putNiu,
} from "../utils/api.js";
import {
  formatResources,
  formatNiu,
  formatOus,
  assingOusIds,
  prepareNiuForSave
} from "../utils/state.js";
import { getS3Data } from "../utils/aws";
import { getS3AllData } from "../utils/s3data";
import { isLoading, isSaving, resetErrors } from "./ui";
import { updateForm, updateOus, updateCurrentOu } from "./form";

export const getOptionsSuccess = data => {
  return {
    type: C.GET_OPTIONS_SUCCESS,
    payload: data
  };
};

export const getOptionsFailure = data => {
  return {
    type: C.GET_OPTIONS_FAILURE,
    payload: data
  };
};

export const getResourcesSuccess = data => {
  return {
    type: C.GET_RESOURCES_SUCCESS,
    payload: data
  };
};

export const getResourcesFailure = data => {
  return {
    type: C.GET_RESOURCES_FAILURE,
    payload: data
  };
};

export const getNiuSuccess = data => {
  return {
    type: C.GET_NIU_SUCCESS,
    payload: data
  };
};

export const getNiuFailure = data => {
  return {
    type: C.GET_NIU_FAILURE,
    payload: data
  };
};

export const saveNiuSuccess = data => {
  return {
    type: C.SAVE_NIU_SUCCESS,
    payload: data
  };
};

export const saveNiuFailure = data => {
  return {
    type: C.SAVE_NIU_FAILURE,
    payload: data
  };
};

export const getOptions = () => (dispatch, getState) => {
  dispatch(resetErrors());
  dispatch(isLoading(true));
  return fetchOptions(getState().context)
    .then(data => {
      if (!data.s3_imatges_endpoint) {
        data.s3_imatges_endpoint = `https://s3.${ data.s3_imatges_region }.amazonaws.com`;
      }
      if(!data.s3_imatges_folder){
        data.s3_imatges_folder = 'imatgesOus';
      }
      dispatch(resetFolder(data.s3_imatges_folder))
      dispatch(getOptionsSuccess(data));
      dispatch(getInitialData());
    })
    .catch(error => {
      const displayError = { name: error.name, message: error.message };
      dispatch(getOptionsFailure(displayError));
      dispatch(isLoading(false));
    });
};
export const getResources = () => (dispatch, getState) => {
  return fetchResources(getState().context)
    .then(data => {
      dispatch(getResourcesSuccess(formatResources(data)));
    })
    .catch(error => {
      const displayError = { name: error.name, message: error.message };
      dispatch(getResourcesFailure(displayError));
    });
};

export const getNiu = () => (dispatch, getState) => {
  const state = getState();
  const { langs } = state.context;
  return fetchNiu(getState().context)
    .then(data => {
      dispatch(getNiuSuccess(data));
      if (data.state === "P") data.state = "M"; // state P (en process) discarted
      if (data.state === "F") data.step = 1;
      dispatch(updateForm(formatNiu(data, langs)));
    })
    .catch(error => {
      const displayError = { name: error.name, message: error.message };
      dispatch(getNiuFailure(displayError));
    });
};

export const getInitialData = () => (dispatch, getState) => {
  const { niuId } = getState().context;
  //dispatch(resetErrors());
  //dispatch(isLoading(true));
  if (niuId) {
    Promise.all([dispatch(getResources()), dispatch(getNiu())]).then(() => {
      const state = getState();
      dispatch(
        updateOus(
          formatOus(state.niu.ous, state.context.langs, state.resources)
        )
      );
      dispatch(updateCurrentOu(false));
      dispatch(isLoading(false));
    });
  } else {
    dispatch(getResources()).then(() => {
      dispatch(isLoading(false));
    });
  }
};

export const saveNiu = data => (dispatch, getState) => {
  const next = data.step;
  delete data.step;

  dispatch(resetErrors());
  dispatch(updateForm(data));
  dispatch(isSaving(true));
  const { ous, context } = getState();
  const niu = prepareNiuForSave(getState);

  niu.step = next + 1;
  if (niu.state === "F") niu.state = "M";
  if (niu.step === 4) niu.state = "F";

  if (niu.id) {
    return putNiu(niu, context)
      .then(result => {
        dispatch(saveNiuSuccess(result));
        dispatch(
          updateForm({
            //step: result.step ? result.step - 1 : 0,
            step: next || 0,
            state: result.state || niu.state,
            isNew: false,
            isUpdated: false,
            id: result.id
          })
        );
        dispatch(updateOus(assingOusIds(result.ous, ous)));
      })
      .catch(error => {
        const displayError = { name: error.name, message: error.message };
        dispatch(saveNiuFailure(displayError));
      })
      .then(() => {
        dispatch(isSaving(false));
      });
  } else {
    return postNiu(niu, context)
      .then(result => {
        dispatch(saveNiuSuccess(result));
        dispatch(
          updateForm({
            //step: result.step ? result.step - 1 : 0,
            step: next || 0,
            state: result.state || "B",
            isNew: false,
            isUpdated: false,
            id: result.id
          })
        );
        dispatch(updateOus(assingOusIds(result.ous, ous)));
      })
      .catch(error => {
        const displayError = { name: error.name, message: error.message };
        dispatch(saveNiuFailure(displayError));
      })
      .then(() => {
        dispatch(isSaving(false));
      });
  }
};

export const getS3Request = data => {
  return {
    type: C.GET_S3_REQUEST
  };
};

export const getS3Success = data => {
  return {
    type: C.GET_S3_SUCCESS,
    payload: data
  };
};

export const getS3Failure = data => {
  return {
    type: C.GET_S3_FAILURE,
    payload: data
  };
};

export const getFolder = (folder, options) => (dispatch, getState) => {
  dispatch(getS3Request());
  return getS3Data(folder, options)
    .then(result => {
      dispatch(getAllS3Success({ folder: folder, data: result }));
    })
    .catch(error => {
      const displayError = { name: error.name, message: error.message };
      dispatch(getAllS3Failure(displayError));
    });
};


export const getAllData = (folder, options) => (dispatch, getState) => {
  dispatch(getS3Request());
  return getS3AllData(folder, options)
    .then(result => {
      for (let i = 0; i < result.length; i++) {
        result[i].url = decodeURIComponent(result[i].url);
      }
      dispatch(getS3Success({ folder: folder, allImageData: result }));
    })
    .catch(error => {
      const displayError = { name: error.name, message: error.message };
      dispatch(getS3Failure(displayError));
    });
};

export const getAllS3Success = data => {
  return {
    type: C.GET_All_S3_SUCCESS,
    payload: data
  };
};

export const getAllS3Failure = data => {
  return {
    type: C.GET_All_S3_FAILURE,
    payload: data
  };
};

export const updateFavImageData = (favImages) => (dispatch, getState) => {
  getS3Success({ favImages: favImages });
   
};

export const resetFolder = (folder) => {
  return { type: C.RESET_S3_FOLDER, payload: folder };
};

export const selectFile = url => {
  return { type: C.SELECT_S3_FILE, payload: url };
};
