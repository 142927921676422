import React from "react";
import Spinner from "./Spinner";

const Loading = () => (
  <div className="loading">
    <Spinner label="" />
  </div>
);

export default Loading;
