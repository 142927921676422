import * as C from "./constants";
import { formatOu, convertOu, setCurrentOu } from "../utils/state";

export const updateForm = data => {
  return {
    type: C.UPDATE_FORM,
    payload: data
  };
};

export const updateNiuDataTable = data => {
  return {
    type: C.UPDATE_NIU_TABLE_DATA,
    payload: data
  };
};

export const updateOu = data => {
  return {
    type: C.UPDATE_OU,
    payload: data
  };
};

export const updateOus = data => {
  return {
    type: C.UPDATE_OUS,
    payload: data
  };
};

export const updateFormAndOus = data => dispatch => {
  dispatch(updateForm(data.form));
  for (const ou of data.ous) {
    dispatch(updateOu(ou));
  }
};

export const addOuSuccess = data => {
  return {
    type: C.ADD_OU,
    payload: data
  };
};

export const removeOuSuccess = data => {
  return {
    type: C.REMOVE_OU,
    payload: data
  };
};

export const updateFavData = data => {
  return {
    type: C.UPDATE_FAV,
    payload: data
  };
};
export const updateFavFolder = data => {
  return {
    type: C.FAV_FOLDER,
    payload: data
  };
};

export const updateImageFilter = data => {
  return {
    type: C.FILTER_IMAGE,
    payload: data
  };
};

export const desSelectFile = url => {
  return { 
    type: C.SELECT_S3_FILE, 
    payload: url };
};


export const updateCurrentOu = (updated = false) => (dispatch, getState) => {
  let state = getState();
  const { langs } = state.context;
  const old = state.form.ou;
  const ou = setCurrentOu(old, state.ous);
  let data = {
    isUpdated: updated,
    ou: ou
  };
  if (ou !== old) {
    const ouData = state.ous.find(o => o.idx === ou);
    if (ouData !== undefined) {
      for (const lang of langs) {
        data["st_ed_collapse_" + lang] = lang === ouData.langId ? true : false;
      }
    }
  }
  dispatch(updateForm(data));
};

export const addOu = (resource, lang) => (dispatch, getState) => {
  const state = getState();
  const { langs } = state.context;
  const { ous } = state.niu;
  const idx = resource.id + "_" + langs + "_" +lang;

  const finded = ous.find(ou => ou.idx === idx);
  let added;
  if (finded !== undefined) {
    added = formatOu(finded, lang, langs, resource);
  } else {
    added = convertOu(resource, lang, langs);
    added.posicio = 999;
  }
  dispatch(addOuSuccess(added));
  dispatch(updateCurrentOu(true));
};

export const removeOu = idx => (dispatch, getState) => {
  dispatch(removeOuSuccess(idx));
  dispatch(updateCurrentOu(true));
};

export const addOuMulti = (resource, lang ) => (dispatch, getState) => {
  const state = getState();
  const { langs } = state.context;
  const { ous } = state.niu;
  const idx = resource.id + "_" + lang+ "_" + resource.lang;
  const finded = ous.find(ou => ou.idx === idx && ou.recursLang === resource.recursLang);
  let added;
  if (finded !== undefined) {
    added = formatOu(finded, lang, langs, resource);
  } else {
    added = convertOu(resource, lang, langs);
    added.posicio = 999;
  }
  dispatch(addOuSuccess(added));
};

export const removeOuMulti = idx => (dispatch, getState) => {
  dispatch(removeOuSuccess(idx));
  dispatch(updateCurrentOu(true));
};

export const updateImageFavData = favImages => (dispatch, getState) => {
  dispatch(updateFavData(favImages));
};

export const updateFavFolderClick = favFolder => (dispatch, getState) => {
  dispatch(updateFavFolder(favFolder));
  dispatch(desSelectFile(''));
};

export const updateFilteredimages= data => (dispatch, getState) => {
  dispatch(updateImageFilter(data));
};
