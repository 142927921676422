import "./polyfills.js";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { LocalizeProvider } from "react-localize-redux";
import configureStore from "./configureStore";
import { getInitialState } from "./utils/state";
import { getOptions } from "./actions/api";
import App from "./containers/App";
import "./styles/App.css";

const store = configureStore(getInitialState());
store.dispatch(getOptions());

render(
  <Provider store={store} >
    <LocalizeProvider>
      <App />
    </LocalizeProvider>
  </Provider>,
  document.querySelector("#root")
);
