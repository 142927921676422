import React, {Fragment } from "react";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import { resetErrors } from "../actions/ui";
import ScrollToTop from "./ScrollToTop";

const AppErrors = ({ errors, resetErrors }) => {
  if (errors.length <= 0) return null;
  return (
    <Fragment>
      <ScrollToTop />
      <Alert dismissible variant="-warning" onClose={resetErrors}>
        {errors.map((error, index) => (
          <p key={index}>
            <strong>{error.name}</strong>: {error.message}
          </p>
        ))}
      </Alert>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    errors: state.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetErrors: () => dispatch(resetErrors())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppErrors);
