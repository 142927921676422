import * as C from "../actions/constants";

export const context = (state = {}, action = { type: null }) => {
  switch (action.type) {
    case C.SET_CONTEXT:
      return { ...action.context };
    default:
      return state;
  }
};
