import React, { Component } from "react";

import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import logo from "../images/logo-uoc-default.png";
import $, { } from 'jquery';
import Alert from 'react-bootstrap/Alert'
import { withLocalize } from "react-localize-redux";
class Header extends Component {
  constructor(props){
    super(props);
    this.state ={
      titleAsig:this.props.titleAsig,
      totalTime:600,
      mouseMove:false,
    };    
  }

  componentDidMount() {
    const {lang} = this.props.context;
    var url = window.location.href;
    var regex = new RegExp("(gestionarNius|copiarNius)");
    if (regex.test(url)===false) {
      var mover= false;
      var timer= false;
      var sec = 1000000;
      var tiempo;
      $("body").mousemove(function(){
          mover = true;
          clearInterval(tiempo);
      });
      setInterval (function() {
         if (!mover) {
           if(!timer){
             sec = 1800;
            window.clearInterval(tiempo);
            tiempo = setInterval(function(){
                var minutes=sec/60;
                minutes=Math.trunc(minutes);
                var seconds=sec-(minutes*60);
                if (minutes<10){
                  minutes="0"+minutes;
                }
                if (seconds<10){
                  seconds="0"+seconds;
                }
                if (sec < 0) {
                    clearInterval(timer);
                    document.getElementById('time').innerHTML="";
                    $('.alertTimer').empty();
                    $('.txtAlertTime').css("display", "none");
                    switch (lang) {
                      case 'es':
                        $('.alertTimer').append('<div class="alertMensaje alert-heading h4" id="alertCorrect"><span>Tu sesión ha caducado. &nbsp;<u> Recarga </u> &nbsp;la página para iniciar el proceso de nuevo.</span><span id="time" class="txtAlertTime"></span></div>');
                        break;
                      case 'ca':
                        $('.alertTimer').append('<div class="alertMensaje alert-heading h4" id="alertCorrect"><span>La teva sessió ha caducat. &nbsp;<u> Recàrrega </u> &nbsp;la pàgina per iniciar el procés de nou.</span><span id="time" class="txtAlertTime"></span></div>');
                        break;
                      case 'en':
                        $('.alertTimer').append('<div class="alertMensaje alert-heading h4" id="alertCorrect"><span>Your session has expired. &nbsp;<u> Reload </u> &nbsp;the page to start the process again.</span><span id="time" class="txtAlertTime"></span></div>');
                        break;
                      default:
                        $('.alertTimer').append('<div class="alertMensaje alert-heading h4" id="alertCorrect"><span>Your session has expired. &nbsp;<u> Reload </u> &nbsp;the page to start the process again.</span><span id="time" class="txtAlertTime"></span></div>');
                    }
                }else{
                  document.getElementById('time').innerHTML=minutes+":"+seconds;
                  sec--;
                }
                if(sec<1500){
                $('.alertTimer').css("display", "block");
                }else{
                  $('.alertTimer').css("display", "none");
                }   
            }, 1000);
           }
           timer=true;
         } else {
             mover=false;
             timer=false
             clearInterval(tiempo);
            $('.alertTimer').css("display", "none"); 
            sec=20;
         }
      }, 1000); 
    }
  }

  render() {
    const { titleAsig} = this.state;
    return (
    <section>
    <div className="header brand-header-light">
      <div className="header-container clearfix">
        <div className="row">
          <div className="col-xs-4 col-lg-3">
            <span className="top-logo">
              <img src={logo} alt="Logo UOC" />
            </span>
            <p className="top-slogan ruler ruler--secondary ruler--double">
              Universitat Oberta <br aria-hidden="true" /> de Catalunya
            </p>
          </div>
          <div className="col-xs-8 col-lg-9">
            <div className="top-title-wrapper ruler ruler--secondary ruler--double">
              <h1 className="top-title">
                <Translate id="header.title" />
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="titleTimeBox">
      <h2 className="subject col-xs-12 col-lg-12 ">{titleAsig}</h2>
      <div className="timerGroup">
        <Alert className="col-xs-12 col-lg-12 alertTimer"  variant="-warning" >
              <Alert.Heading id="alertCorrect" className="alertMensaje"><Translate id="form.txtHeaderTime"/></Alert.Heading>
        </Alert>
      </div>

    </div>
    
  </section>
    )
  }

}

const mapStateToProps = state => {
  return {
    titleAsig: state.context.titleAsig,
    context: state.context
  };
};

export default withLocalize(
  connect(
    mapStateToProps,
    null
  )(Header)
);

