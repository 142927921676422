import React from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";
import FormatsList from "./FormatsList";
import { Translate } from "react-localize-redux";
import { Alert } from "react-bootstrap";

const CheckboxResource = ({
  value,
  name,
  label,
  checked = false,
  isNew = false,
  onChange,
  formats,
  resouceLang,
  s = '',
  lang,
  resourceLang
}) => (
    <div className="form-formats form-group">
      <Checkbox
        id={"resource_" + lang + '_' + value + "_undefined"}
        value={value}
        name={name}
        label={label}
        checked={checked}
        onChange={e => onChange(e, resouceLang)}
      />
      <FormatsList formats={formats} s={s} />
      {
        isNew ? (
          <Alert variant="-info-resource">
            <Translate id="help.newResource" />
          </Alert>
        ) : null
      }
    </div>
  );

CheckboxResource.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  isNew: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  formats: PropTypes.array.isRequired,
  s: PropTypes.string,
  lang: PropTypes.string,
  resourceLang : PropTypes.string
};

export default CheckboxResource;
