import React, { Fragment } from "react";
import { Translate } from "react-localize-redux";
import InfoTooltip from "./InfoTooltip";
import { fileName } from "../utils/ui";
import classNames from "classnames";

const RelevanceField = props => {
  const {
    name,
    label,
    imatge,
    value,
    required,
    tooltip,
    onChange,
    errors,
    onSelectImage
  } = props;
  const fields = [
    {
      id: "rellevancia_1",
      value: "1",
      image: true,
      label: "form.relevanceA"
    },
    {
      id: "rellevancia_2",
      value: "2",
      image: true,
      label: "form.relevanceB"
    },
    { id: "rellevancia_3", value: "3", image: false, label: "form.relevanceC" }
  ];

  const error = errors['imatge'];
  let imgButtonClasses = classNames('btnlink', 'btnlink--regular','btnlink--underline', {
    "is-form-error": error ? true : false
  });

  return (
    <fieldset aria-required="true" aria-labelledby={'legend_' + name}>
      <div role="presentation" className="h5">
        <legend id={'legend_' + name}>
          <Translate id={label} /> {required ? "*" : null}{" "}
        </legend>
        {tooltip ? (
          <InfoTooltip
            tooltip={<Translate id={tooltip} />}
            id={"tooltip_" + name}
            className="tooltip--large"
          />
        ) : null}
      </div>
      <div className="radiogroup">
        {fields.map(field => (
          <div
            key={field.id}
            className="form-inline-radio form-inline-radio--related"
          >
            <label htmlFor={field.id}>
              <input
                id={field.id}
                name={name}
                value={field.value}
                type="radio"
                checked={value === field.value}
                onChange={onChange}
              />
              <span
                aria-hidden="true"
                className="icon icon--radio-button-off icon--small"
              />
              <Translate id={field.label} />
            </label>
            {field.image && value === field.value ? (
              <Fragment>
                <button
                  name="imatge"
                  className={imgButtonClasses}
                  onClick={onSelectImage}
                  aria-describedby={error ? "ou-image-feedback" : null}
                >
                  <Translate id="form.selectImage" />
                </button>
                {" "}
                {error ? (
                  <div id="ou-image-feedback" className="invalid-feedback" role="alert">
                    (<Translate id={error} />)
                  </div>
                ) : null}
                {imatge ? (
                  <p className="relevance-img">
                    <strong>
                      <Translate id="form.image" />:
                    </strong>{" "}
                    {fileName(imatge)}
                  </p>
                ) : null}
              </Fragment>
            ) : null}
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default RelevanceField;
