import React, { Component } from "react";

class PickerImage extends Component {

  constructor(props) {
    super(props);
    this.holderRef = null;
  }

  componentDidMount() {
    if (this.props.image) {
      const loader = new Image();
      loader.src = this.props.image;
      loader.onload = () => {
        if(this.holderRef){
          this.holderRef.classList.remove("gradient");
          this.holderRef.classList.add(this.props.loadedClass);
        }
      };
    } else if(this.holderRef){
      this.holderRef.classList.remove("gradient");
      this.holderRef.classList.add(this.props.loadedClass);
    }
  }

  render() {
    return (
      <div className={"gradient " + this.props.className} ref={holder => (this.holderRef = holder)}>
        {this.props.fav?(
          <img
          src={this.props.image}
          alt={this.props.alt}
          style={{ border: 'groove' ,borderColor: '#72ECFF' }}
        />
        )
        :(
          <img
          src={this.props.image}
          alt={this.props.alt}
          // style={{ border: "cyan" }}
        />
        )
        }
      </div>
    );
  }
}

export default PickerImage;
